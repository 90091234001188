import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../components/Page';
import { Box, Typography } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({

  background: {
    display: 'grid',
    placeContent: 'center',
    minHeight: '100%',
    backgroundImage: `url(${"../images/principal/fondo-profile.jpg"})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  center: {
    textAlign: 'center'
  },
  white: {
    color: '#fff',
    fontFamily: "Avenir Book"
  },
  h1: {
    fontSize:'40px',
    color: '#fff',
    fontFamily: "Avenir Medium"
  },
  fontAvenir:{
    fontFamily: "Avenir Book",
    fontSize: '24px'
  },
  box:{
    margin: '10px',
    padding:'10px',
    borderTop: '2px solid white',
    borderBottom: '2px solid white'
  }

}));


const DashboardView = () => {
  const classes = useStyles();

  return (
    <Page title="Dashboard" className={classes.background}  id="dashboard-pnl">
      <Box className={classes.center}>
        <Typography variant="h2" className={classes.white}>
          HELLO
        </Typography>
        <Box className={classes.box}>
          <Typography className={classes.h1}>
            WELCOME TO BROKMAR ONLINE
          </Typography>
        </Box>
        <Typography variant="h2" color="secondary" className={classes.fontAvenir}>
          ALL YOUR SOLUTIONS IN JUST ONE CLICK
        </Typography>
      </Box>
    </Page>
  );
}

export default DashboardView;