import React, { useState, useEffect } from "react";
import { Link, Button, CssBaseline, InputLabel, OutlinedInput, IconButton, TextField, FormControlLabel, Checkbox, InputAdornment, FormControl, Box, Typography, Container, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import httpClient from '../../services/axios-client';
import Cookies from 'universal-cookie';
import Page from '../../components/Page';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { APP_BASE } from '../../constants/apiConstants';


const cookies = new Cookies();

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

    background: {
        display: 'grid',
        placeContent: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${"../images/principal/fondo-login.jpg"})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    border: {
        textAlign: 'center'
    },
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid white',
        borderRadius: '25px',
        padding: '25px',
        textAlign: 'center',
        background: '#223345'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: '50%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    imglogo: {
        width: '330px',
        marginBottom: '30px'
    },
    white: {
        color: 'white'
    },
    inline: {
        display: 'inline',
    },
    inliner: {
        display: 'inline',
        borderRight: '2px solid'
    },
    linkright: {
        marginRight: '60px'
    },
    linkleft: {
        marginLeft: '60px'
    }
}));

const ChangeView = () => {

    const classes = useStyles();

    const [state, setState] = useState({
        txtPass: ''
    });

    const [idUser, setIdUser] = useState(null);
    const [isOpt, setOpt] = useState(1);
    const [isLoad, setLoad] = useState(false);
    const [isValidP, setValidP] = useState(false);

    const [isSuccess, setSuccess] = useState(false);
    const [lblSuccess, setTextSuccess] = useState('');

    const [isError, setError] = useState(false);
    const [lblError, setTextError] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError(false);
        setSuccess(false);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.id]: value
        });
        if (e.target.id == 'txtPass') setValidP(false);
    };

    const handleClickShowPassword = () => {
        setState({ ...state, showPassword: !state.showPassword });
    };

    const Change = (e) => {
        e.preventDefault();
        const iduser = idUser,
            newpass = state.txtPass;
        if (newpass.length > 5) {
            setLoad(true);
            httpClient.post('Login/UpdPass', { iduser, newpass })
                .then(response => {
                    return response.data;
                })
                .then(response => {
                    if(response.done == 1){
                        setOpt(2);
                        setTextSuccess('Password Changed.');
                        setSuccess(true);
                    }
                    setLoad(false);
                })
                .catch(error => {
                    setLoad(false);
                    setTextError('Server error.');
                    setError(true);
                });
        } else {
            setTextError('Fill in the fields correctly.');
            setError(true);
            if (newpass.length < 6) setValidP(true);
        }

    };

    const getData = () => {
        const url = new URL(window.location.href),
            token = url.searchParams.get('t');
        if (token != null) {
            setLoad(true);
            httpClient.post('Login/RecoverGet', { token, change: 0 })
                .then(response => {
                    return response.data;
                })
                .then(response => {
                    if(response.iduser > 0){
                        setIdUser(response.iduser);
                        setOpt(0);
                    }
                    setLoad(false);
                })
                .catch(error => {
                    setLoad(false);
                    setTextError('Server error.');
                    setError(true);
                });
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <Page title="Log In" className={classes.background} id="change-pnl">
            <Container component="main" maxWidth="xs" className={classes.border}>
                <CssBaseline />
                <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {lblError}
                    </Alert>
                </Snackbar>
                <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        {lblSuccess}
                    </Alert>
                </Snackbar>
                <img className={classes.imglogo}
                    src={`/images/principal/logo-blue-brookmar.svg`}
                    title="logo"
                />
                <div className={classes.paper}>
                    {isOpt == 1 ? (<Box>
                        <Typography variant="h5" className={classes.white}>
                            Please wait, if it does not load please ask again for a new link to recover your password in the following <Link color="inherit" href="/recover" className={classes.linkright}>Link</Link>
                        </Typography>
                    </Box>) : isOpt == 2 ? (<Box><Typography variant="h5" className={classes.white}>
                        Password changed, if you want to go to start session click on the following <Link color="inherit" href="/login" className={classes.linkright}>Link</Link>
                    </Typography></Box>) : (<Box className={classes.form}>

                        <FormControl error={isValidP} variant="outlined" margin="normal" color="secondary" fullWidth required>
                            <InputLabel htmlFor="txtPass">Password</InputLabel>
                            <OutlinedInput
                                id="txtPass"
                                type={state.showPassword ? 'text' : 'password'}
                                value={state.txtPass}
                                onChange={handleChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                            className={classes.white}
                                        >
                                            {state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                labelWidth={95}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            className={classes.submit}
                            onClick={Change}>
                            Change Password
                        </Button>
                    </Box>)}

                </div>
                <Box mt={8}>
                    <Typography variant="body2" align="center" className={classes.white}>
                        {'Copyright © '}
                        <Link color="inherit" href="https://brokmarperu.com/" target="_blank">Brokmar Chartering SAC</Link>{' ' + new Date().getFullYear() + '.'}
                    </Typography>
                </Box>
                <Backdrop className={classes.backdrop} open={isLoad}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Page>
    );
}

export default ChangeView;