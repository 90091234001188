import React, { useState, useEffect } from 'react';
import {
   Hidden, Modal, Slide, Link, Backdrop, CircularProgress, Snackbar, Fab, Paper, FormControl, InputLabel, OutlinedInput, Tabs, Tab, Avatar, Grid, Box, Container, IconButton, Button, Card, CardContent, TextField, InputAdornment, makeStyles,
   Typography, Tooltip, Select, MenuItem, FormControlLabel, Checkbox, FormLabel, RadioGroup, Radio
} from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import Page from '../../components/Page';
import clsx from 'clsx';
import httpClient from '../../services/axios-client';
import Cookies from 'universal-cookie';
import MUIDataTable from "mui-datatables";
import MuiAlert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import CheckIcon from '@material-ui/icons/DoneOutline';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { API_BASE_URL, APP_BASE } from '../../constants/apiConstants';
import { Profile } from '../../constants/baseConstants';

const Alert = (props) => {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = {
   filter: true,
   filterType: "dropdown",
   selectableRows: false,
   /*textLabels: {
      body: {
         noMatch: "Lo sentimos, no se encontraron registros coincidentes",
         toolTip: "Clasificar",
         columnHeaderTooltip: column => `Orderna por ${column.label}`
      },
      pagination: {
         next: "Siguiente Página",
         previous: "Anterior Página",
         rowsPerPage: "Filas por página:",
         displayRows: "de",
      },
      toolbar: {
         search: "Buscar",
         downloadCsv: "Descargar CSV",
         print: "Imprimir",
         viewColumns: "Ver Columnas",
         filterTable: "Filtrar Tabla",
      },
      filter: {
         all: "Todo",
         title: "FILTROS",
         reset: "RESETEAR",
      },
      viewColumns: {
         title: "Mostar Columnas",
         titleAria: "Mostrar/Ocultar Tabla de Columnas",
      },
      selectedRows: {
         text: "Fila(s) Selecccionadas",
         delete: "Eliminar",
         deleteAria: "Eliminal Filas Seleccionadas",
      },
   }*/
};

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      '& .MuiToolbar-root[role=toolbar]': {
         borderBottom: '1px solid',
         borderTop: '1px solid',
         borderColor: theme.palette.primary.main,
      },
      '& .MuiTableCell-body, .MuiTableCell-head': {
         borderColor: theme.palette.primary.main,
      },
      '& .MuiTab-textColorPrimary.Mui-selected': {
         color: '#ffffff',
         background: theme.palette.primary.main
      },
      '& .MuiTableCell-root': {
         textAlign: 'left',
         minWidth: '150px'
      },
      '& .MuiTableCell-head span': {
         justifyContent: 'left'
      }
   },
   container: {
      maxHeight: 440,
   },
   danger: {
      color: '#e91e63'
   },
   avatar: {
      height: 80,
      width: 80,
   },
   m_auto: {
      margin: 'auto'
   },
   b_danger: {
      'margin-left': '2px',
      background: '#e91e63'
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
   pointer: {
      cursor: 'pointer',
      color: theme.palette.secondary.main
   },
   paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `10%`,
      left: `40%`,
   },
   papermobile: {
      position: 'absolute',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `10%`,
   },
   border: {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '5px',
   },
   sub_label: {
      margin: '0px',
      color: '#546e7a',
      padding: '0',
      fontSize: '1rem'
   },
   grid_clients: {
      maxHeight: '250px',
      overflow: 'auto'
   },
   grid_actions: {
      maxHeight: '130px',
      overflow: 'auto'
   }
}));

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

let objVer = {
   login: { text: '', val: false },
};

let objClient = null,
   objData = [];


const UserView = () => {
   const classes = useStyles();

   const navigate = useNavigate();

   const location = useLocation();

   const id = location.state != null ? location.state.idperson : null;

   const objSession = cookies.get(APP_BASE + '.objSession');

   const [state, setState] = useState({
      txtUser: '',
      txtPass: '',
      txtEmail: '',
      txtCompany: '',
      txtRUC: '',
      lblCompany: '',
      lblUser: '',
      lblEmail: '',
      lblFolder: '',
      cboIdProfile: Profile.Client,
      ckCreateEmbarque: false,
      ckEditEmbarque: false,
      ckDeleteEmbarque: false,
      ckCreateClient: false,
      ckEditClient: false,
      ckDeleteClient: false,
      ckPanelTracking: false,
      ckUpdateTracking: false,
      allUser: '1',
      showPassword: false,
      isDisabled: true,
      isShowEdit: 'block',
      isShowSave: 'none'
   });

   const [tabs, setTabs] = React.useState(0);

   const [isValidUser, setValidUser] = useState(false);
   const [isValidPass, setValidPass] = useState(false);
   const [isValidEmail, setValidEmail] = useState(false);
   const [isValidCompany, setValidCompany] = useState(false);
   const [isValidRUC, setValidRUC] = useState(false);

   const [isVisibleProfile, setVisibleProfile] = useState(true);
   const [isSelectOperations, setSelectOperations] = useState(false);

   const [isValidIdProfile, setValidIdProfile] = useState(false);

   const [valueShipment, setValueShipment] = useState(null);

   const [openModal, setOpenModal] = useState(false);

   const [isLoad, setLoad] = useState(false);

   const [isSuccess, setSuccess] = useState(false);
   const [lblSuccess, setTextSuccess] = useState('');

   const [isError, setError] = useState(false);
   const [lblError, setTextError] = useState('');

   const [dataAll, setDataAll] = useState([]);

   const [dataIdProfile, setDataIdProfile] = useState([]);

   const [getDataClient, setDataClient] = useState([]);

   const columns = [
      { name: 'ID' },
      {
         name: 'Ref Client',
         options: {
            setCellProps: () => ({ style: { minWidth: '150px', maxWidth: '150px' } })
         }
      },
      { name: 'Number' },
      { name: 'Containers' },
      {
         name: 'Port Origin',
         options: {
            setCellProps: () => ({ style: { minWidth: '180px', maxWidth: '180px' } })
         }
      },
      { name: 'Days Origin' },
      {
         name: 'Port Dest.',
         options: {
            setCellProps: () => ({ style: { minWidth: '180px', maxWidth: '180px' } })
         }
      },
      { name: 'Days Dest.' },
      { name: 'Sailing Date' },
      { name: 'Arrival Date' },
      {
         name: 'Invoice', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '100px', maxWidth: '100px' } }),
            customBodyRender: (value, meta, update) => {
               let idship = desconvertId(meta.rowData[0]),
                  objReceipt = null;
               for (let i = 0; i < objData.length; i++) {
                  let val = objData[i];
                  if (val.idshipment == idship) objReceipt = val.jsReceipt;
               }
               return (
                  <React.Fragment>
                     {objReceipt != null ? (<Tooltip title={objReceipt.name}><Link className={classes.pointer} onClick={() => { handleLink(objReceipt) }}>{objReceipt.name.length > 6 ? objReceipt.name.substring(0, 6) + '...' : objReceipt.name}
                     </Link></Tooltip>) : (<></>)}

                  </React.Fragment>
               );
            }
         }
      },
      {
         name: 'Bill of LN', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '100px', maxWidth: '100px' } }),
            customBodyRender: (value, meta, update) => {
               let idship = desconvertId(meta.rowData[0]),
                  objBill = null;
               for (let i = 0; i < objData.length; i++) {
                  let val = objData[i];
                  if (val.idshipment == idship) objBill = val.jsBill;
               }
               return (
                  <React.Fragment>
                     {objBill != null ? (<Tooltip title={objBill.name}><Link className={classes.pointer} onClick={() => { handleLink(objBill) }}>{objBill.name.length > 6 ? objBill.name.substring(0, 6) + '...' : objBill.name}
                     </Link></Tooltip>) : (<></>)}

                  </React.Fragment>
               );
            }
         }
      },
      {
         name: 'Other Docs', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '100px', maxWidth: '100px' } }),
            customBodyRender: (value, meta, update) => {
               let idship = desconvertId(meta.rowData[0]),
                  objOther = null;
               for (let i = 0; i < objData.length; i++) {
                  let val = objData[i];
                  if (val.idshipment == idship) objOther = val.jsOther;
               }
               return (
                  <React.Fragment>
                     {objOther != null ? (<Tooltip title={objOther.name}><Link className={classes.pointer} onClick={() => { handleLink(objOther) }}>{objOther.name.length > 6 ? objOther.name.substring(0, 6) + '...' : objOther.name}
                     </Link></Tooltip>) : (<></>)}
                  </React.Fragment>
               );
            }
         }
      },
      { name: 'PIC Brokmar', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '210px', maxWidth: '210px' } }),
               customBodyRender: (value, meta, update) => {
                  return (
                     <React.Fragment>
                        {value?.length > 15 ? (
                           <Tooltip title={value}><Link>{value.substr(0, 15) + '...'}</Link></Tooltip>
                        ) : (
                           <React.Fragment><Link>{value}</Link></React.Fragment>
                        )}
                     </React.Fragment>
                  );
               }
         } 
      },
      { name: 'PIC Client', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '210px', maxWidth: '210px' } }),
               customBodyRender: (value, meta, update) => {
                  return (
                     <React.Fragment>
                        {value?.length > 15 ? (
                           <Tooltip title={value}><Link>{value.substr(0, 15) + '...'}</Link></Tooltip>
                        ) : (
                           <React.Fragment><Link>{value}</Link></React.Fragment>
                        )}
                     </React.Fragment>
                  );
               }
         }  
      },
      // { name: 'Buy' },
      // { name: 'Sale' },
      { name: 'DG' },
      {
         name: 'T. Container', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '200px' } }),
            customBodyRender: (value, meta, update) => {
               return (
                  <React.Fragment>
                     {value.length > 15 ? (
                        <Tooltip title={value}><Link>{value.substr(0, 15) + '...'}</Link></Tooltip>
                     ) : (
                        <React.Fragment> {value}</React.Fragment>
                     )}
                  </React.Fragment>
               );
            }
         }
      },
      { name: 'Code HS' },
      {
         name: 'Desc. HS', options: {
            filter: false,
            sort: false,
            customBodyRender: (value, meta, update) => {
               return (
                  <React.Fragment>
                     {value.length > 50 ? (
                        <Tooltip title={value}><Link>{value.substr(0, 50) + '...'}</Link></Tooltip>
                     ) : (
                        <React.Fragment> {value}</React.Fragment>
                     )}
                  </React.Fragment>
               );
            }
         }
      },
      {
         name: 'Transfer',
         options: {
            setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '200px' } })
         }
      },
      {
         name: 'Action', options: {
            filter: false,
            sort: false,
            empty: true,
            setCellProps: () => ({ style: { minWidth: '80px', maxWidth: '80px' } }),
            customBodyRender: (value, meta, update) => {

               return (
                  <React.Fragment>
                     <Tooltip title="Edit">
                        <IconButton color="primary" size="small" onClick={() => {
                           if (objClient != null) {
                              let idship = desconvertId(meta.rowData[0]),
                                 objShip = null;
                              for (let i = 0; i < objData.length; i++) {
                                 let val = objData[i];
                                 if (val.idshipment == idship) objShip = val;
                              }
                              navigate('/app/ship/info', { state: { objClient, opt: { type: 1, title: 'Edit Shipment', subtitle: 'Register correctly' }, objShip } });
                           }
                        }}>
                           <EditIcon fontSize="inherit" />
                        </IconButton>
                     </Tooltip>
                     <Tooltip title="Delete">
                        <IconButton color="secondary" size="small" onClick={() => {
                           let idshipment = desconvertId(meta.rowData[0]);
                           setValueShipment(idshipment);
                           setOpenModal(true);
                        }}>
                           <DeleteIcon fontSize="inherit" />
                        </IconButton>
                     </Tooltip>
                  </React.Fragment>
               );
            }
         }
      }
   ];

   const handleLink = (objFile) => {
      const link = document.createElement('a');
      link.href = `${API_BASE_URL}/Login/Download?key=${objFile.key}&folder=${objFile.folder}&name=${objFile.name}`;
      link.setAttribute(
         'download',
         objFile.name
      );
      document.body.appendChild(link);
      link.click();
   }

   const handleTabs = (e, newValue) => {
      setTabs(newValue);
      if (objData.length) {

         let dAll = [];
         for (let i = 0; i < objData.length; i++) {
            let value = objData[i],
               transfer = '';
            if (value.jsTransfer != null) {
               for (let i = 0; i < value.jsTransfer.length; i++) {
                  let valueT = value.jsTransfer[i];
                  transfer += `${valueT.origin} - ${valueT.date}\r\n`
               }
            }
            
            if (newValue == 0) {
               dAll.push([
                  convertId(value.idshipment),
                  value.refclient,
                  value.booking,
                  value.container,
                  value.origin,
                  pad(value.dorigin),
                  value.target,
                  pad(value.dtarget),
                  value.datestart,
                  value.dateend,
                  '',
                  '',
                  '',
                  value.txtpicbrokmar,
                  value.picclient,
                  // '$. ' + value.buy,
                  // '$. ' + value.sale,
                  value.dg == 1 ? 'Yes' : 'No',
                  value.typeContainer || '',
                  value.codeHS || '',
                  value.descHS || '',
                  transfer

               ]);
            } else {
               if (newValue + 1 == value.bostate) {
                  dAll.push([
                     convertId(value.idshipment),
                     value.refclient,
                     value.booking,
                     value.container,
                     value.origin,
                     pad(value.dorigin),
                     value.target,
                     pad(value.dtarget),
                     value.datestart,
                     value.dateend,
                     '',
                     '',
                     '',
                     value.txtpicbrokmar,
                     value.picclient,
                     // '$. ' + value.buy,
                     // '$. ' + value.sale,
                     value.dg == 1 ? 'Yes' : 'No',
                     value.typeContainer || '',
                     value.codeHS || '',
                     value.descHS || '',
                     transfer
                  ]);
               }
            }
         }
         setDataAll(dAll);
      }

   };

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setError(false);
      setSuccess(false);
   };

   const handleSelect = (e) => { 
      if (e.target.type == 'checkbox') {
         if (e.target.name.substr(0, 7) == 'client_') {
            let clients = getDataClient;
            let clie = clients.filter(x => x.name == e.target.name)[0];
            clie.checked = e.target.checked;
            setDataClient(clients);
            setState({ ...state, [e.target.name]: e.target.checked });
         } else {
            setState({ ...state, [e.target.name]: e.target.checked });
         }
      } else {
         if (e.target.name == 'cboIdProfile') {
            if (e.target.value == Profile.Operations) {
               setSelectOperations(true);
            } else {
               state.ckCreateEmbarque = false;
               state.ckEditEmbarque = false;
               state.ckDeleteEmbarque = false;
               state.ckCreateClient = false;
               state.ckEditClient = false;
               state.ckDeleteClient = false;
               state.ckPanelTracking = false;
               state.ckUpdateTracking = false;
               state.allUser = '0';
               setSelectOperations(false);
            } 
         } 
         setState({ ...state, [e.target.name]: e.target.value });
      }
   };

   const handleChange = (e) => {
      if (e.target.id == 'txtUser') {

         setState({
            ...state,
            [e.target.id]: e.target.value.replace(/\s/g, '')
         })

         setValidUser(false);
         if (e.target.value == objVer.login.text) objVer.login.val = true;
         else objVer.login.val = false;
      } else {
         setState({
            ...state,
            [e.target.id]: e.target.value
         });
         if (e.target.id == 'txtPass') setValidPass(false);
         if (e.target.id == 'txtEmail') setValidEmail(false);
         if (e.target.id == 'txtCompany') setValidCompany(false);
         if (e.target.id == 'txtRUC') setValidRUC(false);
      }

   };

   const handleValidationUser = () => {
      const user = state.txtUser;
      if (user.length > 5) {
         if (user != objVer.login.text) {
            let idcompany = objSession.idcompany,
               idclient = objClient.idclient;
            setLoad(true);
            //httpClient.post('Client/VerLogin', { idcompany, idclient, user }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            httpClient.post('Login/VerLogin', { idcompany, idclient, user })
               .then(response => {
                  return response.data;
               })
               .then(response => {
                  if (response.id == 0) {
                     setValidUser(false);
                     setTextSuccess('Valid User.');
                     setSuccess(true);
                     objVer.login.text = user;
                     objVer.login.val = true;
                  } else {
                     setValidUser(true);
                     setTextError('This user is already being used.');
                     setError(true);
                     objVer.login.text = user;
                     objVer.login.val = false;
                  }
                  setLoad(false);
               })
               .catch(error => {
                  setTextError('Server error.');
                  setError(true);
                  setLoad(false);
               });
         }
      } else {
         setValidUser(true);
         setTextError('Enter a user with a length of 6 characters.');
         setError(true);
      }
   };

   const handleGenerate = () => {
      let randomstring = Math.random().toString(36).slice(-8);
      setValidPass(false);
      setState({ ...state, txtPass: randomstring });
   }

   const handleClickShowPassword = () => {
      setState({ ...state, showPassword: !state.showPassword });
   };

   const handleEdit = () => {
      setState({ ...state, isDisabled: !state.isDisabled, isShowSave: 'block', isShowEdit: 'none' });
   };

   const handleCancel = () => {
      setState({
         ...state,
         isDisabled: !state.isDisabled,
         isShowSave: 'none',
         isShowEdit: 'block',
         txtUser: objClient.user,
         txtPass: objClient.pass,
         txtEmail: objClient.email,
         txtCompany: objClient.company
      });

      objVer.login.text = objClient.user;
      objVer.login.val = true;
   };

   const handleSave = () => {
      let idclient = objClient.idclient,
         iduser = objSession.iduser,
         user = state.txtUser,
         pass = state.txtPass,
         email = state.txtEmail.trim(),
         company = isSelectOperations ? '' : state.txtCompany.trim(),
         ruc = state.txtRUC,
         idprofile = state.cboIdProfile,
         flgcreateshipment = state.ckCreateEmbarque,
         flgeditshipment = state.ckEditEmbarque,
         flgdeleteshipment = state.ckDeleteEmbarque,
         flgcreateclient = state.ckCreateClient,
         flgeditclient = state.ckEditClient,
         flgdeleteclient = state.ckDeleteClient,
         flgpaneltracking = state.ckPanelTracking,
         flgupdatetracking = state.ckUpdateTracking,
         allUser = state.allUser == '1';
      
      let user_view = '';
      if (!allUser) {
         getDataClient.filter(x => x.checked).forEach(e => {
            user_view += ',' + e.idclient;
         });
      }
      if (user_view.length > 0) {
         user_view = user_view.substr(1);
      }

      if (!objVer.login.val) {
         setValidUser(true);
         setTextError('Please validate the user by clicking the button next to it.');
         setError(true);
         return;
      }
      if (pass.length < 6) {
         setValidPass(true);
         setTextError('Please enter a valid password, or generate one with the button next to it.');
         setError(true);
         return;
      }
      if (email.length == 0) {
         setValidEmail(true);
         setTextError('Please write an email.');
         setError(true);
         return;
      } else {
         let regexText = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         if (!regexText.test(email)) {
            setValidEmail(true);
            setTextError('Please write a valid email.');
            setError(true);
            return;
         }
      }
      if (!isSelectOperations && company.length == 0) {
         setValidCompany(true);
         setTextError('Please enter a company.');
         setError(true);
         return;
      }

      if (origin == 'A' && idprofile == 0) {
         setValidIdProfile(true);
         setTextError('Please enter a profile.');
         setError(true);
         return;
      } 

      setLoad(true);
      httpClient.post('Client/Upd', { idclient, user, pass, email, company, ruc, idprofile, flgcreateshipment, 
         flgeditshipment, flgdeleteshipment, flgcreateclient, flgeditclient, flgdeleteclient, flgpaneltracking, flgupdatetracking, allUser, user_view, iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
         .then(response => {
            return response.data;
         })
         .then(response => {
            if (response.done == 1) {

               objClient.user = user;
               objClient.pass = pass;
               objClient.email = email;
               objClient.company = company;
               setTextSuccess('Saved data.');
               setSuccess(true);
               setState({ ...state, lblCompany: company, lblUser: user, lblEmail: email, isDisabled: !state.isDisabled, isShowSave: 'none', isShowEdit: 'block' });
            } else {
               setTextError('Unexpected error, check with your system administrator.');
               setError(true);
            }
            setLoad(false);
         })
         .catch(error => {
            setTextError('Server error.');
            setError(true);
            setLoad(false);
         });
   };

   const navRegister = () => {
      if (objClient != null) {
         navigate('/app/ship/info', { state: { objClient, opt: { type: 1, title: 'New Shipment', subtitle: 'Register correctly' }, objShip: null } });
      }

   };

   const getData = async () => {
      if (id != null) {
         await httpClient.post('Login/ListProfile', { })
         .then(response => {
            if (response.data.length) {
               setDataIdProfile(response.data);
            }
         })
         .catch(error => {
         });

         await httpClient.post('Client/List', { idcompany: objSession.idcompany, order_by: 'user', asc_desc: 'asc' }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
         .then(response => {
            let clients = [];
            
            let data = response.data.filter(x => x.idprofile == Profile.Client && x.state == 'Active');
            if (data.length > 0) {
               let dataU = []
               for (let i = 0; i < data.length; i++) {
                  let value = data[i];
                  dataU.push({
                     idclient: value.idclient,
                     user: value.user,
                     pass: value.pass,
                     email: value.email,
                     company: value.company,
                     state: value.state,
                     ruc: value.ruc,
                     name: 'client_' + value.idclient,
                     checked: false
                  });
               }
               setDataClient(dataU);
               clients = dataU;

               httpClient.post('Client/Sel', { idclient: id }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
               .then(response => {
                  objClient = response.data;
                  setState({
                     ...state,
                     lblCompany: objClient.company,
                     lblUser: objClient.user,
                     lblEmail: objClient.email.length ? objClient.email : 'No email',
                     lblFolder: objClient.folder.length ? objClient.folder : 'Not assigned',
                     txtUser: objClient.user,
                     txtPass: objClient.pass,
                     txtEmail: objClient.email,
                     txtCompany: objClient.company,
                     cboIdProfile: objClient.idprofile,
                     ckCreateEmbarque: objClient.flgcreateshipment,
                     ckEditEmbarque: objClient.flgeditshipment,
                     ckDeleteEmbarque: objClient.flgdeleteshipment,
                     ckCreateClient: objClient.flgcreateclient,
                     ckEditClient: objClient.flgeditclient,
                     ckDeleteClient: objClient.flgdeleteclient,
                     ckPanelTracking: objClient.flgpaneltracking,
                     ckUpdateTracking: objClient.flgupdatetracking,
                     allUser: objClient.alluser ? "1" : "0",
                     user_view: objClient.user_view
                  });
                  objVer.login.text = objClient.user;
                  objVer.login.val = true;
                  
                  setSelectOperations(Profile.Operations == objClient.idprofile);
                  setVisibleProfile(Profile.Operations == objClient.idprofile);

                  let user_view = objClient.user_view.split(',');
                  clients.forEach(e => {
                     let checked = user_view.filter(x => x == e.idclient.toString())[0];
                     e.checked = checked != null;
                  });
                  setDataClient(clients);

                  if (objClient.jsShipment.length) {
                     objClient.jsShipment = objClient.jsShipment.replaceAll('\\"', '"').replaceAll('"{', '{').replaceAll('}"', '}').replaceAll('\\', '');
                     let arrayShip = JSON.parse(objClient.jsShipment),
                        dAll = [];
                        
                     for (let i = 0; i < arrayShip.length; i++) {
                        let value = arrayShip[i],
                           transfer = '';
                        if (value.jsTransfer != null) {
                           for (let i = 0; i < value.jsTransfer.length; i++) {
                              let valueT = value.jsTransfer[i];
                              transfer += `${valueT.origin} - ${valueT.date}\r\n`
                           }
                        }
                        
                        dAll.push([
                           convertId(value.idshipment),
                           value.refclient,
                           value.booking,
                           value.container,
                           value.origin,
                           pad(value.dorigin),
                           value.target,
                           pad(value.dtarget),
                           value.datestart,
                           value.dateend,
                           '',
                           '',
                           '',
                           value.userpicbrokmar,
                           value.picclient,
                           // '$. ' + value.buy,
                           // '$. ' + value.sale,
                           value.dg == 1 ? 'Yes' : 'No',
                           value.typeContainer || '',
                           value.codeHS || '',
                           value.descHS || '',
                           transfer
                        ]);

                     }
                     objData = arrayShip;
                     setDataAll(dAll);
                  } else {
                     objData = [];
                     setDataAll([]);
                  }
               })
               .catch(error => {

               });
            }
         })
         .catch(error => {
         });
      } else {
         navigate('/app/user');
      }
   };

   const convertId = (id) => {
      let number = parseInt(id) + 1000,
         letter = 'BROK0';
      return letter + number;
   };

   const desconvertId = (ids) => {
      let id = ids.slice(4),
         n = parseInt(id) - 1000;
      return n;
   }

   const pad = (n) => {
      return (n < 10) ? '0' + n.toString() : n.toString();
   };

   useEffect(() => {
      getData();
   }, []);

   const handleConfirm = () => {
      let idshipment = valueShipment,
         iduser = objSession.iduser;
      setLoad(true);
      httpClient.post('Ship/Del', { idshipment, iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
         .then(response => {
            return response.data;
         })
         .then(response => {
            if (response.done == 1) {
               let dataU = dataAll,
                  index = null,
                  indexObj = null;

               for (let i = 0; i < dataU.length; i++) {
                  let value = dataU[i];
                  if (value[0] == idshipment) index = i;
               }
               for (let i = 0; i < objData.length; i++) {
                  let value = objData[i];
                  if (value.idshipment == idshipment) indexObj = i;
               }
               dataU.splice(index, 1);
               dataU = [...dataU];
               setDataAll(dataU);

               objData.splice(indexObj, 1);

               setOpenModal(false);

               setTextSuccess('Shipment removed.');
               setSuccess(true);
            } else {
               setTextError('Unexpected error, check with your system administrator.');
               setError(true);
            }
            setLoad(false);
         })
         .catch(error => {
            setTextError('Server error.');
            setError(true);
            setLoad(false);
         });
   };

   const handleCloseModal = () => {
      setOpenModal(false);
   };

   const body = (
      <React.Fragment>
         <Hidden mdDown>
            <Box className={classes.paper}>
               <Typography variant="h5">Are you sure you want to delete this shipment? It will no longer appear for search.</Typography>
               <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button variant="contained" size="small" color="secondary" onClick={handleConfirm}>Delete</Button>
               </Box>
            </Box>
         </Hidden>
         <Hidden lgUp>
            <Box className={classes.papermobile}>
               <Typography variant="h5">Are you sure you want to delete this shipment? It will no longer appear for search.</Typography>
               <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button variant="contained" size="small" color="secondary" onClick={handleConfirm}>Delete</Button>
               </Box>
            </Box>
         </Hidden>
      </React.Fragment>
   );

   return (
      <Page className={classes.root} title="User Summary" id="userresumen-pnl">
         <Modal
            open={openModal}
            onClose={handleCloseModal}>
            {body}
         </Modal>
         <Container maxWidth={false}>
            <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="error">
                  {lblError}
               </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="success">
                  {lblSuccess}
               </Alert>
            </Snackbar>
            <Box mt={3}>
               <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Card className={classes.border}>
                     <CardContent>
                        <Grid container spacing={3}>
                           <Grid item >
                              <Avatar className={classes.avatar} />
                           </Grid>
                           <Grid item >
                              <Typography color="primary" variant="body1">Company: {state.lblCompany}</Typography>
                              <Typography color="primary" variant="body1">User: {state.lblUser}</Typography>
                              <Typography color="primary" variant="body1">Email: {state.lblEmail}</Typography>
                              <Typography color="primary" variant="body1">Folder code: {state.lblFolder}</Typography>
                           </Grid>
                        </Grid>
                     </CardContent>
                  </Card>
               </Slide>
            </Box>
            <Box mt={3}>
               <Slide direction="up" in={true} mountOnEnter unmountOnExit style={{ transitionDelay: '250ms' }}>
                  <Card className={classes.border}>
                     <CardContent>
                        <Grid container spacing={3}>
                           <Grid item xs={12}>
                              <Box display="flex" justifyContent="space-between" >
                                 <Typography color="textPrimary" variant="h4">Profile</Typography>
                                 <Box display={state.isShowEdit}>
                                    <Tooltip title="Edit">
                                       <Fab color="primary" size="small" onClick={handleEdit}>
                                          <EditIcon />
                                       </Fab>
                                    </Tooltip>
                                 </Box>
                                 <Box display={state.isShowSave}>
                                    <Tooltip title="Save">
                                       <Fab color="primary" size="small" onClick={handleSave}>
                                          <SaveIcon />
                                       </Fab>
                                    </Tooltip>
                                    <Tooltip title="Cancel" onClick={handleCancel}>
                                       <Fab color="secondary" size="small" className={classes.b_danger}>
                                          <CancelIcon />
                                       </Fab>
                                    </Tooltip>
                                 </Box>
                              </Box>
                              <Grid container spacing={3}>
                                 <Grid item xs={12} lg={6}>
                                    <Grid container>
                                       <Grid item xs={10}>
                                          <TextField fullWidth disabled={state.isDisabled}
                                             label="User"
                                             margin="none"
                                             id="txtUser"
                                             onChange={handleChange}
                                             type="text"
                                             value={state.txtUser}
                                             variant="outlined"
                                             error={isValidUser} />
                                       </Grid>
                                       <Grid item xs={2} m="auto" className={classes.m_auto} align="center">
                                          <Tooltip title="ValiValidatedar">
                                             <IconButton color="primary" onClick={handleValidationUser} disabled={state.isDisabled}>
                                                <CheckIcon />
                                             </IconButton>
                                          </Tooltip>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                                 <Grid item xs={12} lg={6}>
                                    <Grid container>
                                       <Grid item xs={10}>
                                          <FormControl variant="outlined" margin="none" fullWidth error={isValidPass} disabled={state.isDisabled}>
                                             <InputLabel htmlFor="txtPass">Password</InputLabel>
                                             <OutlinedInput
                                                id="txtPass"
                                                type={state.showPassword ? 'text' : 'password'}
                                                value={state.txtPass}
                                                onChange={handleChange}
                                                endAdornment={
                                                   <InputAdornment position="end">
                                                      <IconButton
                                                         aria-label="toggle password visibility"
                                                         onClick={handleClickShowPassword}
                                                         edge="end"
                                                      >
                                                         {state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                      </IconButton>
                                                   </InputAdornment>
                                                }
                                                labelWidth={90}
                                             />
                                          </FormControl>
                                       </Grid>
                                       <Grid item xs={2} m="auto" className={classes.m_auto} align="center">
                                          <Tooltip title="Generate">
                                             <IconButton color="primary" onClick={handleGenerate} disabled={state.isDisabled}>
                                                <LockIcon />
                                             </IconButton>
                                          </Tooltip>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                                 <Grid item xs={12} lg={6}>
                                    <Grid container>
                                       <Grid item xs={10}>
                                          <TextField
                                             fullWidth disabled={state.isDisabled}
                                             label="Email"
                                             margin="none"
                                             id="txtEmail"
                                             onChange={handleChange}
                                             type="email"
                                             value={state.txtEmail}
                                             variant="outlined"
                                             error={isValidEmail} />
                                       </Grid>

                                    </Grid>
                                 </Grid>
                                 {isSelectOperations ? (<></>) : (<Grid item xs={12} lg={6}>
                                    <Grid container>
                                       <Grid item xs={10}>
                                          <TextField
                                             fullWidth disabled={state.isDisabled}
                                             label="Company"
                                             margin="none"
                                             id="txtCompany"
                                             onChange={handleChange}
                                             type="text"
                                             value={state.txtCompany}
                                             variant="outlined"
                                             error={isValidCompany} />
                                       </Grid>
                                    </Grid>
                                 </Grid>)}
                                 {isVisibleProfile ? (<Grid item xs={12} lg={6}>
                                    <Grid container>
                                       <Grid item xs={10}>
                                          <FormControl fullWidth variant="outlined" margin="normal">
                                             <InputLabel id="cboIdProfile">User Type</InputLabel>
                                             <Select
                                                disabled="true"
                                                labelId="cboIdProfile"
                                                name="cboIdProfile"
                                                value={state.cboIdProfile}
                                                onChange={handleSelect}
                                                label="User Type"
                                                error={isValidIdProfile}>
                                                {dataIdProfile.map((obj) => (
                                                   <MenuItem key={obj.idprofile} value={obj.idprofile} >
                                                      {obj.profilename}
                                                   </MenuItem>
                                                ))}
                                             </Select>
                                          </FormControl>
                                       </Grid>
                                    </Grid>
                                 </Grid>) : (<></>)}
                                 {isSelectOperations ? (<Grid item xs={12} lg={12}>
                                    <Card className={classes.border}> 
                                       <CardContent>
                                          <Grid container spacing={3}>
                                             <Grid item xs={12} >
                                                <FormControlLabel control={<></>}  label="Actions:" className={classes.sub_label} />
                                                <Grid container className={classes.grid_actions}>
                                                   <Grid item xs={12}>
                                                      <FormControlLabel
                                                         control={<Checkbox checked={state.ckCreateEmbarque} name="ckCreateEmbarque" onChange={handleSelect} disabled={state.isDisabled} />}
                                                         label="Create shipments" />
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                      <FormControlLabel
                                                         control={<Checkbox checked={state.ckEditEmbarque} name="ckEditEmbarque" onChange={handleSelect} disabled={state.isDisabled} />}
                                                         label="Edit shipments" />
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                      <FormControlLabel
                                                         control={<Checkbox checked={state.ckDeleteEmbarque} name="ckDeleteEmbarque" onChange={handleSelect} disabled={state.isDisabled} />}
                                                         label="Delete shipments" />
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                      <FormControlLabel
                                                         control={<Checkbox checked={state.ckCreateClient} name="ckCreateClient" onChange={handleSelect} disabled={state.isDisabled} />}
                                                         label="User creation" />
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                      <FormControlLabel
                                                         control={<Checkbox checked={state.ckDeleteClient} name="ckDeleteClient" onChange={handleSelect} disabled={state.isDisabled} />}
                                                         label="Delete users" />
                                                   </Grid>
                                                   <Grid item xs={12}>
                                                      <FormControlLabel
                                                         control={<Checkbox checked={state.ckPanelTracking} name="ckPanelTracking" onChange={handleSelect} disabled={state.isDisabled} />}
                                                         label="See tracking panel" />
                                                   </Grid>                      
                                                   <Grid item xs={12}>
                                                      <FormControlLabel className={classes.grid_actions_items}
                                                         control={<Checkbox checked={state.ckUpdateTracking} name="ckUpdateTracking" onChange={handleSelect} disabled={state.isDisabled} />}
                                                         label="Update tracking folders" />
                                                   </Grid>                          
                                                </Grid>
                                             </Grid>
                                             <Grid item xs={12} sm={12}>
                                                <FormControl>
                                                   <FormLabel id="demo-radio-buttons-group-label">Visible Clients</FormLabel>
                                                   <RadioGroup row
                                                      defaultValue="0"
                                                      name="allUser"
                                                      value={state.allUser}
                                                      onChange={handleSelect}>
                                                      <FormControlLabel value="1" control={<Radio />} label="Yes" disabled={state.isDisabled} />
                                                      <FormControlLabel value="0" control={<Radio />} label="No" disabled={state.isDisabled} />
                                                   </RadioGroup>
                                                </FormControl>
                                             </Grid>
                                             {state.allUser == "0" ? (<Grid item xs={12}>
                                                <FormControlLabel control={<></>}  label="Select clients:" className={classes.sub_label} />
                                                <Grid container className={classes.grid_clients}>
                                                   {getDataClient.map((obj) => (
                                                      <Grid item xs={12}>
                                                         <FormControlLabel
                                                            control={<Checkbox checked={obj.checked} name={obj.name} onChange={handleSelect} disabled={state.isDisabled} />}
                                                            label={obj.user} >
                                                         </FormControlLabel>
                                                      </Grid>
                                                   ))}
                                                </Grid>
                                             </Grid>) : (<></>)}
                                          </Grid>
                                       </CardContent>
                                    </Card>
                                 </Grid>) : (<></>)}

                              </Grid>
                           </Grid>
                        </Grid>
                     </CardContent>
                  </Card>
               </Slide>
            </Box>
            <Box display="flex" justifyContent="space-between" mt={4}>
               <Typography color="textPrimary" variant="h2">Embarques</Typography>
               <Button style={{ display: "none" }} color="primary" variant="contained" onClick={navRegister}> Create</Button>
            </Box>
            <Box mt={3}>
               <Slide direction="up" in={true} mountOnEnter unmountOnExit style={{ transitionDelay: '500ms' }}>
                  <Card className={classes.border}>
                     <Paper square>
                        <Tabs value={tabs} onChange={handleTabs} indicatorColor="primary" textColor="primary" aria-label="simple tabs example">
                           <Tab label="All" {...a11yProps(0)} />
                           <Tab label="Active" {...a11yProps(2)} />
                           <Tab label="Finished" {...a11yProps(3)} />
                        </Tabs>
                        <MUIDataTable
                           data={dataAll}
                           columns={columns}
                           options={options} />
                     </Paper>
                  </Card>
               </Slide>
            </Box>
            <Backdrop className={classes.backdrop} open={isLoad}>
               <CircularProgress color="inherit" />
            </Backdrop>
         </Container>
      </Page>
   );
};

export default UserView;