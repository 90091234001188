import React, { useState, useEffect } from 'react';
import { Hidden, Tooltip, TextField, IconButton, Button, Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Modal, makeStyles, Typography } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TrackItem from '../components/TrackItem';
import Tree from '../components/Tree';
import Cookies from 'universal-cookie';
import httpClient from '../services/axios-client';
import { APP_BASE } from '../constants/apiConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: 0
    },
    title: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    icon: {
        color: '#ffd54f',
        minWidth: '35px',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: `10%`,
        left: `40%`
    },
    paperTree: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: `10%`,
        left: `30%`
    },
    papermobile: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: `10%`,
    },
    btnpaper: {
        //backgroundColor: theme.palette.background.paper,
        border: '1px solid #fff',
        color: '#fff',
        boxShadow: 'none',
        padding: '1px 4px',
        fontSize: '11px',
        height: '25px'
    },
    btnicon: {
        //background: '#ffffff',
        border: '1px solid #fff',
        color: '#fff',
        height: '25px',
        borderRadius: '0%'
    }
}));

const cookies = new Cookies();


const Folder = (props) => {

    const classes = useStyles();

    const objSession = cookies.get(APP_BASE + '.objSession');

    const [valueType, setValueType] = useState(0);
    const [valueName, setValueName] = useState('');
    const [valueGenerate, setValueGenerate] = useState('');
    const [valueTree, setValueTree] = useState(null);
    const [objectEdit, setObjectEdit] = useState(null);


    const [dataPrimary, setDataPrimary] = useState(props.data.folder);
    const [dataBooking, setDataBooking] = useState([]);
    const [dataFolder, setDataFolder] = useState([]);
    const [dataCheck, setDataCheck] = useState([]);

    const [valueObject, setValueObject] = useState(null);

    const [visibleParent, setVisibleParent] = useState(true);
    const [openModal, setOpenModal] = React.useState(false);
    const [openGenerate, setOpenGenerate] = React.useState(false);
    const [openTree, setOpenTree] = React.useState(false);

    const handleName = (e) => {
        setValueName(e.target.value);
    };

    const handleAdd = () => {

        let name = valueName;
        if (name.length) {
            let idcompany = objSession.idcompany,
                iduser = objSession.iduser,
                type = valueType;
            if (type == 0) {
                let idclient = props.data.key,
                    object = valueObject,
                    idfk = null;
                if (object != null) idfk = object.idcf;
                props.onLoad(true);
                httpClient.post('Tracking/Folder/Ins', { idfk, idclient, name, iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
                    .then(response => {
                        return response.data;
                    })
                    .then(response => {
                        if (response.done == 1) {
                            let data = dataFolder,
                                dataP = dataPrimary;
                            data.push({
                                idcf: response.id,
                                idfk,
                                name
                            });

                            dataP.push({
                                idcf: response.id,
                                idfk,
                                name
                            });
                            setDataFolder(data);
                            setDataPrimary(dataP);
                            handleCloseModal();

                            props.onSuccess('Folder created.', true);
                        } else {
                            props.onError('Unexpected error, check with your system administrator.', true);
                        }
                        props.onLoad(false);
                    })
                    .catch(error => {
                        props.onError('Server error.', true);
                        props.onLoad(false);
                    });

            } else {
                let objEdit = objectEdit;
                if (name != objEdit.name) {
                    let idcf = objEdit.idcf;
                    props.onLoad(true);
                    httpClient.post('Tracking/Folder/Upd', { idcf, name, iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
                        .then(response => {
                            return response.data;
                        })
                        .then(response => {
                            if (response.done == 1) {
                                let data = dataFolder,
                                    dataP = dataPrimary;
                                for (let i = 0; i < data.length; i++) {
                                    let value = data[i];
                                    if (value.idcf == idcf) {
                                        value.name = name;
                                    }
                                }
                                for (let i = 0; i < dataP.length; i++) {
                                    let value = dataP[i];
                                    if (value.idcf == idcf) {
                                        value.name = name;
                                    }
                                }
                                setDataFolder(data);
                                setDataPrimary(dataP);

                                props.onSuccess('Updated folder.', true);
                                handleCloseModal();
                            } else {
                                props.onError('Unexpected error, check with your system administrator.', true);
                            }
                            props.onLoad(false);
                        })
                        .catch(error => {
                            props.onError('Server error.', true);
                            props.onLoad(false);
                        });
                } else {
                    handleCloseModal();
                }

            }
        }
    };

    const handleBack = () => {
        let object = valueObject;
        if (object != null) {
            let idfk = object.idfk;
            let data = [],
                newO = null;
            for (let i = 0; i < dataPrimary.length; i++) {
                let value = dataPrimary[i];
                if (value.idfk == idfk) {
                    data.push(value);
                }
                if (value.idcf == idfk) {
                    newO = value;
                }
            }
            data = [...data];
            setDataFolder(data);
            setValueObject(newO);
            if (idfk == null) {
                setVisibleParent(true);
                setDataBooking([]);
            } else {
                handleListBooking(newO.idcf);
            }
            props.onCleanMap();
            setDataCheck([]);
        }
    };

    const handleSelect = (object) => {
        let idcf = object.idcf;
        let data = [];
        for (let i = 0; i < dataPrimary.length; i++) {
            let value = dataPrimary[i];
            if (value.idfk == idcf) {
                data.push(value);
            }
        }
        data = [...data];
        setDataFolder(data);
        setValueObject(object);
        setVisibleParent(false);

        handleListBooking(idcf);
        props.onCleanMap();
        setDataCheck([]);
    };

    const handleListBooking = (idcf) => {
        let idcompany = objSession.idcompany;
        props.onLoad(true);
        httpClient.post('Tracking/List', { idcompany, idcf }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
                return response.data;
            })
            .then(response => {
                let data = [];
                if (response.length) {
                    for (let i = 0; i < response.length; i++) {
                        let value = response[i];
                        data.push({
                            idshipment: value.idshipment,
                            title: value.booking,
                            select: false,
                            value
                        });
                    }
                }
                setDataBooking(data);
                props.onLoad(false);
            })
            .catch(error => {
                props.onError('Server error.', true);
                props.onLoad(false);
            });
    }

    const handleEdit = (object) => {
        setValueType(1);
        setObjectEdit(object);
        setValueName(object.name);
        setOpenModal(true);
    };

    const handleDelete = (object) => {
        console.log(object);
        console.log(dataFolder);
        console.log(dataPrimary);
        let iduser = objSession.iduser,
            idcf = object.idcf;
        props.onLoad(true);
        httpClient.post('Tracking/Folder/Del', { idcf, iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
                return response.data;
            })
            .then(response => {
                if (response.done == 1) {
                    let data = dataFolder,
                        dataP = dataPrimary,
                        index = 0,
                        indexP = 0;
                    for (let i = 0; i < data.length; i++) {
                        let value = data[i];
                        if (value.idcf == idcf) {
                            index = i;
                        }
                    }
                    data.splice(index, 1);
                    for (let i = 0; i < dataP.length; i++) {
                        let value = dataP[i];
                        if (value.idcf == idcf) {
                            indexP = i;
                        }
                    }
                    dataP.splice(indexP, 1);
                    setDataFolder(data);
                    setDataPrimary(dataP);

                    props.onSuccess('Deleted folder.', true);
                } else if (response.done == 2) {
                    props.onError('This folder contains other folders or traces inside.', true);
                } else {
                    props.onError('Unexpected error, check with your system administrator.', true);
                }
                props.onLoad(false);
            })
            .catch(error => {
                props.onError('Server error.', true);
                props.onLoad(false);
            });
    };

    useEffect(() => {
        let data = [];
        for (let i = 0; i < dataPrimary.length; i++) {
            let value = dataPrimary[i];
            if (value.idfk == null) {
                data.push(value);
            }
        }
        data = [...data];
        setDataFolder(data);
        setValueObject(null);
    }, []);

    const handleCloseModal = () => {
        setValueType(0);
        setObjectEdit(null);
        setValueName('');
        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseGenerate = () => {
        setValueGenerate('');
        setOpenGenerate(false);
    };


    const handleOpenGenerate = () => {
        setValueGenerate(props.data.client.folder);
        setOpenGenerate(true);
    };

    const handleGenerate = () => {
        let idclient = props.data.key,
            iduser = objSession.iduser,
            folder = Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6);
        props.onLoad(true);
        httpClient.post('Tracking/Folder/Key', { idclient, folder, iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
                return response.data;
            })
            .then(response => {
                if (response.done == 1) {
                    props.data.client.folder = folder;
                    setValueGenerate(folder);
                } else {
                    props.onError('Unexpected error, check with your system administrator.', true);
                }
                props.onLoad(false);
            })
            .catch(error => {
                props.onError('Server error.', true);
                props.onLoad(false);
            });

    };

    const bodyFolder = (
        <React.Fragment>
            <Hidden mdDown>
                <Box className={classes.paper}>
                    <Typography variant="h5">Enter the name of the folder</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Folder name"
                        value={valueName}
                        onChange={handleName}
                        variant="outlined" />
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" size="small" color="primary" onClick={handleAdd}>Save</Button>
                    </Box>
                </Box>
            </Hidden>
            <Hidden lgUp>
                <Box className={classes.papermobile}>
                    <Typography variant="h5">Enter the name of the folder</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Folder name"
                        value={valueName}
                        onChange={handleName}
                        variant="outlined" />
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" size="small" color="primary" onClick={handleAdd}>Save</Button>
                    </Box>
                </Box>
            </Hidden>
        </React.Fragment>
    );

    const bodyGenerate = (
        <React.Fragment>
            <Hidden mdDown>
                <Box className={classes.paper}>
                    <Typography variant="h5">Generate key</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Key"
                        value={valueGenerate}
                        disabled={true}
                        variant="outlined" />
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" size="small" color="primary" onClick={handleGenerate}>Generate</Button>
                    </Box>
                </Box>
            </Hidden>
            <Hidden lgUp>
                <Box className={classes.papermobile}>
                    <Typography variant="h5">Generate key</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Key"
                        value={valueGenerate}
                        disabled={true}
                        variant="outlined" />
                    <Box display="flex" justifyContent="flex-end">
                        <Button variant="contained" size="small" color="primary" onClick={handleGenerate}>Generate</Button>
                    </Box>
                </Box>
            </Hidden>
        </React.Fragment>
    );

    const handleCloseTree = () => {
        setValueTree(null);
        setOpenTree(false);
    };

    const handleOpenTree = () => {
        if (dataCheck.length) {
            setOpenTree(true);
        } else {
            props.onError('There is not an item selected.', true);
        }

    };

    const handleMove = () => {
        let idcf = valueTree,
            iduser = objSession.iduser;
        if (idcf != null) {
            idcf = parseInt(idcf);
            props.onLoad(true);
            httpClient.post('Tracking/Folder/Assign', { idcf, jsShipment: JSON.stringify(dataCheck), iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
                .then(response => {
                    return response.data;
                })
                .then(response => {
                    if (response.done == 1) {
                        if (dataBooking.length) {
                            let booking = dataBooking;
                            for (let i = 0; i < dataCheck.length; i++) {
                                let value = dataCheck[i],
                                    indexB = null;
                                for (let j = 0; j < booking.length; j++) {
                                    let val = booking[j];
                                    if (val.idshipment == value.idshipment) {
                                        indexB = j;
                                    }
                                }
                                booking.splice(indexB, 1);
                            }
                            setDataBooking(booking);
                        } else {
                            let booking = props.data.booking;
                            for (let i = 0; i < dataCheck.length; i++) {
                                let value = dataCheck[i],
                                    indexB = null;
                                for (let j = 0; j < booking.length; j++) {
                                    let val = booking[j];
                                    if (val.idshipment == value.idshipment) {
                                        indexB = j;
                                    }
                                }
                                booking.splice(indexB, 1);
                            }
                        }
                        setDataCheck([]);
                        props.onSuccess('Items moved.', true);
                        handleCloseTree();
                        props.onCleanMap();
                    } else {
                        props.onError('Unexpected error, check with your system administrator.', true);
                    }
                    props.onLoad(false);
                })
                .catch(error => {
                    props.onError('Server error.', true);
                    props.onLoad(false);
                });

        } else {
            props.onError('Select a folder.', true);
        }

    };

    const handleTree = (idtree) => {
        setValueTree(idtree);
    };

    const handleCheck = (idshipment) => {

        let data = dataCheck,
            index = null;
        for (let i = 0; i < data.length; i++) {
            let value = data[i];
            if (value.idshipment == idshipment) index = i;
        }
        if (index == null) {
            data.push({ idshipment });
        } else {
            data.splice(index, 1);
        }
        setDataCheck(data);
    };

    const bodyTree = (
        <React.Fragment>
            <Hidden mdDown>
                <Box className={classes.paperTree}>
                    <Box mb={2}>
                        <Typography variant="h4" >{props.data.client.user}</Typography>
                    </Box>
                    <Tree data={dataPrimary} onTree={handleTree}></Tree>
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                        <Button variant="contained" size="small" color="primary" onClick={handleMove}>Move</Button>
                    </Box>
                </Box>
            </Hidden>
            <Hidden lgUp>
                <Box className={classes.papermobile}>
                    <Box mb={2}>
                        <Typography variant="h4" >{props.data.client.user}</Typography>
                    </Box>
                    <Tree data={dataPrimary} onTree={handleTree}></Tree>
                    <Box display="flex" justifyContent="flex-end" mt={3}>
                        <Button variant="contained" size="small" color="primary" onClick={handleMove}>Move</Button>
                    </Box>
                </Box>
            </Hidden>
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={handleCloseModal}>
                {bodyFolder}
            </Modal>
            <Modal
                open={openGenerate}
                onClose={handleCloseGenerate}>
                {bodyGenerate}
            </Modal>
            <Modal
                open={openTree}
                onClose={handleCloseTree}>
                {bodyTree}
            </Modal>
            <Box className={classes.title} p={1} mt={2} display="flex" justifyContent="space-between">
                <Typography variant="subtitle1" color="primary">{props.data.client.user}</Typography>
                <Tooltip title="Close">
                    <IconButton color="secondary" size="small" onClick={() => props.removeClick(props.data.key)}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>

            </Box>
            <Box p={1}>
                <Box display="flex" justifyContent="space-between" mb={1}>
                    <Box>
                        <Tooltip title="Back">
                            <IconButton size="small" color="primary" onClick={handleBack} className={classes.btnicon}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {props.showAction ? (<Button
                        className={classes.btnpaper}
                        size="small"
                        startIcon={<AddIcon />} onClick={handleOpenModal}> Create folder</Button>) : (<></>)}
                    {props.showAction ? (<Button
                        className={classes.btnpaper}

                        size="small"
                        endIcon={<ChevronRightIcon />} onClick={handleOpenTree}> Move to</Button>) : (<></>)}
                </Box>
                <List dense={true} className={classes.root}>
                    {dataFolder.map((object, i) => {
                        return (
                            <ListItem button key={object.idcf} onClick={() => { handleSelect(object) }}>
                                <ListItemIcon className={classes.icon}>
                                    <FolderIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={object.name} />
                                {props.showAction ? (<ListItemSecondaryAction>
                                    <Tooltip title="Editar">
                                        <IconButton aria-label="edit" size="small" color="primary" onClick={() => { handleEdit(object) }}>
                                            <EditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Eliminar">
                                        <IconButton aria-label="delete" size="small" color="secondary" onClick={() => { handleDelete(object) }}>
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    </Tooltip>
                                </ListItemSecondaryAction>) : (<></>)}
                                
                            </ListItem>
                        );
                    })}
                </List>
                <Box>
                    {visibleParent && props.data.booking.map((object, i) => {
                        return (
                            <TrackItem showAction={props.showAction} key={object.idshipment} data={object} onShipment={props.onShipment} onCheck={handleCheck} />
                        );
                    })}
                    {dataBooking.map((object, i) => {
                        return (
                            <TrackItem showAction={props.showAction} key={object.idshipment} data={object} onRoute={props.onRoute} onCheck={handleCheck} />
                        );
                    })}
                </Box>
            </Box>

        </React.Fragment>
    );

}

export default Folder;