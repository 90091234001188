import React, { useState, useEffect } from 'react';
import {
   Hidden, Snackbar, CircularProgress, Backdrop, Modal, Box, Container, IconButton, Button, Card, makeStyles, Typography, Tooltip, Slide, Icon
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import httpClient from '../../services/axios-client';
import Cookies from 'universal-cookie';
import MUIDataTable from "mui-datatables";
import { APP_BASE } from '../../constants/apiConstants';
import { CheckCircleOutline, CloseOutlined, CloseRounded, ClosedCaptionOutlined, FolderSharedOutlined } from '@material-ui/icons';
import { Profile } from '../../constants/baseConstants';

const cookies = new Cookies();

const Alert = (props) => {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = {
   filter: true,
   filterType: "dropdown",
   selectableRows: false
};

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      '& .MuiTableCell-root':{
         textAlign:'left',
         minWidth: '150px'
      },
      '& .MuiTableCell-head span':{
         justifyContent: 'left',
         //margin: '0px 0px 0px 30px'
      },
      '& MUIDataTableHeadCell-toolButton-82': {
         
      }
   },
   rows: {
      textAlign: 'center',
      '& .MuiButton-root': {
         margin: '10px 0px 0px 0px',
      },
      '& .MuiButtonBase-root': {
         width: '200px'
      }
   },
   marginBtn: {
      margintop: '5px'
   },
   container: {
      maxHeight: 440,
   },
   danger: {
      color: '#e91e63'
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
   paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `10%`,
      left: `40%`,
   },
   papermobile: {
      position: 'absolute',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `10%`,
   },
   border:{
      border:'1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius:'5px',
      '& .MuiToolbar-root[role=toolbar]':{
         borderBottom:'1px solid',
         borderColor: theme.palette.primary.main,
      },
      '& .MuiTableCell-body, .MuiTableCell-head':{
         borderColor: theme.palette.primary.main,
      }
   }
}));

const UserView = () => {
   const classes = useStyles();

   const navigate = useNavigate();

   const objSession = cookies.get(APP_BASE + '.objSession');

   const [valuePerson, setValuePerson] = useState(null);

   const [data, setData] = useState([]);

   const [openModal, setOpenModal] = React.useState(false);

   const [openModalApprove, setOpenModalApprove] = React.useState(false);
   const [openModalDisapprove, setOpenModalDisapprove] = React.useState(false);

   const [isLoad, setLoad] = useState(false);

   const [isSuccess, setSuccess] = useState(false);
   const [lblSuccess, setTextSuccess] = useState('');

   const [isError, setError] = useState(false);
   const [lblError, setTextError] = useState('');

   const [isVisibleCreateUser, setVisibleCreateUser] = useState(false);
   const [isVisibleEditUser, setVisibleEditUser] = useState(false);
   const [isVisibleDeleteUser, setVisibleDeleteUser] = useState(false);
   const [isAdministrador, setAdministrador] = useState(false);
   
   
   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setError(false);
      setSuccess(false);
   };

   const columns = [
      { name: 'ID'},
      { name: 'User' },
      { name: 'Password' },
      { name: 'Email' },
      { name: 'Company' },
      { name: 'User Type' },
      { name: 'State' },
      {
         name: 'Action', options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex, rowIndex) => {
               let state = data[dataIndex][6];
               let idprofile = data[dataIndex][8];
               let isPending = (state == 'Pending');
               let isActive = (state == 'Active');
               let isDisabled = (state == 'Inactive');
               let isClient = idprofile == Profile.Client;

               return (
                  <React.Fragment>
                     
                     {isVisibleEditUser ? (<Tooltip title="Edit">
                        <IconButton color="primary" size="small" onClick={() => {
                           let idperson = data[dataIndex][0];
                           navigate('/app/user/resumen', { state: { idperson } });
                        }}>
                           <EditIcon fontSize="inherit" />
                        </IconButton>
                     </Tooltip>) : (<></>)} 
                     
                     {isActive && isClient ? (<Tooltip title="Show Folders">
                        <IconButton color="primary" size="small" onClick={() => {
                           let idperson = data[dataIndex][0];
                           navigate(objSession.flgupdatetracking ? '/app/trackingOpe' : '/app/tracking', { state: { idperson } });
                        }}>
                           <FolderSharedOutlined fontSize="inherit" />
                        </IconButton>
                     </Tooltip>) : (<></>)}
                     
                     
                     {isVisibleDeleteUser ? (<Tooltip title="Delete">
                        <IconButton color="secondary" size="small" onClick={() => {
                           let idperson = data[dataIndex][0];
                           setValuePerson(idperson);
                           setOpenModal(true);
                        }}>
                           <DeleteIcon fontSize="inherit" />
                        </IconButton>
                     </Tooltip>) : (<></>)}
                     
                     {
                        (isAdministrador && (isPending || isDisabled)) ? (
                        <Tooltip title="Active">
                           <IconButton color="primary" size="small" onClick={() => {
                              let idperson = data[dataIndex][0];
                              setValuePerson(idperson);
                              setOpenModalApprove(true);
                           }}>
                              <CheckCircleOutline fontSize="inherit" />
                           </IconButton>
                        </Tooltip>) : 
                        (<></>)
                     }
                     {
                        (isAdministrador && (isPending || isActive)) ? (
                           <Tooltip title="Disabled">
                           <IconButton color="primary" size="small" onClick={() => {
                              let idperson = data[dataIndex][0];
                              setValuePerson(idperson);
                              setOpenModalDisapprove(true);
                           }}>
                              <CloseOutlined fontSize="inherit" />
                           </IconButton>
                        </Tooltip>) : 
                        (<></>)
                     }
                  </React.Fragment>
               );
            }
         }
      }
   ];
   
   const navRegister = () => {
      navigate('/app/user/register');
   };

   const navRegisterOperations = () => {
      navigate('/app/user/operations');
   };
   
   const handleApprove = () => {
      handleApproveDisapprove('A')
   }

   const handleDisapprove = () => {
      handleApproveDisapprove('D')
   }

   const handleApproveDisapprove = (state) => {
      let idclient = valuePerson,
      iduser = objSession.iduser;
      setLoad(true);
      setOpenModalApprove(false);
      setOpenModalDisapprove(false);
      httpClient.post('Client/updState', { state, idclient, iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
         .then(response => {
            return response.data;
         })
         .then(response => {
            if (response.done == 1) {
               getData();
               setTextSuccess(state == 'A' ? 'Active User' :'Disabled User');
               setSuccess(true);
            } else {
               setTextError('Unexpected error, check with your system administrator.');
               setError(true);
            }
            setLoad(false);
         })
         .catch(error => {
            if (state == 'A') {
               setOpenModalApprove(true);
            }
            else {
               setOpenModalDisapprove(false);
            }
            setTextError('Server error.');
            setError(true);
            setLoad(false);
         });
   };

   const handleConfirm = () => {
      let idclient = valuePerson,
         iduser = objSession.iduser;
      setLoad(true);
      setOpenModal(false);
      httpClient.post('Client/Del', { idclient, iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
         .then(response => {
            return response.data;
         })
         .then(response => {
            if (response.done == 1) {
               let dataU = data;
               dataU = dataU.filter(x => x[0] != idclient);
               setData(dataU);
               setTextSuccess('User deleted.');
               setSuccess(true);
            } else {
               setTextError('Unexpected error, check with your system administrator.');
               setError(true);
            }
            setLoad(false);
         })
         .catch(error => {
            setOpenModal(true);
            setTextError('Server error.');
            setError(true);
            setLoad(false);
         });
   };

   const getData = async () => {
      setAdministrador(objSession.idprofile == Profile.Administrador);
      setLoad(true);
      await httpClient.post('Client/List', { idcompany: objSession.idcompany, iduser: objSession.iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
      .then(response => {
         return response.data;
      })
      .then(response => {
         if (response.length) {
            let dataU = []
            for (let i = 0; i < response.length; i++) {
               let value = response[i];
               dataU.push([
                  value.idclient,
                  value.user,
                  value.pass,
                  value.email,
                  value.company,
                  value.profilename,
                  value.state,
                  value.ruc,
                  value.idprofile
               ]);
            }
            setData(dataU);
         }
         setLoad(false);
      })
      .catch(error => {
         setLoad(false);
      });
      
      setVisibleCreateUser(objSession.flgcreateclient ?? false);
      setVisibleEditUser(objSession.flgeditclient ?? false);
      setVisibleDeleteUser(objSession.flgdeleteclient ?? false);
   };

   useEffect(() => {
      getData();
   }, []);

   const handleCloseModal = () => {
      setOpenModal(false);
   };

   const body = (
      <React.Fragment>
         <Hidden mdDown>
            <Box className={classes.paper}>
               <Typography variant="h5">You are sure you want to delete this user, all their shipments will be deleted.</Typography>
               <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button variant="contained" size="small" color="secondary" onClick={handleConfirm}>Delete</Button>
               </Box>
            </Box>
         </Hidden>
         <Hidden lgUp>
         <Box className={classes.papermobile}>
               <Typography variant="h5">You are sure you want to delete this user, all their shipments will be deleted.</Typography>
               <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button variant="contained" size="small" color="secondary" onClick={handleConfirm}>Delete</Button>
               </Box>
            </Box>
         </Hidden>
      </React.Fragment>
   );

   const handleCloseModalApprove = () => {
      setOpenModalApprove(false);
   };

   const handleCloseModalDisapprove = () => {
      setOpenModalDisapprove(false);
   };

   const bodyApprove = (
      <React.Fragment>
         <Hidden mdDown>
            <Box className={classes.paper}>
               <Typography variant="h5">Are you sure to active this user?</Typography>
               <Box className={classes.rows} justifyContent="space-between" mt={2}>
                  <Button variant="contained" size="small" color="primary" onClick={handleApprove}>Activate</Button>
                  <Button variant="contained" size="small" color="secondary" onClick={handleCloseModalApprove}>Cancel</Button><br></br>
               </Box>
            </Box>
         </Hidden>
         <Hidden lgUp>
         <Box className={classes.papermobile}>
               <Typography variant="h5">Are you sure to active this user?</Typography>
               <Box className={classes.rows} justifyContent="space-between" mt={2}>
                  <Button variant="contained" size="small" color="primary" onClick={handleApprove}>Activate</Button><br></br>
                  <Button variant="contained" size="small" color="secondary" onClick={handleCloseModalApprove}>Cancel</Button>
               </Box>
            </Box>
         </Hidden>
      </React.Fragment>
   );

   const bodyDisapprove = (
      <React.Fragment>
         <Hidden mdDown>
            <Box className={classes.paper}>
               <Typography variant="h5">Are you sure to inactive this user?</Typography>
               <Box className={classes.rows} justifyContent="space-between" mt={2}>
                  <Button variant="contained" size="small" color="primary" onClick={handleDisapprove}>Inactivate</Button><br></br>
                  <Button variant="contained" size="small" color="secondary" onClick={handleCloseModalDisapprove}>Cancel</Button>
               </Box>
            </Box>
         </Hidden>
         <Hidden lgUp>
         <Box className={classes.papermobile}>
               <Typography variant="h5">Are you sure to inactive this user?</Typography>
               <Box className={classes.rows} justifyContent="space-between" mt={2}>
                  <Button variant="contained" size="small" color="primary" onClick={handleDisapprove}>Inactivate</Button><br></br>
                  <Button variant="contained" size="small" color="secondary" onClick={handleCloseModalDisapprove}>Cancel</Button>
               </Box>
            </Box>
         </Hidden>
      </React.Fragment>
   );

   return (
      <Page className={classes.root} title="User Management" id="user-pnl">
         <style>
            {`@media print{.MuiTableRow-root:before {content: 'User Details'; color: #263238; font-family: Helvetica, sans-serif; font-size: 18px;margin-top: 25px;margin-bottom: 10px;display: block;padding-left:10px;}}`}
         </style>
         <Modal
            open={openModal}
            onClose={handleCloseModal}>
            {body}
         </Modal>
         <Modal
            open={openModalApprove}
            onClose={handleCloseModalApprove}>
            {bodyApprove}
         </Modal>
         <Modal
            open={openModalDisapprove}
            onClose={handleCloseModalDisapprove}>
            {bodyDisapprove}
         </Modal>
         <Container maxWidth={false}>
            <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="error">
                  {lblError}
               </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="success">
                  {lblSuccess}
               </Alert>
            </Snackbar>
            <Box display="flex" justifyContent="space-between"> 
               <Typography color="textPrimary" variant="h2">User Management</Typography>
               <Box>
                  {isVisibleCreateUser ? (<Button color="primary" variant="contained" onClick={navRegister}> Create</Button>) : (<></>)}&nbsp;&nbsp;
                  {isAdministrador ? (<Button color="primary" variant="contained" onClick={navRegisterOperations}> Staff access</Button>) : (<></>)}
               </Box>
               
            </Box>

            <Box mt={5}>
               <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Card className={classes.border}>
                     <MUIDataTable
                        title={"User List"}
                        data={data}
                        columns={columns}
                        options={options}
                     />
                  </Card>
               </Slide>
            </Box>
            <Backdrop className={classes.backdrop} open={isLoad}>
               <CircularProgress color="inherit" />
            </Backdrop>
         </Container>
      </Page>
   );
};

export default UserView;