import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Avatar, Badge, Box, Hidden, IconButton, Toolbar, Button, Tooltip, makeStyles, Popover, List, ListItem, ListItemText, ListItemAvatar, Divider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import Cookies from 'universal-cookie';
import { APP_BASE } from '../../../constants/apiConstants';
import moment from 'moment';
import httpClient from '../../../services/axios-client';
import { Menu } from '../../../constants/baseConstants';

const cookies = new Cookies();

const items = [];

let objSession = null;

if (cookies.get(APP_BASE + '.objSession')) {
   objSession = cookies.get(APP_BASE + '.objSession');
   if (objSession.jsMenu != null) {
      let arrayMenu = JSON.parse(objSession.jsMenu);
      if (!objSession.flgpaneltracking) {
         arrayMenu = arrayMenu.filter(x => x.idmenu != Menu.Tracking);
      }
      if (!(objSession.flgcreateclient || objSession.flgdeleteclient)) {
         arrayMenu = arrayMenu.filter(x => x.idmenu != Menu.Client);
      }
      for (let i = 0; i < arrayMenu.length; i++) {
         let value = arrayMenu[i];
         items.push({
            href: value.url,
            title: value.title
         });
      }
   }
}

const useStyles = makeStyles((theme) => ({
   root: {
      background: theme.palette.secondary.light
   },
   rootN: {
      width: '100%',
      minWidth: 400,
      maxWidth: 400,
      backgroundColor: theme.palette.background.paper,
   },
   avatar: {
      width: 60,
      height: 60
   },
   menuButton: {
      fontFamily: 'Open Sans, sans-serif',
      size: '20px',
      marginRight: '25px',
      background: '#fff'
   },
   active: {
      background: theme.palette.primary.main,
      color: '#fff'
   },
   margin: {
      marginRight: '150px'
   },
   textCenter: {
      textAlign: 'center'
   }
}));

const TopBar = ({
   className,
   onMobileNavOpen,
   ...rest
}) => {
   const classes = useStyles();

   const navigate = useNavigate();

   const objSession = cookies.get(APP_BASE + '.objSession');

   const [anchorEl, setAnchorEl] = useState(null);
   const [notification, setNotification] = useState([]);

   const LogOut = (e) => {
      cookies.remove(APP_BASE + '.objSession', { path: '/' });
      cookies.remove(APP_BASE + '.token', { path: '/' });

      //navigate('./login');
      //navigate(process.env.PUBLIC_URL + '/login');
      window.location.href = process.env.PUBLIC_URL; //+ '/login';
   };

   const OpenNot = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const CloseNot = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);
   const id = open ? 'simple-popover' : undefined;

   const Setting = (e) => {
      navigate('/app/setting');
   };

   const Account = (e) => {
      navigate('/app/account');
   };

   const Notification = (e) => {
      CloseNot();
      setNotification([]);
      navigate('/app/notification');

   };

   const getData = async () => {
      if (typeof cookies.get(APP_BASE + '.boNotification') === 'undefined') {
         await httpClient.post('Notification/List', { iduser: objSession.iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
               return response.data;
            })
            .then(response => {
               if (response.length) {
                  setNotification(response);
                  cookies.set(APP_BASE + '.listNotification', JSON.stringify(response), { path: '/' });
               } else {
                  cookies.set(APP_BASE + '.listNotification', '[]', { path: '/' });
               }
               cookies.set(APP_BASE + '.boNotification', 1, { path: '/', expires: new Date(moment().add(4, 'h').format()) });
            })
            .catch(error => {

            });
      } else {
         if (typeof cookies.get(APP_BASE + '.listNotification') !== 'undefined') {
            let arrayNot = cookies.get(APP_BASE + '.listNotification');
            setNotification(arrayNot);
            if (arrayNot.length == 0) {
               cookies.set(APP_BASE + '.listNotification', '[]', { path: '/' });
            }
         } else {
            setNotification([]);
            cookies.set(APP_BASE + '.listNotification', '[]', { path: '/' });
         }
      }
   };

   useEffect(() => {
      getData();
   }, []);

   return (
      <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
         <Toolbar>
            <RouterLink to="/">
               <img src='/images/principal/logo-blue-brookmar.svg' height={50} />
            </RouterLink>
            <Box flexGrow={1} />
            <Hidden mdDown>
               {/*<IconButton color="inherit" onClick={Setting}>
                  <SettingIcon />
               </IconButton>*/}
               <Box flexGrow={1} >
                  {items.map((item) => (
                     <Button variant="contained" activeClassName={classes.active} className={classes.menuButton} key={item.title} component={RouterLink} to={item.href}>
                        {item.title}
                     </Button>
                  ))}
               </Box>
               <Tooltip title="Profile">
                  <Button style={{ textTransform: 'none' }} color="primary" onClick={Account} startIcon={<Avatar alt="Remy Sharp" src="/images/principal/profile.png" />}>
                     {objSession.name}
                  </Button>
               </Tooltip>
               <Tooltip title="Notifications">
                  <IconButton color="primary" onClick={OpenNot}>
                     <Badge badgeContent={notification.length} color="secondary">
                        <NotificationsIcon />
                     </Badge>
                  </IconButton>
               </Tooltip>
               <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={CloseNot}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
               >

                  <List className={classes.rootN}>
                     {notification.map((not) => (
                        <ListItem key={not.idnu}>
                           <ListItemAvatar>
                              <Avatar>
                                 <DirectionsBoatIcon />
                              </Avatar>
                           </ListItemAvatar>
                           <ListItemText primary={'Code: ' + not.booking + ' is ' + not.description + ' in ' + not.location} secondary={not.date + ', ' + not.origin + ' - ' + not.target} />
                        </ListItem>

                     ))}
                     {notification.length ? (<></>) : (
                        <ListItem className={classes.textCenter}>
                           <ListItemText primary="You do not have any pending notification." />
                        </ListItem>
                     )}
                     <Divider />
                     <ListItem button onClick={Notification} className={classes.textCenter}>
                        <ListItemText primary="SEE NOTIFICATIONS" />
                     </ListItem>
                  </List>

               </Popover>
               <Tooltip title="Log Out">
                  <IconButton color="primary" onClick={LogOut}>
                     <InputIcon />
                  </IconButton>
               </Tooltip>
            </Hidden>
            <Hidden lgUp>
               <IconButton color="primary" onClick={OpenNot}>
                  <Badge badgeContent={notification.length} color="secondary">
                     <NotificationsIcon />
                  </Badge>
               </IconButton>

               <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={CloseNot}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
               >

                  <List component="nav" className={classes.rootN}>
                     {notification.map((not) => (
                        <ListItem key={not.idnu}>
                           <ListItemAvatar>
                              <Avatar>
                                 <DirectionsBoatIcon />
                              </Avatar>
                           </ListItemAvatar>
                           <ListItemText primary={'Code: ' + not.booking + ' is ' + not.description + ' in ' + not.location} secondary={not.date + ', ' + not.origin + ' - ' + not.target} />
                        </ListItem>

                     ))}
                     {notification.length ? (<></>) : (
                        <ListItem className={classes.textCenter}>
                           <ListItemText primary="You do not have any pending notification." />
                        </ListItem>
                     )}
                     <Divider />
                     <ListItem button onClick={Notification} className={classes.textCenter}>
                        <ListItemText primary="SEE ALL NOTIFICATIONS" />
                     </ListItem>
                  </List>

               </Popover>
               <IconButton color="primary" onClick={LogOut}>
                  <InputIcon />
               </IconButton>
               <IconButton color="primary" onClick={onMobileNavOpen}>
                  <MenuIcon />
               </IconButton>

            </Hidden>
         </Toolbar>
      </AppBar>
   );
};

TopBar.propTypes = {
   className: PropTypes.string,
   onMobileNavOpen: PropTypes.func
};

export default TopBar;