import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import { esES } from '@material-ui/core/locale';

const theme = createMuiTheme({
  palette: {

    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      light: '#c79e4d',
      main: '#205ba8',
      dark: '#737373',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e8e9eb',
      main: '#737373',
      dark: '#ba000d',
      contrastText: '#fff',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    }
  },
  shadows,
  typography
}, esES);

export default theme;