import React, { useState, useEffect } from 'react';
import { Slider, Card, CardContent, Box, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import clsx from 'clsx';
import moment from 'moment';

moment.locale('es');

function ThumbComponent(props) {
    return (
      <span {...props}>
        <DirectionsBoatIcon className="icon" />
      </span>
    );
  }

const DefaultSlider = withStyles({
    root: {
      padding: 0,
    },
    thumb: {
        height: 27,
        width: 27,
        marginTop: -12,
        marginLeft: -13,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
          boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .icon': {
          // display: inline-block !important;
          height: 15,
          width: 15,
          color: 'white',
          marginLeft: 1,
          marginRight: 1,
        },
      },
    active: {},
    track: {
      height: 2,
    },
    rail: {
      height: 2,
      opacity: 1,
      backgroundColor: '#0e264c',
    },
    mark: {
      backgroundColor:  '#0e264c',
      height: 10,
      width: 10,
      borderRadius:'50%',
      marginTop: -4,
    },
    markActive: {
      opacity: 1,
      backgroundColor: 'currentColor!important',
    },
  })(Slider);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: 0,
        boxShadow: 'none'
    },
    logo: {
        height: 29,
        border: '1px solid',
        borderColor: theme.palette.primary.main
    },
    imglogo: {
        height: 27,
        width: '100%'
    },
    contentBook: {
        borderRight: '2px solid #f4f6f8',
    },
    contentDetail: {
        width: "100%",
        cursor: 'pointer'
    },
    date: {
        fontSize: 9
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    active: {
        border: '3px solid',
        borderColor: '#fff',
        backgroundColor: theme.palette.primary.main,
        color:'#fff',
        '& .MuiCheckbox-colorPrimary.Mui-checked ':{
            color:'white'
        },
        '& .MuiSlider-rail':{
            backgroundColor: '#ffffff',
            opacity: 1
        },
        '& .MuiSlider-mark':{
            backgroundColor: '#ffffff',
        }
    },
    container: {
        fontSize: '0.60rem'

    },
    form: {
        margin: 0
    },
    checkbox: {
        padding: 0
    },
    title: {
        fontSize: '12px',
    },
    lblTypo:{
        fontSize: '11px'
    }
}));

const TrackItem = (props) => {

    const classes = useStyles();

    const [state, setState] = useState({
        txtStart: '',
        txtEnd: '',
        txtDStart: '',
        txtDEnd: '',
        txtDays: 0,
        slide: 0
    });

    const handleClick = () => {
        if (typeof props.onShipment === 'undefined') {
            props.onRoute(props.data);
        } else {
            let idshipment = props.data.idshipment;
            props.onShipment(idshipment);
        }
    }

    const setData = () => {
        const value = props.data.value;
        let dstart = moment(value.datestart, 'DD/MM/YYYY'),
            tdend = value.dateend.length ? 1 : 0,
            dend = value.dateend.length ? moment(value.dateend, 'DD/MM/YYYY') : moment(new Date()),
            days = moment(dend).diff(moment(dstart), 'days'),
            dnow = moment(new Date()),
            daysnow = 0,
            slide = 0;
        if (dnow.isAfter(dend)) {
            daysnow = days;
            slide = 100;
        } else {
            if (dnow.isAfter(dstart)) {
                daysnow = moment(dnow).diff(moment(dstart), 'days');
                slide = (daysnow / days) * 100;
            }
        }
        setState({
            ...state,
            txtStart: value.origin,
            txtEnd: value.target,
            txtDStart: dstart.format('DD MMM YYYY'),
            txtDEnd: tdend == 1 ? dend.format('DD MMM YYYY') : 'Not ava.',
            txtDays: daysnow,
            slide: slide
        });
    };

    const handleCheck = () => {
        props.onCheck(props.data.idshipment)
    };

    useEffect(() => {
        setData();
    }, []);

    return (
        <React.Fragment>
            <Box mt={1}>
                <Card className={props.data.select ? clsx(classes.root, classes.active) : classes.root}>
                    <CardContent className={classes.contentBook}>
                        {props.showAction ? (<FormControlLabel className={classes.form}
                            classes={{
                                label: classes.title,
                            }}

                            value="bottom"
                            control={<Checkbox color="primary" size="small" className={classes.checkbox} onChange={handleCheck} />}
                            label={props.data.title}
                            labelPlacement="bottom"
                        />) : (<FormControlLabel className={classes.form}
                            classes={{
                                label: classes.title,
                            }}

                            value="bottom"
                            control={<></>}
                            label={props.data.title}
                            labelPlacement="bottom"
                        />)}
                        
                        <Box className={classes.logo} mt={1}>
                            <img className={classes.imglogo}
                                src={`/images/line/${props.data.value.image}`}
                                title="logo"
                            />
                        </Box>
                        <Typography variant="caption" className={classes.container}>{props.data.value.container} container(s)</Typography>
                    </CardContent>
                    <CardContent className={classes.contentDetail} onClick={handleClick}>
                        <Box display="flex" justifyContent="space-between" mt={1}>
                            <Typography variant="subtitle2" className={classes.title}>{state.txtStart}</Typography>
                            <Typography variant="subtitle2" className={classes.title}>{state.txtEnd}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mt={1} mb={1}>
                            <Typography variant="caption" className={classes.date}>{state.txtDStart}</Typography>
                            <Typography variant="caption" className={classes.date}>{state.txtDays + ' days'}</Typography>
                            <Typography variant="caption" className={classes.date}>{state.txtDEnd}</Typography>
                        </Box>
                        <DefaultSlider ThumbComponent={ThumbComponent} color="secondary" value={state.slide} marks={[{value:0}, {value:100}]} className={classes.slider} />
                    </CardContent>
                </Card>
            </Box>

        </React.Fragment>
    );

}

export default TrackItem;