import React, { useEffect } from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"

const Map = withScriptjs(withGoogleMap((props) => {

   const exampleMapStyles = [
      {
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#f5f5f5"
            }
         ]
      },
      {
         "elementType": "labels.icon",
         "stylers": [
            {
               "visibility": "off"
            }
         ]
      },
      {
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#616161"
            }
         ]
      },
      {
         "elementType": "labels.text.stroke",
         "stylers": [
            {
               "color": "#f5f5f5"
            }
         ]
      },
      {
         "featureType": "administrative.country",
         "elementType": "geometry.fill",
         "stylers": [
            {
               "visibility": "off"
            }
         ]
      },
      {
         "featureType": "administrative.country",
         "elementType": "geometry.stroke",
         "stylers": [
            {
               "color": "#75cff0"
            }
         ]
      },
      {
         "featureType": "administrative.land_parcel",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "visibility": "off"
            }
         ]
      },
      {
         "featureType": "administrative.locality",
         "stylers": [
            {
               "visibility": "on"
            }
         ]
      },
      {
         "featureType": "administrative.locality",
         "elementType": "geometry.stroke",
         "stylers": [
            {
               "visibility": "off"
            }
         ]
      },
      {
         "featureType": "administrative.province",
         "elementType": "geometry.fill",
         "stylers": [
            {
               "visibility": "off"
            }
         ]
      },
      {
         "featureType": "poi",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#eeeeee"
            }
         ]
      },
      {
         "featureType": "poi",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#757575"
            }
         ]
      },
      {
         "featureType": "poi.park",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#e5e5e5"
            }
         ]
      },
      {
         "featureType": "poi.park",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#9e9e9e"
            }
         ]
      },
      {
         "featureType": "road",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#ffffff"
            }
         ]
      },
      {
         "featureType": "road.arterial",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#757575"
            }
         ]
      },
      {
         "featureType": "road.highway",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#dadada"
            }
         ]
      },
      {
         "featureType": "road.highway",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#616161"
            }
         ]
      },
      {
         "featureType": "road.local",
         "elementType": "labels.text.fill",
         "stylers": [
            {
               "color": "#9e9e9e"
            }
         ]
      },
      {
         "featureType": "transit.line",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#e5e5e5"
            }
         ]
      },
      {
         "featureType": "transit.station",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#eeeeee"
            }
         ]
      },
      {
         "featureType": "water",
         "elementType": "geometry",
         "stylers": [
            {
               "color": "#c9c9c9"
            }
         ]
      },
      {
         "featureType": "water",
         "elementType": "geometry.fill",
         "stylers": [
            {
               "color": "#75cff0"
            },
            {
               "visibility": "on"
            }
         ]
      },
      {
         "featureType": "water",
         "elementType": "geometry.stroke",
         "stylers": [
            {
               "color": "#9e9e9e"
            },
            {
               "visibility": "on"
            }
         ]
      }
   ];

   const icon ={
      url:'/images/icon-ship.webp',
      scaledSize:10
   };

   return (
      <GoogleMap
         defaultZoom={6}
         center={props.valueCenter}
         fullscreenControl={false}
         streetViewControl={false}
         defaultOptions={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            styles: exampleMapStyles
         }}>

         {!props.showRoute ? (
            <></>
         ) : (
            <React.Fragment>
               <Marker position={props.data.marker} />
               <Polyline options={{path:props.data.polyline, 
               strokeColor: '#3f50b5',
                strokeOpacity: 1,
                strokeWeight: 1.5}} />
            </React.Fragment>
         )}
      </GoogleMap>
   );
}
));
// <Polyline path={props.polyline} />
export default Map;