import React, { useState, useEffect } from 'react';
import {
   Tooltip,
   Box,
   Container,
   Button,
   Grid,
   Card,
   CardContent,
   CardHeader,
   Divider,
   TextField,
   IconButton,
   FormControl,
   InputLabel,
   OutlinedInput,
   InputAdornment,
   Backdrop,
   CircularProgress,
   Snackbar,
   Slide,
   makeStyles,
   Select,
   MenuItem,
   rgbToHex,
   FormControlLabel,
   FormGroup,
   Checkbox,
   RadioGroup,
   Radio,
   FormLabel
} from '@material-ui/core';
import Page from '../../components/Page';
import MuiAlert from '@material-ui/lab/Alert';
import Cookies from 'universal-cookie';
import httpClient from '../../services/axios-client';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/DoneOutline';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { APP_BASE } from '../../constants/apiConstants';
import { COMPANY_DEFAULT_CREATE, Profile, USER_DEFAULT_CREATE } from '../../constants/baseConstants';
import { CheckBox } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({


   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
   },
   background: {
      display: 'grid',
      placeContent: 'center',
      minHeight: '100vh',
      backgroundImage: `url(${"../images/principal/new-user-background.jpg"})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '& .MuiCard-root': {
         boxShadow: `6px 8px 20px 2px rgb(0 0 0 / 50%)`,
      }
   },
   background2: {
      display: 'grid',
      placeContent: 'center',
      minHeight: '100%',
      backgroundImage: `url(${"../images/principal/new-user-background.jpg"})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '& .MuiCard-root': {
         boxShadow: `6px 8px 20px 2px rgb(0 0 0 / 50%)`,
      }
   },
   m_auto: {
      margin: 'auto'
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
   border:{
      borderColor: theme.palette.primary.main,
      borderRadius:'5px',
      '& .MuiDivider-root':{
         background: theme.palette.primary.main,
      }
   },
   border1: {
      background:'#215AA9',
      background: `linear-gradient(107deg, rgba(33,90,169,1) 9%, rgba(81,125,183,1) 86%)`,
      color:'#ffffff',
      '& .MuiCardHeader-subheader':{
         color:'#ffffff'
      }
   },
   sub_label: {
      margin: '0px',
      color: '#546e7a',
      padding: '0',
      fontSize: '1rem'
   },
   grid_clients: {
      maxHeight: '250px',
      overflow: 'auto'
   },
   grid_actions: {
      /*maxHeight: '130px',
      overflow: 'auto'*/
   },
   grid_actions_items: {
      '& .MuiIconButton-root': {
         padding: '3px 0px 3px 9px !important'
      }
   }
}));

const cookies = new Cookies();

const Alert = (props) => {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let objVer = {
   login: { text: '', val: false },
   ruc: { text: '', val: true }
};

const UserRegisterView = () => {
   const classes = useStyles();

   const navigate = useNavigate();

   const location = useLocation();
   
   const isOriginLogin = location?.state?.router == 'login';//useState(false);

   const objSession = cookies.get(APP_BASE+'.objSession');

   const [state, setState] = useState({
      txtUser: '',
      txtPass: '',
      txtEmail: '',
      txtCompany: '',
      txtRUC: '',
      cboIdProfile: Profile.Client,
      ckCreateEmbarque: false,
      ckEditEmbarque: false,
      ckDeleteEmbarque: false,
      ckCreateClient: false,
      ckEditClient: false,
      ckDeleteClient: false,
      ckPanelTracking: false,
      ckUpdateTracking: false,
      allUser: '1',
      showPassword: false
   });

   const [isValidUser, setValidUser] = useState(false);
   const [isValidPass, setValidPass] = useState(false);
   const [isValidEmail, setValidEmail] = useState(false);
   const [isValidCompany, setValidCompany] = useState(false);
   const [isValidRUC, setValidRUC] = useState(false);

   const [isVisibleProfile, setVisibleProfile] = useState(true);
   const [isSelectOperations, setSelectOperations] = useState(false);

   const [isValidIdProfile, setValidIdProfile] = useState(false);

   const [getDataClient, setDataClient] = useState([]);

   const [isLoad, setLoad] = useState(false);

   const [isSuccess, setSuccess] = useState(false);
   const [lblSuccess, setTextSuccess] = useState('');

   const [isError, setError] = useState(false);
   const [lblError, setTextError] = useState('');

   const [dataIdProfile, setDataIdProfile] = useState([]);

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setError(false);
      setSuccess(false);
   };

   const handleSelect = (e) => {
      if (e.target.type == 'checkbox') {
         if (e.target.name.substr(0, 7) == 'client_') {
            let clients = getDataClient;
            let clie = clients.filter(x => x.name == e.target.name)[0];
            clie.checked = e.target.checked;
            setDataClient(clients);
            setState({ ...state, [e.target.name]: e.target.checked });
         } else {
            setState({ ...state, [e.target.name]: e.target.checked });
         }
      } else {
         if (e.target.name == 'cboIdProfile') {
            if (e.target.value == Profile.Operations) {
               setSelectOperations(true);
            } else {
               state.ckCreateEmbarque = false;
               state.ckEditEmbarque = false;
               state.ckDeleteEmbarque = false;
               state.ckCreateClient = false;
               state.ckEditClient = false;
               state.ckDeleteClient = false;
               state.ckPanelTracking = false;
               state.ckUpdateTracking = false;
               state.allUser = '0';
               setSelectOperations(false);
            } 
         } 
         setState({ ...state, [e.target.name]: e.target.value });
      }
   };

   const handleChange = (e) => {
      if (e.target.id == 'txtUser') {

         setState({
            ...state,
            [e.target.id]: e.target.value.replace(/\s/g, '')
         });

         setValidUser(false);
         if (e.target.value == objVer.login.text) objVer.login.val = true;
         else objVer.login.val = false;
      } else {
         setState({
            ...state,
            [e.target.id]: e.target.value
         });
         if (e.target.id == 'txtPass') setValidPass(false);
         if (e.target.id == 'txtEmail') setValidEmail(false);
         if (e.target.id == 'txtCompany') setValidCompany(false);
         if (e.target.id == 'txtRUC') setValidRUC(false);
      }

   };

   const handleGenerate = () => {
      let randomstring = Math.random().toString(36).slice(-8);
      setValidPass(false);
      setState({ ...state, txtPass: randomstring });
   }

   const handleClickShowPassword = () => {
      setState({ ...state, showPassword: !state.showPassword });
   };

   const handleValidationUser = () => {
      const user = state.txtUser;
      if (user.length > 5) {
         if (user != objVer.login.text) {
            let idcompany = isOriginLogin ? COMPANY_DEFAULT_CREATE : objSession.idcompany,
               idclient = 0;
            setLoad(true);
            
            //httpClient.post('Client/VerLogin', { idcompany, idclient, user }, { Authorization: 'Bearer ' + cookies.get(APP_BASE+'.token') })
            httpClient.post('Login/VerLogin', { idcompany, idclient, user })
               .then(response => {
                  return response.data;
               })
               .then(response => {
                  if (response.id == 0) {
                     setValidUser(false);
                     setTextSuccess('Valid user.');
                     setSuccess(true);
                     objVer.login.text = user;
                     objVer.login.val = true;
                  } else {
                     setValidUser(true);
                     setTextError('This user is already being used.');
                     setError(true);
                     objVer.login.text = user;
                     objVer.login.val = false;
                  }
                  setLoad(false);
               })
               .catch(error => {
                  setTextError('Server error.');
                  setError(true);
                  setLoad(false);
               });
         }
      } else {
         setValidUser(true);
         setTextError('Enter a user with a length of 6 characters.');
         setError(true);
      }
   };

   const getData = async () => {
      if (objSession != null) {
         await httpClient.post('Login/ListProfile', { })
         .then(response => {
            return response.data;
         })
         .then(response => {
            if (response.length) {
               setDataIdProfile(response);
            }
         })
         .catch(error => {
         });

         await httpClient.post('Client/List', { idcompany: objSession.idcompany, order_by: 'user', asc_desc: 'asc' }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
         .then(response => {
            return response.data.filter(x => x.idprofile == Profile.Client && x.state == 'Active');
         })
         .then(response => {
            if (response.length) {
               let dataU = []
               for (let i = 0; i < response.length; i++) {
                  let value = response[i];
                  dataU.push({
                     idclient: value.idclient,
                     user: value.user,
                     pass: value.pass,
                     email: value.email,
                     company: value.company,
                     state: value.state,
                     ruc: value.ruc,
                     name: 'client_' + value.idclient,
                     checked: false
                  });
               }
               setDataClient(dataU);
            }
         })
         .catch(error => {
         });

      }
   };

   useEffect(() => {
      setVisibleProfile(!isOriginLogin);
      getData();
      objVer = {
         login: { text: '', val: false },
         ruc: { text: '', val: true }
      };
   }, []);

   const Back = () => {
      if (isOriginLogin) {
         navigate('/login');
      }
   }

   const InsClient = () => {
      let origin = isOriginLogin ? 'P' : 'A'; 

      let idcompany = isOriginLogin ? COMPANY_DEFAULT_CREATE : objSession.idcompany,
         iduser = isOriginLogin ? USER_DEFAULT_CREATE : objSession.iduser,
         user = state.txtUser,
         pass = state.txtPass,
         email = state.txtEmail.trim(),
         company = isSelectOperations ? '' : state.txtCompany.trim(),
         ruc = state.txtRUC,
         idprofile = state.cboIdProfile,
         flgcreateshipment = state.ckCreateEmbarque,
         flgeditshipment = state.ckEditEmbarque,
         flgdeleteshipment = state.ckDeleteEmbarque,
         flgcreateclient = state.ckCreateClient,
         flgeditclient = state.ckEditClient,
         flgdeleteclient = state.ckDeleteClient,
         flgpaneltracking = state.ckPanelTracking,
         flgupdatetracking = state.ckUpdateTracking,
         allUser = state.allUser == '1';
      
      let user_view = '';
      if (!allUser) {
         getDataClient.filter(x => x.checked).forEach(e => {
            user_view += ',' + e.idclient;
         });
      }
      if (user_view.length > 0) {
         user_view = user_view.substr(1);
      }
      

      if (!objVer.login.val) {
         setValidUser(true);
         setTextError('Please validate the user by clicking the button next to it.');
         setError(true);
         return;
      }
      if (pass.length < 6) {
         setValidPass(true);
         setTextError('Please enter a valid password, or generate one with the button next to it..');
         setError(true);
         return;
      }
      if (email.length == 0) {
         setValidEmail(true);
         setTextError('Please write an email.');
         setError(true);
         return;
      } else {
         let regexText = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         if (!regexText.test(email)) {
            setValidEmail(true);
            setTextError('Please write an email.');
            setError(true);
            return;
         }
      }
      if (!isSelectOperations && company.length == 0) {
         setValidCompany(true);
         setTextError('Please enter a company.');
         setError(true);
         return;
      }
      
      if (origin == 'A' && idprofile == 0) {
         setValidIdProfile(true);
         setTextError('Please enter a profile.');
         setError(true);
         return;
      }
      
      setLoad(true);
      httpClient.post('Login/Ins', { idcompany, user, pass, email, company, ruc, idprofile, flgcreateshipment, 
         flgeditshipment, flgdeleteshipment, flgcreateclient, flgeditclient, flgdeleteclient, flgpaneltracking, flgupdatetracking, allUser, user_view, iduser, origin })
         .then(response => {
            return response.data;
         })
         .then(response => {
            if (response.done == 1) {
               setTextSuccess('Registered user.');
               setSuccess(true);
               navigate('/app/user');
            } else {
               setTextError('Unexpected error, check with your system administrator.');
               setError(true);
            }
            setLoad(false);
         })
         .catch(error => {
            setTextError('Server error.');
            setError(true);
            setLoad(false);
         });
   };

   return (
      <Page className={isOriginLogin ? classes.background : classes.background2} title="New User" id="userregister-pnl">
         <Container maxWidth="sm">
            <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="error">
                  {lblError}
               </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="success">
                  {lblSuccess}
               </Alert>
            </Snackbar>
            <Box mt={3}>
               <form>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Card className={classes.border}>
                     <CardHeader className={classes.border1} title="New User" subheader="Register correctly" />
                     <Divider />
                     <CardContent>
                        <Grid container>
                           <Grid item xs={10}>
                              <TextField fullWidth
                                 label="User"
                                 margin="normal"
                                 id="txtUser"
                                 onChange={handleChange}
                                 type="text"
                                 value={state.txtUser}
                                 variant="outlined"
                                 error={isValidUser} />
                           </Grid>
                           <Grid item xs={2} m="auto" className={classes.m_auto} align="center">
                              <Tooltip title="Validate">
                                 <IconButton color="primary" onClick={handleValidationUser}>
                                    <CheckIcon />
                                 </IconButton>
                              </Tooltip>
                           </Grid>
                           <Grid item xs={10}>
                              <FormControl variant="outlined" margin="normal" fullWidth error={isValidPass}>
                                 <InputLabel htmlFor="txtPass">Password</InputLabel>
                                 <OutlinedInput
                                    id="txtPass"
                                    type={state.showPassword ? 'text' : 'password'}
                                    value={state.txtPass}
                                    onChange={handleChange}
                                    endAdornment={
                                       <InputAdornment position="end">
                                          <IconButton
                                             aria-label="toggle password visibility"
                                             onClick={handleClickShowPassword}
                                             edge="end"
                                          >
                                             {state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                          </IconButton>
                                       </InputAdornment>
                                    }
                                    labelWidth={90}
                                 />
                              </FormControl>
                           </Grid>
                           <Grid item xs={2} m="auto" className={classes.m_auto} align="center">
                              <Tooltip title="Generate">
                                 <IconButton color="primary" onClick={handleGenerate}>
                                    <LockIcon />
                                 </IconButton>
                              </Tooltip>
                           </Grid>
                           <Grid item xs={10}>
                              <TextField
                                 fullWidth
                                 label="Email"
                                 margin="normal"
                                 id="txtEmail"
                                 onChange={handleChange}
                                 type="email"
                                 value={state.txtEmail}
                                 variant="outlined"
                                 error={isValidEmail} />
                           </Grid>
                           {isSelectOperations ? (<></>) : (<Grid item xs={10}>
                              <TextField
                                 fullWidth
                                 label="Company"
                                 margin="normal"
                                 id="txtCompany"
                                 onChange={handleChange}
                                 type="text"
                                 value={state.txtCompany}
                                 variant="outlined"
                                 error={isValidCompany} />
                           </Grid>)}
                           {isVisibleProfile ? (<Grid item xs={10}>
                              <FormControl fullWidth variant="outlined" margin="normal">
                                 <InputLabel id="cboIdProfile">User Type</InputLabel>
                                 <Select
                                    labelId="cboIdProfile"
                                    name="cboIdProfile"
                                    value={state.cboIdProfile}
                                    onChange={handleSelect}
                                    label="User Type"
                                    error={isValidIdProfile}>
                                    {dataIdProfile.map((obj) => (
                                       <MenuItem key={obj.idprofile} value={obj.idprofile} >
                                          {obj.profilename}
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </FormControl>
                           </Grid>) : (<></>)}
                           {isSelectOperations ? (<Grid item xs={10}>
                              <Card className={classes.border}> 
                                 <CardContent>
                                    <Grid container spacing={3}>
                                       <Grid item xs={12} >
                                          <FormControlLabel control={<></>}  label="Actions:" className={classes.sub_label} />
                                          <Grid item sm={12}>
                                             <Grid container className={classes.grid_actions}>
                                                <Grid item xs={12}>
                                                   <FormControlLabel className={classes.grid_actions_items}
                                                      control={<Checkbox checked={state.ckCreateEmbarque} name="ckCreateEmbarque" onChange={handleSelect} />}
                                                      label="Create shipments" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                   <FormControlLabel className={classes.grid_actions_items}
                                                      control={<Checkbox checked={state.ckEditEmbarque} name="ckEditEmbarque" onChange={handleSelect} />}
                                                      label="Edit shipments" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                   <FormControlLabel className={classes.grid_actions_items}
                                                      control={<Checkbox checked={state.ckDeleteEmbarque} name="ckDeleteEmbarque" onChange={handleSelect} />}
                                                      label="Delete shipments" />
                                                </Grid>
                                                <Grid item xs={12}> 
                                                   <FormControlLabel className={classes.grid_actions_items}
                                                      control={<Checkbox checked={state.ckCreateClient} name="ckCreateClient" onChange={handleSelect} />}
                                                      label="User creation" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                   <FormControlLabel className={classes.grid_actions_items}
                                                      control={<Checkbox checked={state.ckDeleteClient} name="ckDeleteClient" onChange={handleSelect} />}
                                                      label="Delete users" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                   <FormControlLabel className={classes.grid_actions_items}
                                                      control={<Checkbox checked={state.ckPanelTracking} name="ckPanelTracking" onChange={handleSelect} />}
                                                      label="See tracking panel" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                   <FormControlLabel className={classes.grid_actions_items}
                                                      control={<Checkbox checked={state.ckUpdateTracking} name="ckUpdateTracking" onChange={handleSelect} />}
                                                      label="Update tracking folders" />
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                       <Grid item xs={12} sm={12}>
                                          <FormControl>
                                             <FormLabel id="demo-radio-buttons-group-label">Visible Clients</FormLabel>
                                             <RadioGroup row
                                                defaultValue="0"
                                                name="allUser"
                                                value={state.allUser}
                                                onChange={handleSelect}>
                                                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="0" control={<Radio />} label="No" />
                                             </RadioGroup>
                                          </FormControl>
                                       </Grid>
                                       {state.allUser == "0" ? (<Grid item xs={12}>
                                          <FormControlLabel control={<></>}  label="Select clients:" className={classes.sub_label} />
                                          <Grid container className={classes.grid_clients}>
                                             {getDataClient.map((obj) => (
                                                <Grid item xs={12}>
                                                   <FormControlLabel
                                                      control={<Checkbox checked={obj.checked} name={obj.name} onChange={handleSelect} />}
                                                      label={obj.user} >
                                                   </FormControlLabel>
                                                </Grid>
                                                
                                             ))}
                                          </Grid>
                                       </Grid>) : (<></>)}
                                    </Grid>
                                 </CardContent>
                              </Card>
                           </Grid>) : (<></>)}

                           

                           {/*<Grid item xs={10}>
                              <TextField
                                 fullWidth
                                 label="RUC"
                                 margin="normal"
                                 id="txtRUC"
                                 onChange={handleChange}
                                 type="number"
                                 value={state.txtRUC}
                                 variant="outlined"
                                 error={isValidRUC} />
                                 </Grid>
                           <Grid item xs={2} m="auto" className={classes.m_auto} align="center">
                              <IconButton color="primary" onClick={handleValidationDoc}>
                                 <CheckIcon />
                              </IconButton>
                           </Grid>*/}
                        </Grid>
                     </CardContent>
                     <Divider />
                     {
                        isOriginLogin ? (
                           <Box display="flex" justifyContent="space-between" p={2}>
                              <Button color="#737373" variant="contained" onClick={Back}> Back </Button>
                              <Button color="primary" variant="contained" onClick={InsClient}> Create </Button>
                           </Box>
                        ) : (
                           <Box display="flex" justifyContent="flex-end" p={2}>
                              <Button boxShadow="" color="primary" variant="contained" onClick={InsClient}> Create </Button>
                           </Box>
                        )
                     }
                  </Card>
                  </Slide>
               </form>
            </Box>
            <Backdrop className={classes.backdrop} open={isLoad}>
               <CircularProgress color="inherit" />
            </Backdrop>
         </Container>
      </Page>
   );
};

export default UserRegisterView;