import React, { useState, useEffect } from 'react';
import {
   Link, Slide, Backdrop, CircularProgress, Paper, Tabs, Tab, Box, Container, IconButton, Button, Card, CardContent, TextField, InputAdornment, makeStyles,
   Typography, Tooltip
} from '@material-ui/core';
import Page from '../../components/Page';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import httpClient from '../../services/axios-client';
import Cookies from 'universal-cookie';
import MUIDataTable from "mui-datatables";
import { API_BASE_URL, APP_BASE } from '../../constants/apiConstants';

const options = {
   filter: true,
   filterType: "dropdown",
   selectableRows: false,
   /*textLabels: {
      body: {
         noMatch: "Lo sentimos, no se encontraron registros coincidentes",
         toolTip: "Clasificar",
         columnHeaderTooltip: column => `Orderna por ${column.label}`
      },
      pagination: {
         next: "Siguiente Página",
         previous: "Anterior Página",
         rowsPerPage: "Filas por página:",
         displayRows: "de",
      },
      toolbar: {
         search: "Buscar",
         downloadCsv: "Descargar CSV",
         print: "Imprimir",
         viewColumns: "Ver Columnas",
         filterTable: "Filtrar Tabla",
      },
      filter: {
         all: "Todo",
         title: "FILTROS",
         reset: "RESETEAR",
      },
      viewColumns: {
         title: "Mostar Columnas",
         titleAria: "Mostrar/Ocultar Tabla de Columnas",
      },
      selectedRows: {
         text: "Fila(s) Selecccionadas",
         delete: "Eliminar",
         deleteAria: "Eliminal Filas Seleccionadas",
      },
   }*/
};

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      '& .MuiToolbar-root[role=toolbar]': {
         borderBottom: '1px solid',
         borderTop: '1px solid',
         borderColor: theme.palette.primary.main,
      },
      '& .MuiTableCell-body, .MuiTableCell-head': {
         borderColor: theme.palette.primary.main,
      },
      '& .MuiTab-textColorPrimary.Mui-selected': {
         color: '#ffffff',
         background: theme.palette.primary.main
      },
      '& .MuiToolbar-root[role=toolbar]': {
         borderBottom: '1px solid',
         borderTop: '1px solid',
         borderColor: theme.palette.primary.main,
      },
      '& .MuiTableCell-body, .MuiTableCell-head': {
         borderColor: theme.palette.primary.main,
      },
      '& .MuiTab-textColorPrimary.Mui-selected': {
         color: '#ffffff',
         background: theme.palette.primary.main
      },
      '& .MuiTableCell-root': {
         textAlign: 'left',
         minWidth: '150px'
      },
      '& .MuiTableCell-head span': {
         justifyContent: 'left'
      },
   },
   container: {
      maxHeight: 440,
   },
   danger: {
      color: '#e91e63'
   },
   avatar: {
      height: 80,
      width: 80,
   },
   m_auto: {
      margin: 'auto'
   },
   b_danger: {
      'margin-left': '2px',
      background: '#e91e63'
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
   border: {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '5px',
   },
   pointer: {
      cursor: 'pointer',
      color: theme.palette.secondary.main
   }
}));

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

let objData = [];

const ShipUserView = () => {
   const classes = useStyles();

   const objSession = cookies.get(APP_BASE + '.objSession');

   const navigate = useNavigate();

   const [tabs, setTabs] = React.useState(0);

   const [dataAll, setDataAll] = useState([]);

   const [isLoad, setLoad] = useState(false);

   const columns = [
      { name: 'ID' },
      {
         name: 'Ref Client',
         options: {
            setCellProps: () => ({ style: { minWidth: '150px', maxWidth: '150px' } })
         }
      },
      {
         name: 'Number', options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, meta, update) => {
               let idship = desconvertId(meta.rowData[0]),
                  booking = '';
               for (let i = 0; i < objData.length; i++) {
                  let val = objData[i];
                  if (val.idshipment == idship) booking = val.booking;
               }
               return (
                  <Link className={classes.pointer} onClick={() => { navigate('/app/trackingShip', { state: { idship } }); }}>{booking}
                  </Link>
               );
            }
         }
      },
      { name: 'Containers' },
      {
         name: 'Port Origin',
         options: {
            setCellProps: () => ({ style: { minWidth: '180px', maxWidth: '180px' } })
         }
      },
      { name: 'Days Origin' },
      {
         name: 'Port Dest.',
         options: {
            setCellProps: () => ({ style: { minWidth: '180px', maxWidth: '180px' } })
         }
      },
      { name: 'Days Dest.' },
      { name: 'Sailing Date' },
      { name: 'Arrival Date' },
      {
         name: 'Invoice', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '100px', maxWidth: '500px' } }),
            customBodyRender: (value, meta, update) => {
               let idship = desconvertId(meta.rowData[0]),
                  objReceipt = null;
               for (let i = 0; i < objData.length; i++) {
                  let val = objData[i];
                  if (val.idshipment == idship) objReceipt = val.jsReceipt;
               }
               return (
                  <React.Fragment>
                     {objReceipt != null ? (<Tooltip title={objReceipt.name}><Link className={classes.pointer} onClick={() => { handleLink(objReceipt) }}>{objReceipt.name.length > 6 ? objReceipt.name.substring(0, 6) + '...' : objReceipt.name}
                     </Link></Tooltip>) : (<></>)}

                  </React.Fragment>
               );
            }
         }
      },
      {
         name: 'Bill of LN', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '100px', maxWidth: '100px' } }),
            customBodyRender: (value, meta, update) => {
               let idship = desconvertId(meta.rowData[0]),
                  objBill = null;
               for (let i = 0; i < objData.length; i++) {
                  let val = objData[i];
                  if (val.idshipment == idship) objBill = val.jsBill;
               }
               return (
                  <React.Fragment>
                     {objBill != null ? (<Tooltip title={objBill.name}><Link className={classes.pointer} onClick={() => { handleLink(objBill) }}>{objBill.name.length > 6 ? objBill.name.substring(0, 6) + '...' : objBill.name}
                     </Link></Tooltip>) : (<></>)}

                  </React.Fragment>
               );
            }
         }
      },
      {
         name: 'Other Docs', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '100px', maxWidth: '100px' } }),
            customBodyRender: (value, meta, update) => {
               let idship = desconvertId(meta.rowData[0]),
                  objOther = null;
               for (let i = 0; i < objData.length; i++) {
                  let val = objData[i];
                  if (val.idshipment == idship) objOther = val.jsOther;
               }
               return (
                  <React.Fragment>
                     {objOther != null ? (<Tooltip title={objOther.name}><Link className={classes.pointer} onClick={() => { handleLink(objOther) }}>{objOther.name.length > 6 ? objOther.name.substring(0, 6) + '...' : objOther.name}
                     </Link></Tooltip>) : (<></>)}
                  </React.Fragment>
               );
            }
         }
      },
      { name: 'PIC Brokmar', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '210px', maxWidth: '210px' } }),
               customBodyRender: (value, meta, update) => {
                  return (
                     <React.Fragment>
                        {value?.length > 15 ? (
                           <Tooltip title={value}><Link>{value.substr(0, 15) + '...'}</Link></Tooltip>
                        ) : (
                           <React.Fragment><Link>{value}</Link></React.Fragment>
                        )}
                     </React.Fragment>
                  );
               }
         } 
      },
      { name: 'PIC Client', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '210px', maxWidth: '210px' } }),
               customBodyRender: (value, meta, update) => {
                  return (
                     <React.Fragment>
                        {value?.length > 15 ? (
                           <Tooltip title={value}><Link>{value.substr(0, 15) + '...'}</Link></Tooltip>
                        ) : (
                           <React.Fragment><Link>{value}</Link></React.Fragment>
                        )}
                     </React.Fragment>
                  );
               }
         }  
      },
      { name: 'DG' },
      {
         name: 'T. Container', options: {
            filter: false,
            sort: false,
            setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '200px' } }),
            customBodyRender: (value, meta, update) => {
               return (
                  <React.Fragment>
                     {value.length > 15 ? (
                        <Tooltip title={value}><Link>{value.substr(0, 15) + '...'}</Link></Tooltip>
                     ) : (
                        <React.Fragment> {value}</React.Fragment>
                     )}
                  </React.Fragment>
               );
            }
         }
      },
      { name: 'Code HS' },
      {
         name: 'Desc. HS', options: {
            filter: false,
            sort: false,
            customBodyRender: (value, meta, update) => {
               return (
                  <React.Fragment>
                     {value.length > 50 ? (
                        <Tooltip title={value}><Link>{value.substr(0, 50) + '...'}</Link></Tooltip>
                     ) : (
                        <React.Fragment> {value}</React.Fragment>
                     )}
                  </React.Fragment>
               );
            }
         }
      },
      {
         name: 'Transhipment',
         options: {
            setCellProps: () => ({ style: { minWidth: '200px', maxWidth: '200px' } })
         }
      },
   ];

   const handleLink = (objFile) => {
      const link = document.createElement('a');
      link.href = `${API_BASE_URL}/Login/Download?key=${objFile.key}&folder=${objFile.folder}&name=${objFile.name}`;
      link.setAttribute(
         'download',
         objFile.name
      );
      document.body.appendChild(link);
      link.click();
   }

   const handleTabs = (e, newValue) => {
      setTabs(newValue);
      if (objData.length) {

         let dAll = [];
         for (let i = 0; i < objData.length; i++) {
            let value = objData[i],
               transfer = 'Direct';
            if (value.jsTransfer.length) {
               /*transfer = '';
               for (let i = 0; i < value.jsTransfer.length; i++) {
                  let valueT = value.jsTransfer[i];
                  transfer += `${valueT.origin} - ${valueT.date}\r\n`
               }*/
               transfer = value.jsTransfer;
            }
            if (newValue == 0) {
               dAll.push([
                  convertId(value.idshipment),
                  value.refclient,
                  value.container,
                  value.origin,
                  pad(value.dorigin),
                  value.target,
                  pad(value.dtarget),
                  value.datestart,
                  value.dateend,
                  '',
                  '',
                  '',
                  value.picbrokmar,
                  value.picclient,
                  value.dg == 1 ? 'Yes' : 'No',
                  value.typeContainer || '',
                  value.codeHS || '',
                  value.descHS || '',
                  transfer
               ]);
            } else {
               if (newValue + 1 == value.bostate) {
                  dAll.push([
                     convertId(value.idshipment),
                     value.refclient,
                     value.container,
                     value.origin,
                     pad(value.dorigin),
                     value.target,
                     pad(value.dtarget),
                     value.datestart,
                     value.dateend,
                     '',
                     '',
                     '',
                     value.picbrokmar,
                     value.picclient,
                     value.dg == 1 ? 'Yes' : 'No',
                     value.typeContainer || '',
                     value.codeHS || '',
                     value.descHS || '',
                     transfer
                  ]);
               }
            }
         }
         setDataAll(dAll);
      }

   };

   const getData = async () => {
      if (objSession != null) {
         await httpClient.post('Ship/ListUser', { idcompany: objSession.idcompany, idclient: objSession.iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
               return response.data;
            })
            .then(response => {

               if (response.length) {

                  let dAll = [];
                  for (let i = 0; i < response.length; i++) {
                     let value = response[i];
                     value.jsReceipt = value.jsReceipt.length ? JSON.parse(value.jsReceipt) : null;
                     value.jsBill = value.jsBill.length ? JSON.parse(value.jsBill) : null;
                     value.jsOther = value.jsOther.length ? JSON.parse(value.jsOther) : null;
                     //value.jsTransfer = value.jsTransfer.length ? JSON.parse(value.jsTransfer) : [];
                     let transfer = 'Direct';
                     if (value.jsTransfer.length) {
                        /*transfer = '';
                        for (let i = 0; i < value.jsTransfer.length; i++) {
                           let valueT = value.jsTransfer[i];
                           transfer += `${valueT.origin} - ${valueT.date}\r\n`
                        }*/
                        transfer = value.jsTransfer;
                     }
                     
                     dAll.push([
                        convertId(value.idshipment),
                        value.refclient,
                        value.container,
                        value.origin,
                        pad(value.dorigin),
                        value.target,
                        pad(value.dtarget),
                        value.datestart,
                        value.dateend,
                        '',
                        '',
                        '',
                        value.userpicbrokmar,
                        value.picclient,
                        value.dg == 1 ? 'Yes' : 'No',
                        value.typeContainer || '',
                        value.codeHS || '',
                        value.descHS || '',
                        transfer
                     ]);

                  }
                  objData = response;
                  setDataAll(dAll);
               }
            })
            .catch(error => {
            });
      }
   };

   const convertId = (id) => {
      let number = parseInt(id) + 1000,
         letter = 'BROK0';
      return letter + number;
   };

   const desconvertId = (ids) => {
      let id = ids.slice(4),
         n = parseInt(id) - 1000;
      return n;
   }

   const pad = (n) => {
      return (n < 10) ? '0' + n.toString() : n.toString();
   };

   useEffect(() => {
      getData();
   }, []);

   return (
      <Page className={classes.root} title="Shipment List" id="shipuser-pnl">
         <Container maxWidth={false}>
            <Box display="flex" justifyContent="space-between">
               <Typography color="textPrimary" variant="h2">Shipments</Typography>
            </Box>
            <Box mt={3}>
               <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Card className={classes.border}>
                     <Paper square>
                        <Tabs value={tabs} onChange={handleTabs} indicatorColor="primary" textColor="primary" aria-label="simple tabs example">
                           <Tab label="All" {...a11yProps(0)} />
                           {/*<Tab label="En Espera" {...a11yProps(1)} />*/}
                           <Tab label="Active" {...a11yProps(2)} />
                           <Tab label="Finished" {...a11yProps(3)} />
                        </Tabs>
                        <MUIDataTable
                           data={dataAll}
                           columns={columns}
                           options={options} />
                     </Paper>
                  </Card>
               </Slide>
            </Box>
            <Backdrop className={classes.backdrop} open={isLoad}>
               <CircularProgress color="inherit" />
            </Backdrop>
         </Container>
      </Page>
   );
};

export default ShipUserView;