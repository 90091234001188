import React, { useState } from "react";
import { Link, Button, CssBaseline, InputLabel, OutlinedInput, IconButton, TextField, FormControlLabel, Checkbox, InputAdornment, FormControl, Box, Typography, Container, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import httpClient from '../../services/axios-client';
import Cookies from 'universal-cookie';
import Copyright from '../../components/Copyright'
import Page from '../../components/Page';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { APP_BASE } from '../../constants/apiConstants';


const cookies = new Cookies();

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

    background: {
        display: 'grid',
        placeContent: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${"../images/principal/fondo-login.jpg"})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    border: {
        textAlign: 'center'
    },
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        border: '1px solid white',
        borderRadius: '25px',
        padding: '25px',
        textAlign: 'center',
        background: '#223345'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        width: '50%'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    imglogo: {
        width: '330px',
        marginBottom: '30px'
    },
    white: {
        color: 'white'
    },
    inline: {
        display: 'inline',
    },
    inliner: {
        display: 'inline',
        borderRight: '2px solid'
    },
    linkright: {
        marginRight: '60px'
    },
    linkleft: {
        marginLeft: '60px'
    }
}));

const RecoverView = () => {

    const classes = useStyles();

    const [state, setState] = useState({
        txtEmail: ''
    });

    const [isSend, setSend] = useState(false);

    const [isLoad, setLoad] = useState(false);
    const [isValid, setValid] = useState(false);

    const [isSuccess, setSuccess] = useState(false);
    const [lblSuccess, setTextSuccess] = useState('');

    const [isError, setError] = useState(false);
    const [lblError, setTextError] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError(false);
        setSuccess(false);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.id]: value
        });
        if (e.target.id == 'txtUser') setValid(false);
    };

    const Recover = (e) => {
        e.preventDefault();
        const usermail = state.txtEmail;
        if (usermail.length > 5) {
            let type = 'user',
                regexText = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (regexText.test(usermail)) {
                type = 'email';
            }
            setLoad(true);
            httpClient.post('Login/RecoverCreate', { type, usermail })
                .then(response => {
                    return response.data;
                })
                .then(response => {
                    if (response.success == 1) {
                        setSend(true);
                        setTextSuccess('Email Sent.');
                        setSuccess(true);
                    } else {
                        setTextError('The email or user entered does not exist..');
                        setError(true);
                    }
                    setLoad(false);
                })
                .catch(error => {
                    setLoad(false);
                    setTextError('Server error.');
                    setError(true);
                });
        } else {
            setTextError('Fill in the fields correctly.');
            setError(true);
            if (usermail.length < 6) setValid(true);
        }
    };

    return (
        <Page title="Recover" className={classes.background} id="recover-pnl">
            <Container component="main" maxWidth="xs" className={classes.border}>
                <CssBaseline />
                <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {lblError}
                    </Alert>
                </Snackbar>
                <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        {lblSuccess}
                    </Alert>
                </Snackbar>
                <img className={classes.imglogo}
                    src={`/images/principal/logo-white-brookmar.svg`}
                    title="logo"
                />
                <div className={classes.paper}>
                    <Typography variant="h4" className={classes.white}> Recover password</Typography>
                    {!isSend ? (<Box className={classes.form} noValidate mt={4}>

                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            type="text"
                            id="txtEmail"
                            label="Email or User"
                            color="secondary"
                            autoFocus
                            value={state.txtEmail}
                            onChange={handleChange}
                            error={isValid} />
                        <Button
                            type="submit"
                            variant="contained"
                            
                            className={classes.submit}
                            onClick={Recover}>
                            Send Request
                        </Button>
                    </Box>) : (<Box mt={4}>
                        <Typography variant="h5" className={classes.white}>
                            The link to recover the password was sent to your email, if you cannot find it in your tray, look in your spam.
                        </Typography>
                    </Box>)}
                </div>
                <Box mt={8}>
                    <Typography variant="body2" align="center" className={classes.white}>
                        {'Copyright © '}
                        <Link color="inherit" href="https://brokmarperu.com/" target="_blank">BROKMAR</Link>{' ' + new Date().getFullYear() + '.'}
                    </Typography>
                </Box>
                <Backdrop className={classes.backdrop} open={isLoad}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Page>
    );
}

export default RecoverView;