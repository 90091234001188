import React, { useState, useEffect } from 'react';
import {
   Input,
   Hidden,
   Modal,
   Link,
   Avatar,
   Typography,
   Tooltip,
   Box,
   Container,
   Button,
   Grid,
   Card,
   CardContent,
   CardHeader,
   Divider,
   TextField,
   IconButton,
   FormControl,
   InputLabel,
   OutlinedInput,
   InputAdornment,
   Backdrop,
   CircularProgress,
   Snackbar,
   Select,
   MenuItem,
   Slide,
   Checkbox,
   FormGroup,
   FormControlLabel,
   List,
   ListItem,
   ListItemText,
   makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Cookies from 'universal-cookie';
import httpClient from '../../services/axios-client';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/DoneOutline';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import { API_BASE_URL, APP_BASE } from '../../constants/apiConstants';
import validator from 'validator'
import { Profile } from '../../constants/baseConstants';

const options = {
   filter: false,
   download: false,
   print: false,
   selectableRows: false,
   viewColumns: false
};

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
   },
   m_auto: {
      margin: 'auto'
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
   avatar: {
      height: 80,
      width: 80,
   },
   hide: {
      display: 'none',
   },
   m_upload: {
      'margin': '20px 0px'
   },
   border: {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '5px',
      '& .MuiDivider-root': {
         background: theme.palette.primary.main,
      }
   },
   paper: {
      position: 'absolute',
      height: '75%',
      width: 800,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      overflow: 'auto',
      //padding: theme.spacing(2, 4, 3),
      top: `10%`,
      left: `30%`
   },
   papermobile: {
      position: 'absolute',
      width: '100%',
      height: '75%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      overflow: 'auto',
      //padding: theme.spacing(2, 4, 3),
      top: `10%`,
   },
   dinline: {
      display: 'inline',
      marginRight: '5px'
   },
   pnlChk: {
      borderRadius: 4,
      border: '1px solid #bdbdbd',
      paddingLeft: 10
   }
}));

const cookies = new Cookies();

const Alert = (props) => {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let objVer = { booking: { type: 1, text: '', val: false } };

const UserRegisterView = () => {
   const classes = useStyles();

   const navigate = useNavigate();

   const location = useLocation();

   const objSession = cookies.get(APP_BASE + '.objSession');
   const objClient = location.state != null ? location.state.objClient : null;
   const opt = location.state != null ? location.state.opt : null;
   const objShip = location.state != null ? location.state.objShip : null;

   const [state, setState] = useState({
      cboState: 2,
      cboType: 1,
      cboContainer: 1,
      txtRefClient: '',
      txtRefCompany: '',
      txtBooking: '',
      txtContainer: '',
      txtOrigin: '',
      txtDOrigin: '',
      txtTarget: '',
      txtDTarget: '',
      txtDateStart: '',
      txtDateEnd: '',
      cboPICBrokmar: '',
      txtPICClient: '',
      //txtBuy: '',
      //txtSale: '',
      cboDG: 1,
      txtCode: '',
      txtDesc: '',
      boapi: 0,
      jsApi: '{}',
      //Modal
      cboSection: 0,
      txtCode1: '',
      txtCode2: '',
      txtCode3: '',
      txtDescHS: '',
      chkTransfer: false,
      cboPICBrokmars: []
   });

   const [data, setData] = useState([]);
   const [transfer, setTransfer] = useState([]);
   const [openModal, setOpenModal] = useState(false);

   const [isValidBooking, setValidBooking] = useState(false);

   const [isLoad, setLoad] = useState(false);

   const [isSuccess, setSuccess] = useState(false);
   const [lblSuccess, setTextSuccess] = useState('');

   const [isError, setError] = useState(false);
   const [lblError, setTextError] = useState('');

   const [selectedFR, setSelectedFR] = useState(null);
   const [isSelectedFR, setIsSelectedFR] = useState(false);
   const [selFR, setSelFR] = useState(false);

   const [selectedFB, setSelectedFB] = useState(null);
   const [isSelectedFB, setIsSelectedFB] = useState(false);
   const [selFB, setSelFB] = useState(false);

   const [selectedFO, setSelectedFO] = useState(null);
   const [isSelectedFO, setIsSelectedFO] = useState(false);
   const [selFO, setSelFO] = useState(false);

   const [dataUser, setDataUser] = useState([]);
   const [dataLine, setDataLine] = useState([]);
   const [dataContainer, setDataContainer] = useState([]);
   const [dataSection, setDataSection] = useState([]);
   const [dataPICBrokmar, setDataPICBrokmar] = useState([]);

   const [valueUser, setValueUser] = useState(null);
   const [valueLine, setValueLine] = useState(null);

   const columns = [
      { name: 'Code' },
      { name: 'Description' },
      {
         name: 'Action', options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRenderLite: (dataIndex, rowIndex) => {
               return (
                  <React.Fragment>

                     <Tooltip title="Select">
                        <IconButton color="primary" size="small" onClick={() => {
                           let code = data[dataIndex][0],
                              description = data[dataIndex][1];
                           console.log(code);
                           console.log(description);
                           setState({
                              ...state,
                              txtCode: code,
                              txtDesc: description,
                              cboSection: 0,
                              txtCode1: '',
                              txtCode2: '',
                              txtCode3: '',
                              txtDescHS: ''
                           });
                           setOpenModal(false);
                        }}>
                           <CheckIcon fontSize="inherit" />
                        </IconButton>
                     </Tooltip>
                  </React.Fragment>
               );
            }
         }
      }
   ];

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setError(false);
      setSuccess(false);
   };

   const handleSelect = (e) => {
      setState({ ...state, [e.target.name]: e.target.value });
      if (e.target.name == 'cboType') {
         if (e.target.value == objVer.booking.type) objVer.booking.val = true;
         else objVer.booking.val = false;
      }
   };

   const handleChange = (e) => {
      if (e.target.name == 'cboPICBrokmars') {
         setState({ ...state, [e.target.name]: e.target.value });
      } 
      else if (e.target.id == 'txtBooking') {
         setState({
            ...state,
            [e.target.id]: e.target.value.replace(/\s/g, '')
         });

         setValidBooking(false);
         if (e.target.value == objVer.booking.text) objVer.booking.val = true;
         else objVer.booking.val = false;

      } else {
         setState({
            ...state,
            [e.target.id]: e.target.value
         });
      }
   };

   const handleFileReceipt = (e) => {
      setSelectedFR(e.target.files[0]);
      setIsSelectedFR(true);
      setSelFR(false);
   }

   const handleFileBill = (e) => {
      setSelectedFB(e.target.files[0]);
      setIsSelectedFB(true);
      setSelFB(false);
   };

   const handleFileOther = (e) => {
      setSelectedFO(e.target.files[0]);
      setIsSelectedFO(true);
      setSelFO(false);
   };

   const handleLink = (objFile) => {
      const link = document.createElement('a');
      link.href = `${API_BASE_URL}/Login/Download?key=${objFile.key}&folder=${objFile.folder}&name=${objFile.name}`;
      link.setAttribute(
         'download',
         objFile.name
      );
      document.body.appendChild(link);
      link.click();
   }

   const handleValidationBooking = async () => {
      if (valueLine != null) {
         const booking = state.txtBooking,
            type = state.cboType,
            code = valueLine.code;
         if (booking.length) {
            if (booking != objVer.booking.text || type != objVer.booking.type) {
               let idcompany = objSession.idcompany,
                  idshipment = 0;
               if (objShip != null) idshipment = objShip.idshipment;
               setLoad(true);
               try {
                  const response = await httpClient.post('Ship/VerBooking', { idcompany, idshipment, type, booking }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') });
                  if (response.data.id == 0) {

                     let stype = 'BL';
                     if (type == 2) stype = 'BK';
                     const responseApi = await httpClient.post('Tracking/Consume', { stype, booking, code }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') });
                     //const responseApi = await httpClient.get(`${TRACKING_URL}/reference?type=${stype}&number=${booking}&sealine=${code}&api_key=${TRACKING_KEY}`);
                     const respFirst = responseApi.data;
                     const respReference = JSON.parse(respFirst);
                     console.log(respReference);
                     if (respReference.status == 'success' && (respReference.message == 'OK' || respReference.message == 'NO_CONTAINERS' || respReference.message == 'NO_EVENTS')) {
                        const dataApi = respReference.data;
                        let idorigin = null,
                           idtarget = null,
                           origin = '',
                           target = '',
                           dorigin = '',
                           dtarget = '',
                           dstart = '',
                           dend = '';

                        if (dataApi.route != null) {
                           let dpre = '',
                              dpost = '';
                           if (typeof dataApi.route.prepol.date != null) dpre = moment(dataApi.route.prepol.date).format('YYYY-MM-DD');
                           if (typeof dataApi.route.pol.date != null) dstart = moment(dataApi.route.pol.date).format('YYYY-MM-DD');
                           if (typeof dataApi.route.pod.date != null) dend = moment(dataApi.route.pod.date).format('YYYY-MM-DD');
                           if (typeof dataApi.route.postpod.date != null) dpost = moment(dataApi.route.postpod.date).format('YYYY-MM-DD');
                           if (dpre.length && dstart.length) dorigin = moment(dstart).diff(moment(dpre), 'days');
                           if (dpost.length && dend.length) dtarget = moment(dpost).diff(moment(dend), 'days');
                           idorigin = dataApi.route.pol.location;
                           idtarget = dataApi.route.pod.location;
                        }
                        if (dataApi.locations.length) {
                           for (let i = 0; i < dataApi.locations.length; i++) {
                              let value = dataApi.locations[i];
                              if (value.id == idorigin) origin = value.name + ' ' + value.country_code;
                              if (value.id == idtarget) target = value.name + ' ' + value.country_code;
                           }
                        }
                        setState({
                           ...state,
                           cboState: 2,
                           txtContainer: dataApi.containers.length,
                           txtOrigin: origin,
                           txtDOrigin: dorigin,
                           txtTarget: target,
                           txtDTarget: dtarget,
                           txtDateStart: dstart,
                           txtDateEnd: dend,
                           boapi: 1,
                           jsApi: JSON.stringify(responseApi.data)
                        });
                        setTextSuccess('Valid Code.');
                        setSuccess(true);
                     } else {
                        setState({
                           ...state,
                           cboState: 2,
                           boapi: 1,
                           jsApi: JSON.stringify(responseApi.data)
                        });
                        setTextSuccess('Valid code but currently has no information.');
                        setSuccess(true);
                     }

                     setValidBooking(false);

                     objVer.booking.text = booking;
                     objVer.booking.type = type;
                     objVer.booking.val = true;
                  } else {
                     setValidBooking(true);
                     setTextError('This code is already being used.');
                     setError(true);
                     objVer.booking.text = booking;
                     objVer.booking.type = type;
                     objVer.booking.val = false;
                  }
                  setLoad(false);
               } catch (error) {
                  setTextError('Server error.');
                  setError(true);
                  setLoad(false);
               }
            } else {
               setLoad(true);
               try {
                  let stype = 'BL';
                  if (type == 2) stype = 'BK';
                  const responseApi = await httpClient.post('Tracking/Consume', { stype, booking, code }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') });
                  //const responseApi = await httpClient.get(`${TRACKING_URL}/reference?type=${stype}&number=${booking}&sealine=${code}&api_key=${TRACKING_KEY}`);
                  const respFirst = responseApi.data;
                  const respReference = JSON.parse(respFirst);

                  if (respReference.status == 'success' && (respReference.message == 'OK' || respReference.message == 'NO_CONTAINERS' || respReference.message == 'NO_EVENTS')) {
                     const dataApi = respReference.data;
                     let idorigin = null,
                        idtarget = null,
                        origin = '',
                        target = '',
                        dorigin = '',
                        dtarget = '',
                        dstart = '',
                        dend = '';

                     if (dataApi.route != null) {
                        let dpre = '',
                           dpost = '';
                        if (typeof dataApi.route.prepol.date != null) dpre = moment(dataApi.route.prepol.date).format('YYYY-MM-DD');
                        if (typeof dataApi.route.pol.date != null) dstart = moment(dataApi.route.pol.date).format('YYYY-MM-DD');
                        if (typeof dataApi.route.pod.date != null) dend = moment(dataApi.route.pod.date).format('YYYY-MM-DD');
                        if (typeof dataApi.route.postpod.date != null) dpost = moment(dataApi.route.postpod.date).format('YYYY-MM-DD');
                        if (dpre.length && dstart.length) dorigin = moment(dstart).diff(moment(dpre), 'days');
                        if (dpost.length && dend.length) dtarget = moment(dpost).diff(moment(dend), 'days');
                        idorigin = dataApi.route.pol.location;
                        idtarget = dataApi.route.pod.location;
                     }
                     if (dataApi.locations.length) {
                        for (let i = 0; i < dataApi.locations.length; i++) {
                           let value = dataApi.locations[i];
                           if (value.id == idorigin) origin = value.name + ' ' + value.country_code;
                           if (value.id == idtarget) target = value.name + ' ' + value.country_code;
                        }
                     }
                     setState({
                        ...state,
                        cboState: 2,
                        txtContainer: dataApi.containers.length,
                        txtOrigin: origin,
                        txtDOrigin: dorigin,
                        txtTarget: target,
                        txtDTarget: dtarget,
                        txtDateStart: dstart,
                        txtDateEnd: dend,
                        boapi: 1,
                        jsApi: JSON.stringify(responseApi.data)
                     });

                  } else {
                     setState({
                        ...state,
                        boapi: 0,
                        jsApi: '{}'
                     });
                     setTextSuccess('Valid code but currently has no information.');
                     setSuccess(true);
                  }
                  setLoad(false);

               } catch (error) {
                  setLoad(false);
               }
            }
         } else {
            setValidBooking(true);
            setTextError('Enter a code with a minimum length of 6 characters.');
            setError(true);
         }
      } else {
         setTextError('Select a line.');
         setError(true);
      }
   };

   const isEmail = async (search) => {
       let serchfind = false;
       let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
       serchfind = regexp.test(search);
       return serchfind
   };

   const handleShip = async (e) => {
      let idclient = null;
      if (opt.type == 1) idclient = objClient.idclient || null;
      else {
         if (valueUser != null) idclient = valueUser.id;
      }
      if (idclient == null) {
         setTextError('Please select a client.');
         setError(true);
         return;
      }
      if (valueLine == null) {
         setTextError('Select a line.');
         setError(true);
         return;
      }
      if (!objVer.booking.val) {
         setValidBooking(true);
         setTextError('Please validate the booking by clicking the button next to it.');
         setError(true);
         return;
      }

      //Validación de correo Brokmar
      /*if (state.txtPICBrokmar?.trim() != '') {
         let emailbrokmar = state.txtPICBrokmar.split(',');
         let isInvalidEmailBrokmar = '';
         emailbrokmar.forEach(e => {
            if (e.trim() != '') {
               if (!validator.isEmail(e.trim())) {
                  isInvalidEmailBrokmar += e.trim();
               }
            }
         });

         if (isInvalidEmailBrokmar != '') {
            setTextError('Correo(s) no válido: ' + isInvalidEmailBrokmar);
            setError(true);
            return;
         }
      }*/
      
      //Validación de correo Client
      if (state.txtPICClient != null && state.txtPICClient?.trim() != '') {
         let emailclient = state.txtPICClient.split(',');
         let isInvalidEmailClient = '';
         emailclient.forEach(e => {
            if (e.trim() != '') {
               if (!validator.isEmail(e.trim())) {
                  isInvalidEmailClient += e.trim();
               }
            }
         });

         if (isInvalidEmailClient != '') {
            setTextError('Correo(s): no válido: ' + isInvalidEmailClient);
            setError(true);
            return;
         }
      }

      /*if (state.cboPICBrokmar == null || state.cboPICBrokmar == '') {
         setTextError('Select a PIC Brokmar');
         setError(true);
         return;
      }*/
      
      if (state.cboPICBrokmars.length == 0) {
         setTextError('Select a PIC Brokmar');
         setError(true);
         return;
      }

      let idPICBrokmar = '';
      let emailPICBrokmar = '';
      let userpicbrokmar = '';
      
      state.cboPICBrokmars.forEach(e => {
         idPICBrokmar += ',' + e.idclient;
         emailPICBrokmar += ',' + e.email;
         userpicbrokmar += ',' + e.user;
      });

      idPICBrokmar = idPICBrokmar.substring(1);
      emailPICBrokmar = emailPICBrokmar.substring(1);
      userpicbrokmar = userpicbrokmar.substring(1);
      
      let idcompany = objSession.idcompany,
         iduser = objSession.iduser,
         type = state.cboType,
         idsl = valueLine.id,
         booking = state.txtBooking,
         refclient = state.txtRefClient,
         refcompany = state.txtRefCompany,
         container = state.txtContainer || 0,
         origin = state.txtOrigin,
         dorigin = state.txtDOrigin || 0,
         target = state.txtTarget,
         dtarget = state.txtDTarget || 0,
         datestart = state.txtDateStart,
         dateend = state.txtDateEnd,

         idpicbrokmar = idPICBrokmar,
         emailpicbrokmar = emailPICBrokmar,
         
         picclient = state.txtPICClient ?? '',
         //buy = state.txtBuy || 0,
         //sale = state.txtSale || 0,
         bostate = state.cboState,
         boapi = state.boapi,
         jsApi = state.jsApi,
         fileReceipt = selectedFR,
         fileBill = selectedFB,
         fileOther = selectedFO,
         idtype = state.cboContainer,
         dg = state.cboDG,
         code = state.txtCode,
         desc = state.txtDesc;
         
      const formData = new FormData();
      formData.append('id_Company', idcompany);
      formData.append('id_User', iduser);
      formData.append('id_Client', idclient);
      formData.append('id_ShipLine', idsl);
      formData.append('nu_Shipment_type', type);
      formData.append('tx_Shipment_booking', booking);
      formData.append('tx_Shipment_refClient', refclient);
      formData.append('tx_Shipment_refCompany', refcompany);
      formData.append('nu_Shipment_container', container);
      formData.append('tx_Shipment_origin', origin);
      formData.append('nu_Shipment_origin', dorigin);
      formData.append('tx_Shipment_target', target);
      formData.append('nu_Shipment_target', dtarget);
      formData.append('dt_Shipment_start', datestart);
      formData.append('dt_Shipment_end', dateend);
      //formData.append('nu_Shipment_buy', buy);
      //formData.append('nu_Shipment_sale', sale);

      //formData.append('tx_Shipment_picbrokmar', picbrokmar);
      formData.append('tx_Shipment_picBrokmar', idpicbrokmar);
      formData.append('tx_Email_picBrokmar', emailpicbrokmar);
      formData.append('tx_User_picBrokmar', userpicbrokmar);

      formData.append('tx_Shipment_picClient', picclient);

      formData.append('bo_Shipment_state', bostate);
      formData.append('bo_Shipment_api', boapi);
      formData.append('js_Shipment_api', jsApi);
      formData.append('fileReceipt', fileReceipt);
      formData.append('fileBill', fileBill);
      formData.append('fileOther', fileOther);
      formData.append('id_MT_typeContainer', idtype);
      formData.append('bo_Shipment_dg', dg);
      formData.append('tx_Shipment_codeHS', code ?? '');
      formData.append('tx_Shipment_descHS', desc ?? '');
      if (objShip == null) {

         setLoad(true);
         httpClient.post('Ship/Ins', formData, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
               return response.data;
            })
            .then(response => {
               if (response.done == 1) {
                  setTextSuccess('Registered shipment.');
                  setSuccess(true);
                  navigate(-1);
               } else {
                  setTextError('Unexpected error, check with your system administrator.');
                  setError(true);
               }
               setLoad(false);
            })
            .catch(error => {
               setTextError('Server error.');
               setError(true);
               setLoad(false);
            });
      } else {
         let idshipment = objShip.idshipment,
            jsReceipt = objShip.jsReceipt,
            jsBill = objShip.jsBill,
            jsOther = objShip.jsOther;
         if (fileReceipt != null || !selFR) {
            if (jsReceipt != null) jsReceipt.hide = true;
         }
         if (fileBill != null || !selFB) {
            if (jsBill != null) jsBill.hide = true;
         }
         if (fileOther != null || !selFO) {
            if (jsOther != null) jsOther.hide = true;
         }
         formData.append('id_Shipment', idshipment);
         formData.append('jsReceipt', jsReceipt != null ? JSON.stringify(jsReceipt) : '');
         formData.append('jsBill', jsBill != null ? JSON.stringify(jsBill) : '');
         formData.append('jsOther', jsOther != null ? JSON.stringify(jsOther) : '');

         formData.append('hd_company', objClient.company);
         formData.append('hd_user', objClient.user);
         formData.append('hd_user_email', objClient.email ?? '');

         setLoad(true);
         httpClient.post('Ship/Upd', formData, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
               return response.data;
            })
            .then(response => {
               if (response.done == 1) {
                  setTextSuccess('Updated shipment.');
                  setSuccess(true);
                  navigate(-1);
               } else {
                  setTextError('Unexpected error, check with your system administrator.');
                  setError(true);
               }
               setLoad(false);
            })
            .catch(error => {
               setTextError('Server error.');
               setError(true);
               setLoad(false);
            });
      }
   };

   const getData = async (type) => {
      setLoad(true);
      let _DataPICBrokmar = [];
      await httpClient.post('Client/List', { idcompany: objSession.idcompany, order_by: 'user', asc_desc: 'asc' }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
      .then(response => {
         return response.data.filter(x => x.state == 'Active' && x.idprofile == Profile.Operations); 
      })
      .then(response => {
         setDataPICBrokmar(response);
         _DataPICBrokmar = response;
      });
      
      if (type == 0) {
         await httpClient.post('Client/Min', { idcompany: objSession.idcompany, iduser: objSession.iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
               return response.data;
            })
            .then(response => {
               
               if (response.listClient.length) {

                  let data = [];
                  let clients = response.listClient.filter(x => x.idprofile == Profile.Client);
                  for (let i = 0; i < clients.length; i++) {
                     let value = clients[i];
                     data.push({
                        id: value.idclient,
                        user: value.user,
                        company: value.company,
                        userorigin: value.userorigin
                     });
                  }
                  let d = data.filter(x => x.userorigin == 'A');
                  if (objSession.idprofile == Profile.Administrador || objSession.alluser) {
                     setDataUser(d);
                  }
                  else {
                     if (objSession.user_view == '') {
                        setDataUser([]);
                     }
                     else {
                        let idUsers = objSession.user_view.split(',');
                        let d2 = [];
                        d.forEach(e => {
                           let exist = idUsers.filter(x => x == e.id.toString())[0];
                           if (exist != null) {
                              d2.push(e);
                           }
                        });
                        setDataUser(d2);
                     }
                  }
               }

               if (response.listLine.length) {

                  let data = []
                  for (let i = 0; i < response.listLine.length; i++) {
                     let value = response.listLine[i];
                     data.push({
                        id: value.idsl,
                        title: value.title,
                        code: value.code
                     });
                  }
                  setDataLine(data);
                  if (objShip == null) {
                     setValueLine(data[0]);
                  } else {
                     let item = null;
                     for (let i = 0; i < data.length; i++) {
                        let value = data[i];
                        if (value.id == objShip.idsl) item = value;
                     }
                     setValueLine(item);
                  }
               }

               if (response.listContainer.length) {
                  setDataContainer(response.listContainer);
               }

               if (response.listSection.length) {
                  let dataS = [{ idsection: 0, code: 'Buscar en', description: 'Todos' }];
                  dataS = dataS.concat(response.listSection);
                  setDataSection(dataS);
               }

               setLoad(false);
            })
            .catch(error => {
               setLoad(false);
            });
      } else {
         await httpClient.post('Ship/ListLine', { idcompany: objSession.idcompany }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
               return response.data;
            })
            .then(response => {

               if (response.listLine.length) {
                  let data = []
                  for (let i = 0; i < response.listLine.length; i++) {
                     let value = response.listLine[i];
                     data.push({
                        id: value.idsl,
                        title: value.title,
                        code: value.code
                     });
                  }
                  setDataLine(data);
                  if (objShip == null) {
                     setValueLine(data[0]);
                  } else {
                     let item = data.filter(x => x.id == objShip.idsl)[0];
                     setValueLine(item);
                  }
               }

               if (response.listContainer.length) {
                  setDataContainer(response.listContainer);
               }

               if (response.listSection.length) {
                  let dataS = [{ idsection: 0, code: 'Buscar en', description: 'Todos' }];
                  dataS = dataS.concat(response.listSection);
                  setDataSection(dataS);
               }

               setLoad(false);
            })
            .catch(error => {
               setLoad(false);
            });
      }

      if (objShip != null) {
         let chkT = false;
         /*if (objShip.jsTransfer != null) {
            if(objShip.jsTransfer.length){
               
               let arrayTransfer = objShip.jsTransfer;
               if (arrayTransfer.length) {
                  chkT = true;
               }
               setTransfer(arrayTransfer);
            }
            
         }*/
         if (objShip.jsTransfer.length) {
            if(objShip.jsTransfer.length){
               chkT = true;
            }
         }
         let _datestart = '';
         let _dateend = '';
         if (!(objShip.datestart == null || objShip.datestart == '')) {
            _datestart = moment(objShip.datestart, 'DD/MM/YYYY').format('YYYY-MM-DD')
         } 
         if (!(objShip.dateend == null || objShip.dateend == '')) {
            _dateend = moment(objShip.dateend, 'DD/MM/YYYY').format('YYYY-MM-DD')
         } 
         
         let cboPICBrokmars = [];
         let ids = objShip.picbrokmar.split(',');
         ids.forEach(e => {
            let d = _DataPICBrokmar.filter(x => x.idclient == e)[0];
            if (d != null) {
               cboPICBrokmars.push(d);
            }
         });
         
         setState({
            ...state,
            cboState: objShip.bostate,
            cboType: objShip.type,
            cboContainer: objShip.idtype,
            cboDG: objShip.dg,
            txtRefClient: objShip.refclient || '',
            txtRefCompany: objShip.refcompany || '',
            txtBooking: objShip.booking,
            txtContainer: objShip.container,
            txtOrigin: objShip.origin || '',
            txtDOrigin: objShip.dorigin,
            txtTarget: objShip.target || '',
            txtDTarget: objShip.dtarget,
            txtDateStart: _datestart,
            txtDateEnd: _dateend,
            //PICBrokmar: objShip.picbrokmar,
            cboPICBrokmar: objShip.picbrokmar,
            txtPICClient: objShip.picclient,
            //txtBuy: objShip.buy,
            //txtSale: objShip.sale,
            txtCode: objShip.codeHS,
            txtDesc: objShip.descHS,
            chkTransfer: chkT,
            cboPICBrokmars: cboPICBrokmars
         });

         if (objShip.jsReceipt != null) setSelFR(true);
         if (objShip.jsBill != null) setSelFB(true);
         if (objShip.jsOther != null) setSelFO(true);

         objVer = { booking: { type: objShip.type, text: objShip.booking, val: true } };
      } else {

         objVer = { booking: { type: 1, text: '', val: false } };
      }

   };

   useEffect(() => {
      getData(opt.type);
   }, []);

   const handleSearch = async () => {
      let idsection = state.cboSection,
         code1 = state.txtCode1,
         code2 = state.txtCode2,
         code3 = state.txtCode3,
         description = state.txtDescHS,
         idharmonized = code1;
      /*if (code1 == '00') code1 = '';
      if (code1.length == 1) code1 = '0' + code1;
      if (code2 == '00') code2 = '';
      if (code2.length == 1) code2 = '0' + code2;
      if (code3 == '00') code3 = '';
      if (code3.length == 1) code3 = '0' + code3;
      idharmonized = code1 + code2 + code3;*/
      setLoad(true);
      await httpClient.post('Ship/Harmonized', { idsection, idharmonized, description }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
         .then(response => {
            return response.data;
         })
         .then(response => {
            let dataH = []
            if (response.length) {

               for (let i = 0; i < response.length; i++) {
                  let value = response[i];
                  dataH.push([
                     value.idharmonized,
                     value.description
                  ]);
               }

            }
            setData(dataH);
            setLoad(false);
         })
         .catch(error => {
            setLoad(false);
         });

   };

   const handleOpenModal = () => {
      setOpenModal(true);
   };

   const handleCloseModal = () => {
      setState({
         ...state,
         cboSection: 0,
         txtCode1: '',
         txtCode2: '',
         txtCode3: '',
         txtDescHS: ''
      });
      setOpenModal(false);
   };

   const body = (
      <React.Fragment>
         <Hidden mdDown>
            <Box className={classes.paper}>
               <Box m={2}>
                  <Typography variant="h4">Search HS</Typography>
                  <Grid container spacing={1}>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography>Section:</Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <FormControl fullWidth variant="outlined" margin="normal" size="small">
                           <Select
                              labelId="cboSection"
                              name="cboSection"
                              value={state.cboSection}
                              onChange={handleSelect}>
                              {dataSection.map((obj) => (
                                 <MenuItem key={obj.idsection} value={obj.idsection} >
                                    {obj.code} - {obj.description}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>

                     </Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography>Code HS:</Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <TextField fullWidth style={{ marginTop: 0 }}
                           label=""
                           margin="normal"
                           id="txtCode1"
                           type="number"
                           variant="outlined" size="small"
                           onChange={handleChange}
                           value={state.txtCode1} />

                     </Grid>
                     {/*<Grid item xs={3}>
                        <TextField fullWidth style={{ marginTop: 0 }}
                           label=""
                           margin="normal"
                           placeholder="00"
                           id="txtCode2"
                           type="number"
                           variant="outlined" size="small"
                           onChange={handleChange}
                           value={state.txtCode2} />

                     </Grid>
                     <Grid item xs={3}>
                        <TextField fullWidth style={{ marginTop: 0 }}
                           label=""
                           margin="normal"
                           placeholder="00"
                           id="txtCode3"
                           type="number"
                           variant="outlined" size="small"
                           onChange={handleChange}
                           value={state.txtCode3} />

                              </Grid>*/}
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography>Description HS:</Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <TextField fullWidth style={{ marginTop: 0 }}
                           label=""
                           margin="normal"
                           id="txtDescHS"
                           type="text"
                           variant="outlined" size="small"
                           onChange={handleChange}
                           value={state.txtDescHS} />

                     </Grid>
                  </Grid>
                  <Box display="flex" justifyContent="flex-end">
                     <Button variant="contained" size="small" color="primary" onClick={handleSearch}>Search</Button>
                  </Box>
               </Box>
               <MUIDataTable
                  title={""}
                  data={data}
                  columns={columns}
                  options={options}
               />
            </Box>
         </Hidden>
         <Hidden lgUp>
            <Box className={classes.papermobile}>
               <Box m={2}>
                  <Typography variant="h4">Search HS</Typography>
                  <Grid container spacing={1}>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography>Section:</Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <FormControl fullWidth variant="outlined" margin="normal" size="small">
                           <Select
                              labelId="cboSection"
                              name="cboSection"
                              value={state.cboSection}
                              onChange={handleSelect}>
                              {dataSection.map((obj) => (
                                 <MenuItem key={obj.idsection} value={obj.idsection} >
                                    {obj.code} - {obj.description}
                                 </MenuItem>
                              ))}
                           </Select>
                        </FormControl>

                     </Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography>Code HS:</Typography>
                     </Grid>
                     <Grid item xs={3}>
                        <TextField fullWidth style={{ marginTop: 0 }}
                           label=""
                           margin="normal"
                           placeholder="00"
                           id="txtCode1"
                           type="number"
                           variant="outlined" size="small"
                           onChange={handleChange}
                           value={state.txtCode1} />

                     </Grid>
                     <Grid item xs={3}>
                        <TextField fullWidth style={{ marginTop: 0 }}
                           label=""
                           margin="normal"
                           placeholder="00"
                           id="txtCode2"
                           type="number"
                           variant="outlined" size="small"
                           onChange={handleChange}
                           value={state.txtCode2} />

                     </Grid>
                     <Grid item xs={3}>
                        <TextField fullWidth style={{ marginTop: 0 }}
                           label=""
                           margin="normal"
                           placeholder="00"
                           id="txtCode3"
                           type="number"
                           variant="outlined" size="small"
                           onChange={handleChange}
                           value={state.txtCode3} />

                     </Grid>
                     <Grid item xs={3} style={{ margin: "auto" }}>
                        <Typography>Description HS:</Typography>
                     </Grid>
                     <Grid item xs={9}>
                        <TextField fullWidth style={{ marginTop: 0 }}
                           label=""
                           margin="normal"
                           id="txtDescHS"
                           type="text"
                           variant="outlined" size="small"
                           onChange={handleChange}
                           value={state.txtDescHS} />

                     </Grid>
                  </Grid>
                  <Box display="flex" justifyContent="flex-end">
                     <Button variant="contained" size="small" color="primary" onClick={handleSearch}>Search</Button>
                  </Box>
               </Box>
               <MUIDataTable
                  title={""}
                  data={data}
                  columns={columns}
                  options={options}
               />
            </Box>
         </Hidden>
      </React.Fragment>
   );

   return (
      <Page className={classes.root} title={opt.title} id="shipinfo-pnl">
         <Modal
            open={openModal}
            onClose={handleCloseModal}>
            {body}
         </Modal>
         <Container maxWidth="lg">
            <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="error">
                  {lblError}
               </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="success">
                  {lblSuccess}
               </Alert>
            </Snackbar>
            {opt.type == 1 ? (
               <Box mt={3}>
                  <Slide direction="up" in={true} mountOnEnter unmountOnExit >
                     <Card className={classes.border}>
                        <CardContent>
                           <Grid container spacing={3}>
                              <Grid item >
                                 <Avatar className={classes.avatar} />
                              </Grid>
                              <Grid item >
                                 <Typography color="textSecondary" variant="body1">Company: {objClient.company}</Typography>
                                 <Typography color="textSecondary" variant="body1">User: {objClient.user}</Typography>
                                 <Typography color="textSecondary" variant="body1">Email: {objClient.email.length ? objClient.email : 'No email'}</Typography>
                                 <Typography color="textSecondary" variant="body1">Folder Code: {objClient.folder.length ? objClient.folder : 'Not assigned'}</Typography>
                              </Grid>
                           </Grid>
                        </CardContent>
                     </Card>
                  </Slide>
               </Box>) : (<></>)}

            <Box mt={3}>
               <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Card className={classes.border}>
                     <CardHeader title={opt.title} subheader={opt.subtitle} />
                     <Divider />
                     <CardContent>
                        <Grid container spacing={3}>
                           <Grid item xs={12} sm={6}>
                              <Grid container>
                                 {opt.type == 0 ? (
                                    <Grid item xs={12}>
                                       <Autocomplete
                                          id="cboUser"
                                          options={dataUser}
                                          getOptionLabel={(option) => option.company + ' - ' + option.user}
                                          fullWidth
                                          margin="normal"
                                          onChange={(event, newValue) => {
                                             setValueUser(newValue);
                                          }}
                                          renderInput={(params) => <TextField {...params} label="Search client" variant="outlined" margin="normal" />}
                                       />

                                    </Grid>) : (<></>)}
                                 <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined" margin="normal">
                                       <InputLabel id="cboState">State</InputLabel>
                                       <Select
                                          labelId="cboState"
                                          name="cboState"
                                          value={state.cboState}
                                          onChange={handleSelect}
                                          label="Estado">
                                          <MenuItem value={2}>Active</MenuItem>
                                          <MenuItem value={3}>Finished</MenuItem>
                                       </Select>
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined" margin="normal">
                                       <InputLabel id="cboType">Type</InputLabel>
                                       <Select
                                          labelId="cboType"
                                          name="cboType"
                                          value={state.cboType} 
                                          onChange={handleSelect}
                                          label="Tipo">
                                          <MenuItem value={1}>Bill of Lading</MenuItem>
                                          <MenuItem value={2}>Booking</MenuItem>
                                       </Select>
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Autocomplete
                                       value={valueLine}
                                       id="cboLine"
                                       options={dataLine}
                                       getOptionLabel={(option) => option.title}
                                       fullWidth
                                       margin="normal"
                                       onChange={(event, newValue) => {
                                          setValueLine(newValue);
                                       }}
                                       renderInput={(params) => <TextField {...params} label="Select Line" variant="outlined" margin="normal" />}
                                    />

                                 </Grid>
                                 <Grid item xs={10}>
                                    <TextField fullWidth
                                       label="Number"
                                       margin="normal"
                                       id="txtBooking"
                                       onChange={handleChange}
                                       type="text"
                                       value={state.txtBooking}
                                       variant="outlined"
                                       error={isValidBooking} />
                                 </Grid>
                                 <Grid item xs={2} m="auto" className={classes.m_auto} align="center">
                                    <Tooltip title="Validate">
                                       <IconButton color="primary" onClick={handleValidationBooking}>
                                          <CheckIcon />
                                       </IconButton>
                                    </Tooltip>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Ref Client"
                                       margin="normal"
                                       id="txtRefClient"
                                       onChange={handleChange}
                                       type="text"
                                       value={state.txtRefClient}
                                       variant="outlined" />

                                 </Grid>
                                 {/*<Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Ref Company"
                                       margin="normal"
                                       id="txtRefCompany"
                                       onChange={handleChange}
                                       type="text"
                                       value={state.txtRefCompany}
                                       variant="outlined" />

                                    </Grid>*/}
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Containers"
                                       margin="normal"
                                       id="txtContainer"
                                       onChange={handleChange}
                                       type="number"
                                       value={state.txtContainer}
                                       variant="outlined" />

                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Port of Origin"
                                       margin="normal"
                                       id="txtOrigin"
                                       onChange={handleChange}
                                       type="text"
                                       value={state.txtOrigin}
                                       variant="outlined" />

                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Free Days Origin"
                                       margin="normal"
                                       id="txtDOrigin"
                                       onChange={handleChange}
                                       type="text"
                                       value={state.txtDOrigin}
                                       variant="outlined" />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Port of destination"
                                       margin="normal"
                                       id="txtTarget"
                                       onChange={handleChange}
                                       type="text"
                                       value={state.txtTarget}
                                       variant="outlined" />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Free Days Destination"
                                       margin="normal"
                                       id="txtDTarget"
                                       onChange={handleChange}
                                       type="text"
                                       value={state.txtDTarget}
                                       variant="outlined" />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Sailing Date"
                                       margin="normal"
                                       id="txtDateStart"
                                       onChange={handleChange}
                                       type="date"
                                       value={state.txtDateStart}
                                       variant="outlined"
                                       InputLabelProps={{
                                          shrink: true,
                                       }} />
                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Arrival Date"
                                       margin="normal"
                                       id="txtDateEnd"
                                       onChange={handleChange}
                                       type="date"
                                       value={state.txtDateEnd}
                                       variant="outlined"
                                       InputLabelProps={{
                                          shrink: true,
                                       }} />
                                 </Grid>
                              </Grid>
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <Grid container>
                                 <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined" margin="normal">
                                       <InputLabel id="cboPICBrokmars">PIC BROKMAR</InputLabel>
                                       <Select
                                          labelId="cboPICBrokmars"
                                          name="cboPICBrokmars"
                                          multiple
                                          value={state.cboPICBrokmars}
                                          onChange={handleChange}
                                          input={<OutlinedInput label="PIC BROKMAR" />}
                                          renderValue={(selected) => {
                                             let value = [];
                                             selected.forEach(e => {
                                                value.push(e.user);
                                             });
                                             return value.toString();
                                          }}>
                                          {dataPICBrokmar.map((obj) => (
                                             <MenuItem key={obj.idclient} value={obj}>
                                             <Checkbox checked={state.cboPICBrokmars.indexOf(obj) > -1} />
                                             <ListItemText primary={obj.user} />
                                             </MenuItem>
                                          ))}
                                       </Select>
                                    </FormControl>
                                 </Grid>
                                 {/*<Grid item xs={12}>
                                    <FormControl variant="outlined" margin="normal" fullWidth>
                                       <InputLabel htmlFor="txtPICBrokmar">PIC BROKMAR</InputLabel>
                                       <OutlinedInput
                                          id="txtPICBrokmar"
                                          type="email" 
                                          placeholder='Separator: ,'
                                          value={state.txtPICBrokmar}
                                          onChange={handleChange}
                                          labelWidth={110}
                                       />
                                    </FormControl>
                                 </Grid>*/}
                                 <Grid item xs={12}>
                                    <FormControl variant="outlined" margin="normal" fullWidth>
                                       <InputLabel htmlFor="txtPICClient">PIC CLIENT</InputLabel>
                                       <OutlinedInput
                                          id="txtPICClient"
                                          type="email"
                                          placeholder='Separator: ,'
                                          value={state.txtPICClient}
                                          onChange={handleChange}
                                          labelWidth={85}
                                       />
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined" margin="normal">
                                       <InputLabel id="cboContainer">Type Container</InputLabel>
                                       <Select
                                          labelId="cboContainer"
                                          name="cboContainer"
                                          value={state.cboContainer}
                                          onChange={handleSelect}
                                          label="Type Container">
                                          {dataContainer.map((obj) => (
                                             <MenuItem key={obj.idmt} value={obj.idmt} >
                                                {obj.title}
                                             </MenuItem>
                                          ))}
                                       </Select>
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <FormControl fullWidth variant="outlined" margin="normal">
                                       <InputLabel id="cboDG">DG</InputLabel>
                                       <Select
                                          labelId="cboDG"
                                          name="cboDG"
                                          value={state.cboDG}
                                          onChange={handleSelect}
                                          label="DG">
                                          <MenuItem value={1}>Yes</MenuItem>
                                          <MenuItem value={0}>No</MenuItem>
                                       </Select>
                                    </FormControl>
                                 </Grid>
                                 <Grid item xs={10}>
                                    <TextField fullWidth
                                       label="Code HS"
                                       margin="normal"
                                       id="txtCode"
                                       type="text"
                                       onChange={handleChange}
                                       value={state.txtCode}
                                       variant="outlined"
                                       disabled
                                    />
                                 </Grid>
                                 <Grid item xs={2} m="auto" className={classes.m_auto} align="center">
                                    <Tooltip title="Search">
                                       <IconButton color="primary" onClick={handleOpenModal}>
                                          <SearchIcon />
                                       </IconButton>
                                    </Tooltip>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <TextField fullWidth
                                       label="Description HS"
                                       margin="normal"
                                       id="txtDesc"
                                       type="number"
                                       onChange={handleChange}
                                       value={state.txtDesc}
                                       variant="outlined"
                                       multiline
                                       rows={2}
                                       disabled
                                    />
                                 </Grid>
                                 {opt.type == 1 ? (
                                    <Grid item xs={12} className={classes.pnlChk}>
                                       <FormGroup row>
                                          <FormControlLabel
                                             control={<Checkbox checked={state.chkTransfer} name="chkTransfer" />}
                                             label="Transhipment" disabled
                                          />
                                       </FormGroup>
                                       <List dense>
                                          {objShip.jsTransfer.length?( 
                                             <ListItem >
                                                <ListItemText primary={objShip.jsTransfer} />
                                             </ListItem>):(<></>)}
                                         
                                       </List>
                                    </Grid>) : (<></>)


                                 }

                                 <Grid item xs={12}>
                                    <Box className={classes.m_upload}>
                                       <Grid container spacing={1}>
                                          <Grid item xs={2}>
                                             <Typography>Invoice:</Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                             <input
                                                accept="image/*,.pdf"
                                                className={classes.hide}
                                                id="txtFileReceipt"
                                                type="file"
                                                onChange={handleFileReceipt}
                                             />
                                             <label htmlFor="txtFileReceipt">
                                                <Button variant="contained" color="primary" component="span">
                                                   Upload file
                                                </Button>
                                             </label>
                                          </Grid>
                                          <Grid item xs={6}>
                                             {
                                                isSelectedFR ? (
                                                   <React.Fragment>
                                                      <Typography variant="h6" className={classes.dinline}> {selectedFR.name.length > 15 ? selectedFR.name.substring(0, 15) + '...' : selectedFR.name}</Typography>
                                                      <IconButton color="secondary" size="small" onClick={() => { setIsSelectedFR(false); setSelectedFR(null); }}>
                                                         <CloseIcon />
                                                      </IconButton>
                                                   </React.Fragment>
                                                ) :
                                                   !selFR ? (<></>) :
                                                      (
                                                         <React.Fragment>
                                                            <Link onClick={() => { handleLink(objShip.jsReceipt) }}>
                                                               <Typography variant="h6" className={classes.dinline}>{objShip.jsReceipt.name.length > 15 ? objShip.jsReceipt.name.substring(0, 15) + '...' : objShip.jsReceipt.name}
                                                               </Typography>
                                                            </Link>
                                                            <IconButton color="secondary" size="small" onClick={() => { setSelFR(false); }}>
                                                               <CloseIcon />
                                                            </IconButton>
                                                         </React.Fragment>
                                                      )
                                             }
                                          </Grid>
                                       </Grid>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Box className={classes.m_upload}>
                                       <Grid container spacing={1}>
                                          <Grid item xs={2}>
                                             <Typography>Bill of Landing:</Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                             <input
                                                accept="image/*,.pdf"
                                                className={classes.hide}
                                                id="txtFileBill"
                                                type="file"
                                                onChange={handleFileBill}
                                             />
                                             <label htmlFor="txtFileBill">
                                                <Button variant="contained" color="primary" component="span">
                                                   Upload file
                                                </Button>
                                             </label>
                                          </Grid>
                                          <Grid item xs={6}>
                                             {
                                                isSelectedFB ? (
                                                   <React.Fragment>
                                                      <Typography variant="h6" className={classes.dinline}> {selectedFB.name.length > 15 ? selectedFB.name.substring(0, 15) + '...' : selectedFB.name}</Typography>
                                                      <IconButton color="secondary" size="small" onClick={() => { setIsSelectedFB(false); setSelectedFB(null); }}>
                                                         <CloseIcon />
                                                      </IconButton>
                                                   </React.Fragment>
                                                ) :
                                                   !selFB ? (<></>) :
                                                      (
                                                         <React.Fragment>
                                                            <Link onClick={() => { handleLink(objShip.jsBill) }}>
                                                               <Typography variant="h6" className={classes.dinline}>{objShip.jsBill != null ? (objShip.jsBill.name.length > 15 ? objShip.jsBill.name.substring(0, 15) + '...' : objShip.jsBill.name) : ''}
                                                               </Typography>
                                                            </Link>
                                                            <IconButton color="secondary" size="small" onClick={() => { setSelFB(false); }}>
                                                               <CloseIcon />
                                                            </IconButton>
                                                         </React.Fragment>
                                                      )
                                             }
                                          </Grid>
                                       </Grid>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <Box className={classes.m_upload}>
                                       <Grid container spacing={1}>
                                          <Grid item xs={2}>
                                             <Typography>Other:</Typography>
                                          </Grid>
                                          <Grid item xs={4}>
                                             <input
                                                accept="image/*,.pdf"
                                                className={classes.hide}
                                                id="txtFileOther"
                                                type="file"
                                                onChange={handleFileOther}
                                             />
                                             <label htmlFor="txtFileOther">
                                                <Button variant="contained" color="primary" component="span">
                                                   Upload file
                                                </Button>
                                             </label>
                                          </Grid>
                                          <Grid item xs={6}>
                                             {
                                                isSelectedFO ? (
                                                   <React.Fragment>
                                                      <Typography variant="h6" className={classes.dinline}> {selectedFO.name.length > 15 ? selectedFO.name.substring(0, 15) + '...' : selectedFO.name}</Typography>
                                                      <IconButton color="secondary" size="small" onClick={() => { setIsSelectedFO(false); setSelectedFO(null); }}>
                                                         <CloseIcon />
                                                      </IconButton>
                                                   </React.Fragment>
                                                ) :
                                                   !selFO ? (<></>) :
                                                      (
                                                         <React.Fragment>
                                                            <Link onClick={() => { handleLink(objShip.jsOther) }}>
                                                               <Typography variant="h6" className={classes.dinline}>{objShip.jsOther != null ? (objShip.jsOther.name.length > 15 ? objShip.jsOther.name.substring(0, 15) + '...' : objShip.jsOther.name) : ''}
                                                               </Typography>
                                                            </Link>
                                                            <IconButton color="secondary" size="small" onClick={() => { setSelFO(false); }}>
                                                               <CloseIcon />
                                                            </IconButton>
                                                         </React.Fragment>
                                                      )
                                             }
                                          </Grid>
                                       </Grid>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </CardContent>
                     <Divider />
                     <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button color="primary" variant="contained" onClick={handleShip}> {objShip == null ? 'Create' : 'Update'} </Button>
                     </Box>
                  </Card>
               </Slide>
            </Box>
            <Backdrop className={classes.backdrop} open={isLoad}>
               <CircularProgress color="inherit" />
            </Backdrop>
         </Container>
      </Page>
   );
};

export default UserRegisterView;