import React, { useState } from "react";
import { Link, Button, CssBaseline, InputLabel, OutlinedInput, IconButton, TextField, FormControlLabel, Checkbox, InputAdornment, FormControl, Box, Typography, Container, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import httpClient from '../../services/axios-client';
import Cookies from 'universal-cookie';
import Page from '../../components/Page';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { APP_BASE } from '../../constants/apiConstants';
import { useNavigate } from "react-router";


const cookies = new Cookies();

const Alert = (props) => {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({

   background: {
      display: 'grid',
      placeContent: 'center',
      minHeight: '100vh',
      backgroundImage: `url(${"../images/principal/fondo-login.jpg"})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
   },
   border: {
      textAlign: 'center'
   },
   paper: {
      //marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: '1px solid white',
      borderRadius: '25px',
      padding: '25px',
      textAlign: 'center',
      background: 'transparent'
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
   form: {
      width: '100%',
      marginTop: theme.spacing(1),
      '& .MuiTextField-root': {
         background: 'rgba(255, 255, 255, 0.2)',
      },
      '& .MuiOutlinedInput-root': {
         background: 'rgba(255, 255, 255, 0.2)'
      }, 
      '& .MuiInputBase-input': {
         fontWeight: '600'
      }
   },
   submitBlue: {
      background: '#205ba8',
      color: '#fff',
      margin: theme.spacing(3, 0, 2),
      width: '50%'
   },
   submitWhite: {
      background: '#fff',
      color: '#205ba8',
      margin: theme.spacing(3, 0, 2),
      width: '50%'
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
   imglogo: {
      width: '330px',
      marginBottom: '30px'
   },
   white: {
      color: 'white'
   },
   inline: {
      display: 'inline',
   },
   inliner: {
      display: 'inline',
      borderRight: '2px solid'
   },
   linkright: {
      marginRight: '60px'
   },
   linkleft: {
      marginLeft: '60px'
   }
}));

const LoginView = () => {
   const navigate = useNavigate();

   const classes = useStyles();

   const [state, setState] = useState({
      txtUser: '',
      txtPass: '',
      chkRemember: false,
      showPassword: false
   });

   const [isLoad, setLoad] = useState(false);
   const [isValid, setValid] = useState(false);
   const [isValidP, setValidP] = useState(false);

   const [isSuccess, setSuccess] = useState(false);
   const [lblSuccess, setTextSuccess] = useState('');

   const [isError, setError] = useState(false);
   const [lblError, setTextError] = useState('');

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setError(false);
      setSuccess(false);
   };

   const handleChange = (e) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      setState({
         ...state,
         [e.target.id]: value
      });
      if (e.target.id == 'txtUser') setValid(false);
      if (e.target.id == 'txtPass') setValidP(false);
   };

   const handleClickShowPassword = () => {
      setState({ ...state, showPassword: !state.showPassword });
   };

   const CreateUser = () => {
      navigate('/login/user-register', { state: { 'router': 'login' } });
   }

   const ResetPass = () => {
      navigate('/recover');
   }

   const LogIn = (e) => {
      e.preventDefault();
      const user = state.txtUser,
         pass = state.txtPass;
      if (user.length > 5 && pass.length > 5) {
         setLoad(true);
         httpClient.post('Login/Enter', { user, pass })
            .then(response => {
               return response.data;
            })
            .then(response => {
               if (response.token.length) {
                  response.user.jsMenu = response.user.jsMenu.replaceAll('\\"', '"').replaceAll('"{', '{').replaceAll('}"', '}');
                  cookies.set(APP_BASE + '.objSession', JSON.stringify(response.user), { path: '/' });
                  cookies.set(APP_BASE + '.token', response.token, { path: '/' });
                  setTextSuccess(`Welcome ${response.user.personName}.`);
                  setSuccess(true);
                  //navigate(process.env.PUBLIC_URL + '/app/dashboard');
                  window.location.href = process.env.PUBLIC_URL; //+ '/app/dashboard';
               }
               setLoad(false);
            })
            .catch(error => {
               setLoad(false);
               setTextError('Incorrect username and / or password.');
               setError(true);
            });
      } else {
         setTextError('Fill in the fields correctly.');
         setError(true);
         if (user.length < 6) setValid(true);
         if (pass.length < 6) setValidP(true);
      }

   };

   return (
      <Page title="Log In" className={classes.background} id="login-pnl">
         <Container component="main" maxWidth="xs" className={classes.border}>
            <CssBaseline />
            <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="error">
                  {lblError}
               </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="success">
                  {lblSuccess}
               </Alert>
            </Snackbar>
            <img className={classes.imglogo}
               src={`/images/principal/logo-white-brookmar.svg`}
            />
            <div className={classes.paper}>

               <form className={classes.form} noValidate>
                  <TextField
                     variant="outlined"
                     margin="normal"
                     fullWidth
                     type="text"
                     id="txtUser"
                     label="User"
                     color="secondary"
                     autoFocus
                     value={state.txtUser}
                     onChange={handleChange}
                     error={isValid} />

                  <FormControl error={isValidP} variant="outlined" margin="normal" color="secondary" fullWidth>
                     <InputLabel htmlFor="txtPass">Password</InputLabel>
                     <OutlinedInput
                        id="txtPass"
                        type={state.showPassword ? 'text' : 'password'}
                        value={state.txtPass}
                        onChange={handleChange}
                        endAdornment={
                           <InputAdornment position="end">
                              <IconButton
                                 aria-label="toggle password visibility"
                                 onClick={handleClickShowPassword}
                                 edge="end"
                                 className={classes.white}
                              >
                                 {state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                           </InputAdornment>
                        }
                        labelWidth={95}
                     />
                  </FormControl>
                  {/*<FormControlLabel
                     control={<Checkbox value="remember" color="primary" id="chkRemember" checked={state.chkRemember} onChange={handleChange} />}
                     label="Recuerdame" />*/}
                  <Button
                     type="submit"
                     fullWidth
                     variant="contained"
                     className={classes.submitBlue}
                     onClick={LogIn}>
                     LOGIN
                  </Button>
               </form>
               <Button
                     type="button"
                     fullWidth
                     variant="contained"
                     className={classes.submitWhite}
                     onClick={CreateUser}>
                     CREATE USER
               </Button>
            </div>
            <Box mt={3} className={classes.white}>
               <Box>
                  <Link color="inherit" onClick={ResetPass}>Forgot password?</Link>
               </Box>
            </Box>
            <Box mt={8}>
               <Typography variant="body2" align="center" className={classes.white}>
                  {'Copyright © '}
                  <Link color="inherit" href="https://brokmarperu.com/" target="_blank">Brokmar Chartering SAC</Link>{' ' + new Date().getFullYear() + '.'}
               </Typography>
            </Box>
            <Backdrop className={classes.backdrop} open={isLoad}>
               <CircularProgress color="inherit" />
            </Backdrop>
         </Container>
      </Page>
   );
}

export default LoginView;