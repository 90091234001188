export const USER_DEFAULT_CREATE = 1;
export const COMPANY_DEFAULT_CREATE = 1;

export const Profile = {
    SuperAdmin: 1,
    Administrador: 2, 
    Client: 3,
    Operations: 4
}

export const Menu = {
    Tracking: 1,
    Client: 2
}