
import React from 'react';
import { Link, Typography } from '@material-ui/core';



function Copyright() {
    
    const classes = {
        white:{
            color:'white'
        }
    }

    return (
        <Typography variant="body2" align="center" className={classes.white}>
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Brokmar Chartering SAC
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default Copyright;