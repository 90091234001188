import React, { useState, useEffect } from 'react';

import { Tooltip, TextField, IconButton, Button, Box, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Modal, makeStyles, Typography } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TrackItemUser from '../components/TrackItemUser';
import Tree from '../components/Tree';
import Cookies from 'universal-cookie';
import httpClient from '../services/axios-client';
import { APP_BASE } from '../constants/apiConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: 0
    },
    title: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    icon: {
        color: '#ffd54f',
        minWidth: '35px',
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: `10%`,
        left: `40%`,
    },
    btnpaper: {
        //backgroundColor: theme.palette.background.paper,
        border: '1px solid #fff',
        color: '#fff',
        boxShadow: 'none',
        padding: '1px 4px',
        fontSize: '11px',
        height: '25px'
    },
    btnicon: {
        //background: '#ffffff',
        border: '1px solid #fff',
        color: '#fff',
        height: '25px',
        borderRadius: '0%'
    }
}));

const cookies = new Cookies();


const FolderUser = (props) => {

    const classes = useStyles();

    const objSession = cookies.get(APP_BASE + '.objSession');

    const optVisible = props.data.booking.length ? 0 : 1;

    const [dataPrimary, setDataPrimary] = useState(props.data.folder);
    const [dataBooking, setDataBooking] = useState(props.data.booking);
    const [dataFolder, setDataFolder] = useState([]);

    const [valueObject, setValueObject] = useState(null);


    const handleBack = () => {
        let object = valueObject;
        if (object != null) {
            let idfk = object.idfk;
            let data = [],
                newO = null;
            for (let i = 0; i < dataPrimary.length; i++) {
                let value = dataPrimary[i];
                if (value.idfk == idfk) {
                    data.push(value);
                }
                if (value.idcf == idfk) {
                    newO = value;
                }
            }
            data = [...data];
            setDataFolder(data);
            setValueObject(newO);
            if (idfk == null) {
                setDataBooking([]);
            } else {
                handleListBooking(newO.idcf);
            }
            props.onCleanMap();
        }
    };

    const handleSelect = (object) => {
        let idcf = object.idcf;
        let data = [];
        for (let i = 0; i < dataPrimary.length; i++) {
            let value = dataPrimary[i];
            if (value.idfk == idcf) {
                data.push(value);
            }
        }
        data = [...data];
        setDataFolder(data);
        setValueObject(object);

        handleListBooking(idcf);
        props.onCleanMap();
    };

    const handleListBooking = (idcf) => {
        let idcompany = objSession.idcompany;
        props.onLoad(true);
        httpClient.post('Tracking/List', { idcompany, idcf }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
                return response.data;
            })
            .then(response => {
                let data = [];
                if (response.length) {
                    for (let i = 0; i < response.length; i++) {
                        let value = response[i];
                        data.push({
                            idshipment: value.idshipment,
                            title: value.booking,
                            select: false,
                            value
                        });
                    }
                }
                setDataBooking(data);
                props.onLoad(false);
            })
            .catch(error => {
                props.onError('Server error.', true);
                props.onLoad(false);
            });
    }

    useEffect(() => {
        let data = [];
        for (let i = 0; i < dataPrimary.length; i++) {
            let value = dataPrimary[i];
            if (value.idfk == null) {
                data.push(value);
            }
        }
        data = [...data];
        setDataFolder(data);
        setValueObject(null);
    }, []);


    return (
        <React.Fragment>
            <Box p={1}>
                {optVisible == 1 ? (
                    <Box display="flex" justifyContent="space-between" mb={1}>
                        <Box>
                            <Tooltip title="Back">
                                <IconButton size="small" color="primary" onClick={handleBack} className={classes.btnicon}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>) : (<></>)}

                <List dense={true} className={classes.root}>
                    {dataFolder.map((object, i) => {
                        return (
                            <ListItem button key={object.idcf} onClick={() => { handleSelect(object) }}>
                                <ListItemIcon className={classes.icon}>
                                    <FolderIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary={object.name} />
                                <ListItemSecondaryAction>
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
                <Box>
                    {dataBooking.map((object, i) => {
                        return (
                            <TrackItemUser key={object.idshipment} data={object} onRoute={props.onRoute} />
                        );
                    })}
                </Box>
            </Box>

        </React.Fragment>
    );

}

export default FolderUser;