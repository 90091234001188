import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, List, Typography, makeStyles } from '@material-ui/core';
import TrackingIcon from '@material-ui/icons/Room';
import ShipIcon from '@material-ui/icons/AirplanemodeActive';
import UsersIcon from '@material-ui/icons/Group';
import NavItem from './NavItem';
import Cookies from 'universal-cookie';
import { APP_BASE } from '../../../../constants/apiConstants';

const cookies = new Cookies();

const items = [];

let objSession = {
   Profile: '',
   name: '',
   lname: ''
};
if (cookies.get(APP_BASE + '.objSession')) {
   objSession = cookies.get(APP_BASE + '.objSession');
   if (objSession.jsMenu != null) {
      let arrayMenu = JSON.parse(objSession.jsMenu);
      for (let i = 0; i < arrayMenu.length; i++) {
         let value = arrayMenu[i],
            obj = null;
         if (value.icon == 'TrackingIcon') obj = TrackingIcon;
         else if (value.icon == 'UsersIcon') obj = UsersIcon;
         else if (value.icon == 'ShipIcon') obj = ShipIcon;
         items.push({
            href: value.url,
            icon: obj,
            title: value.title
         });
      }
   }
}

const user = {
   avatar: '',
   jobTitle: objSession.profile,
   name: objSession.name + ' ' + objSession.lname
};

const useStyles = makeStyles(() => ({
   mobileDrawer: {
      width: 256
   },
   desktopDrawer: {
      width: 256,
      top: 64,
      height: 'calc(100% - 64px)'
   },
   avatar: {
      cursor: 'pointer',
      width: 64,
      height: 64
   }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
   const classes = useStyles();
   const location = useLocation();

   useEffect(() => {
      if (openMobile && onMobileClose) {
         onMobileClose();
      }
   }, [location.pathname]);

   const content = (
      <Box height="100%" display="flex" flexDirection="column">
         <Box alignItems="center" display="flex" flexDirection="column" p={2}>
            <Avatar className={classes.avatar} component={RouterLink} src={user.avatar} to="/app/account" />
            <Typography className={classes.name} color="textPrimary" variant="h5">{user.name}</Typography>
            <Typography color="textSecondary" variant="body2">{user.jobTitle}</Typography>
         </Box>
         <Divider />
         <Box p={2}>
            <List>
               {items.map((item) => (
                  <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
               ))}
            </List>
         </Box>
         <Box flexGrow={1} />
      </Box>
   );

   return (
      <>
         <Hidden lgUp>
            <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
               {content}
            </Drawer>
         </Hidden>
         {/*<Hidden mdDown>
            <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
               {content}
            </Drawer>
         </Hidden>*/}
      </>
   );
};

NavBar.propTypes = {
   onMobileClose: PropTypes.func,
   openMobile: PropTypes.bool
};

NavBar.defaultProps = {
   onMobileClose: () => { },
   openMobile: false
};

export default NavBar;