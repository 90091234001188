import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../../css/login.css';

const MainLayout = () => {

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default MainLayout;