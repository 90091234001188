import React,{useState} from 'react';
import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SettingsView = () => {
  const classes = useStyles();

  const [state, setState] = useState({
    txtPassword: '',
    txtConfirm: ''
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.id]: event.target.value
    });
  };

  return (
    <Page
      className={classes.root}
      title="Configuration"
      id="setting-pnl"
    >
      <Container maxWidth="sm">
        <Box mt={3}>
        <form>
      <Card>
        <CardHeader
          subheader="Actualizar Contraseña"
          title="Contraseña"
        />
        <Divider />
        <CardContent>
          <TextField
            fullWidth
            label="Contraseña"
            margin="normal"
            id="txtPassword"
            onChange={handleChange}
            type="password"
            value={state.txtPassword}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Confirmar contraseña"
            margin="normal"
            onChange={handleChange}
            type="password"
            value={state.txtConfirm}
            variant="outlined"
          />
        </CardContent>
        <Divider />
        <Box
          display="flex"
          justifyContent="flex-end"
          p={2}
        >
          <Button
            color="primary"
            variant="contained"
          >
            Actualizar
          </Button>
        </Box>
      </Card>
    </form>
        </Box>
      </Container>
    </Page>
  );
};

export default SettingsView;