import React, { useState } from 'react';
import { Slide, Container, Grid, Avatar, Box, Card, CardHeader, CardContent, Divider, TextField, Typography, makeStyles } from '@material-ui/core';
import Cookies from 'universal-cookie';
import Page from '../../components/Page';
import { APP_BASE } from '../../constants/apiConstants';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    minHeight: '100%',
    backgroundImage: `url(${"../images/principal/fondo-profile.jpg"})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  avatar: {
    height: 100,
    width: 100
  },
  lblTitle: {
    //fontSize: '20px',
    marginLeft: '10px',
    color: '#ffffff'
  },
  text: {
    color: '#ffffff',
    margin: '10px 0',
    padding: '5px',
    border: '2px solid white',
    borderRadius: '5px'
  },
  border: {
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '5px',
    '& .MuiDivider-root': {
      background: theme.palette.primary.main,
    }
  }
}));

const AccounView = () => {
  const classes = useStyles();

  let objSession = {};
  if (cookies.get(APP_BASE + '.objSession')) {
    objSession = cookies.get(APP_BASE + '.objSession');
  }

  return (
    <Page className={classes.root} title="Profile" id="account-pnl">
      <Container maxWidth="sm">
        <Slide direction="up" in={true} mountOnEnter unmountOnExit>
          <Box mt={3}>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <Box alignItems="center" display="flex" >
                  <Avatar className={classes.avatar} src="/images/principal/profile.png" />
                  <Typography className={classes.lblTitle} gutterBottom variant="h1">Profile</Typography>
                </Box>
                <Box>
                  <Typography className={classes.text}> Name: {objSession.personName} </Typography>
                  <Typography className={classes.text}> User: {objSession.user} </Typography>
                  {objSession.folder.length ? (
                    <Typography className={classes.text}> Folder Code: {objSession.folder} </Typography>) : (<></>)
                  }
                  <Typography className={classes.text}> Email: {objSession.email} </Typography>
                </Box>
              </Grid>
              {/*<Grid item lg={4} md={6} xs={12}>
              <Card className={classes.border}>
                <CardContent>
                  <Box alignItems="center" display="flex" flexDirection="column">
                    <Avatar className={classes.avatar} />
                    <Typography className={classes.lblTitle} color="textPrimary" gutterBottom variant="h3">{objSession.personName}</Typography>
                    <Typography color="textSecondary" variant="body1">{objSession.profile}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={8} md={6} xs={12}>
              <Card className={classes.border}>
                <CardHeader subheader="This information cannot be edited" title="Profile" />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                      <TextField fullWidth label="Name" value={objSession.personName} variant="outlined" disabled />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField fullWidth label="User" value={objSession.user} variant="outlined" disabled />
                    </Grid>
                    {objSession.folder.length ? (
                      <Grid item md={6} xs={12}>
                        <TextField fullWidth label="Folder Code" value={objSession.folder} variant="outlined" disabled />
                      </Grid>) : (<></>)
                    }
                    <Grid item md={6} xs={12}>
                      <TextField fullWidth label="Email" value={objSession.email} variant="outlined" disabled />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              </Grid>*/}
            </Grid>
          </Box>
        </Slide>
      </Container>
    </Page>
  );
};

export default AccounView;