import axios from 'axios'
import{API_BASE_URL} from '../constants/apiConstants'

const urlBase = API_BASE_URL;

const readUrl = (url = '') =>
  url.startsWith('http://') || url.startsWith('https://') ? url : `${urlBase}/${url}`

const get = (url = '', headers = {}) => axios.get(readUrl(url), {
  headers: {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json',
    ...headers
  }
})

const post = (url = '', body = {}, headers = {}) => axios.post(readUrl(url), body, {
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json;charset=utf-8',
    ...headers
  }
})

const put = (url = '', body = {}, headers = {}) => axios.put(readUrl(url), body, {
  headers: {
    Accept: 'application/json;charset=utf-8',
    'Content-Type': 'application/json;charset=utf-8',
    ...headers
  }
})

const del = (url = '', headers = {}) => axios.delete(readUrl(url), {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...headers
  }
})

export default {
  get,
  post,
  put,
  delete: del,
}