import React, { useState, useEffect } from 'react';
import { Hidden, Tabs, Tab, AppBar, Snackbar, Backdrop, CircularProgress, Tooltip, Box, InputBase, Paper, Divider, makeStyles } from '@material-ui/core';
import { useNavigate, useLocation } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import FolderUser from '../../components/FolderUser'
import Page from '../../components/Page';
import Map from '../../components/Map'
import { MAPS_KEY, APP_BASE } from '../../constants/apiConstants';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Cookies from 'universal-cookie';
import httpClient from '../../services/axios-client';
import TabPanel from '../../components/TabPanel';
import Folder from '../../components/Folder';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.primary.main,
      minHeight: '100%',
      maxHeight: '100%',
      display: 'flex',
   },
   contsearch: {
      width: 400,
   },
   contmap: {
      width: '100%'
   },
   contmobile: {
      width: '100%'
   },
   search: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: 400
   },
   searchmobile: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
   },
   input: {
      marginLeft: theme.spacing(1),
      flex: 1,
      'border-radius': '0px'
   },
   iconButton: {
      padding: 10,
   },
   divider: {
      height: 28,
      margin: 4,
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   }
}));

const cookies = new Cookies();

const Alert = (props) => {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

const TrackingUserView = () => {

   const classes = useStyles();

   const objSession = cookies.get(APP_BASE + '.objSession');

   const navigate = useNavigate();

   const location = useLocation();
   
   //const id = location.state != null ? location.state.idship : null;
   const id = location.state != null ? location.state.idperson : objSession.iduser;

   const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${MAPS_KEY}`;

   const [tabs, setTabs] = React.useState(0);

   const [showRoute, setShowRoute] = useState(false);
   const [dataMap, setDataMap] = useState(null);
   const [valueCenter, setValueCenter] = useState({ lat: -12.046374, lng: -77.042793 });

   const [isLoad, setLoad] = useState(false);

   const [isSuccess, setSuccess] = useState(false);
   const [lblSuccess, setTextSuccess] = useState('');

   const [isError, setError] = useState(false);
   const [lblError, setTextError] = useState('');

   const [dataAll, setDataAll] = useState([]);

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setError(false);
      setSuccess(false);
   };

   const cleanMap = () => {
      setShowRoute(false);
      setDataMap(null);
   };

   const handleRoute = async (item) => {
      setLoad(true);
      try {
         let booking = item.title,
            code = item.value.code,
            stype = 'BL';
         if (item.value.type == 2) stype = 'BK';
         //const responseApi = await httpClient.get(`${TRACKING_URL}/route?type=${stype}&number=${booking}&sealine=${code}&api_key=${TRACKING_KEY}`);
         const responseApi = await httpClient.post('Tracking/Route', { stype, booking, code }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') });
         const respFirst = responseApi.data;
         const respRoute = JSON.parse(respFirst);
         if (respRoute.status == 'success' && respRoute.message == 'OK') {
            let data = respRoute.data,
               position = null,
               polyline = [];
            if (data.pin.length) {
               position = { lat: data.pin[0], lng: data.pin[1] };
            }
            if (data.route.length) {
               for (let i = 0; i < data.route.length; i++) {
                  let value = data.route[i];
                  for (let j = 0; j < value.path.length; j++) {
                     let val = value.path[j];
                     polyline.push({ lat: val[0], lng: val[1] });
                  }
               }
            }
            setValueCenter(position);
            setDataMap({ marker: position, polyline });
            setShowRoute(true);
         } else {
            setLoad(false);
            handleError('Could not get the position or the route.', true);
         }

         setLoad(false);
      } catch (error) {
         setLoad(false);
         handleError('Api error, see admin.', true);
      }
   };

   const handleLoad = (load) => {
      setLoad(load);
   };

   const handleError = (text, error) => {
      setTextError(text);
      setError(error);
   };

   const handleSuccess = (text, success) => {
      setTextSuccess(text);
      setSuccess(success);
   };

   const handleRemove = (item) => {
      let temp = dataAll,
         removeIndex = null;
      for (let i = 0; i < temp.length; i++) {
         let value = temp[i];
         if (value.key == item) {
            removeIndex = i
         }
      }
      temp.splice(removeIndex, 1);
      temp = [...temp];
      setDataAll(temp);
   };

   const handleShipment = async (idshipment) => {
      let data = dataAll,
         item = null;
      for (let i = 0; i < data.length; i++) {
         let value = data[i];
         for (let j = 0; j < value.booking.length; j++) {
            let val = value.booking[j];
            if (val.idshipment == idshipment) {
               if (val.select) val.select = false;
               else {
                  val.select = true;
                  item = val;
               };
            }
            else val.select = false;
         }
      }
      data = [...data];
      setDataAll(data);

      if (item == null) {
         cleanMap()
      } else {
         handleRoute(item);
      }
   };

   const getData = () => {
      if (id != null) {
         let idcompany = objSession.idcompany,
            idclient = id;
         handleLoad(true);
         httpClient.post('Tracking/FolderAdmin', { idcompany, idclient }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
            .then(response => {
               return response.data;
            })
            .then(response => {
               if (response.length) {

                  let value = response[0],
                     temp = dataAll,
                     objClient = JSON.parse(value.jsClient),
                     objFolder = [];
                  if (value.jsFolder) {
                     value.jsFolder = value.jsFolder.replaceAll('\\"', '"').replaceAll('"{', '{').replaceAll('}"', '}');
                     objFolder = JSON.parse(value.jsFolder);
                  }

                  let data = {
                     key: value.idclient,
                     client: objClient,
                     folder: objFolder,
                     booking: []
                  };

                  temp = [...temp, data];
                  setDataAll(temp);
                  
                  handleSuccess('Found.', true);
                  //handleLoad(false);
                  handleShipByClient(temp);
               } else {
                  handleError('No code found.', true);
                  handleLoad(false);
               }
            })
            .catch(error => {
               handleLoad(false);
               navigate('/app/ship');
            });
      }
   }

   const handleShipByClient = async (dataAll) => {
      let idcompany = objSession.idcompany,
      idclient = id;
      
      let respData = [];

      handleLoad(true);
      await httpClient.post('Tracking/SearchShipAllByClient', { idcompany, idclient }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
      .then(response => {
         return response.data;
      })
      .then(response => {
         respData = response;
      })
      .catch(error => {
         handleError('Server error.', true);
         handleLoad(false);
         return;
      });

      if (respData.length) {
         respData.forEach(e => {
            let value = e,
            temp = dataAll,
            objClient = JSON.parse(value.jsClient),
            objFolder = [],
            item = null;
            if (value.jsFolder) {
               value.jsFolder = value.jsFolder.replaceAll('\\"', '"').replaceAll('"{', '{').replaceAll('}"', '}');
               objFolder = JSON.parse(value.jsFolder);
            }

            for (let i = 0; i < temp.length; i++) {
               let val = temp[i];
               if (val.key == value.idclient) item = val;
            }
            
            if (item != null) {
               let cont = 0;
               for (let i = 0; i < item.booking.length; i++) {
                  let val = item.booking[i];
                  if (val.idshipment == value.idshipment) cont++;
               }
               if (cont == 0) {

                  item.booking.push({
                     idshipment: value.idshipment,
                     title: value.booking,
                     select: false,
                     value
                  });
                  handleSuccess('Added code.', true);
               } else {
                  handleError('This code has already been searched.', true);
               }
            } else {

               let data = {
                  key: value.idclient,
                  client: objClient,
                  folder: objFolder,
                  booking: [{
                     idshipment: value.idshipment,
                     title: value.booking,
                     select: false,
                     value
                  }]
               };

               temp = [...temp, data];
               setDataAll(temp);

               handleSuccess('Added code.', true);
            }
         });
         
         handleLoad(false);
      } else {
         handleError('No code found.', true);
         handleLoad(false);
      }
   };

   const handleChangeTab = (event, newValue) => {
      setTabs(newValue);
   };

   useEffect(() => {
      setDataAll([]);
      getData();
   }, []);


   return (
      <Page className={classes.root} title="Tracking" id="trackinguser-pnl">
         <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
               {lblError}
            </Alert>
         </Snackbar>
         <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
               {lblSuccess}
            </Alert>
         </Snackbar>
         <Hidden mdDown>
            <Box className={classes.contsearch} >
               <Box style={{ maxHeight: '100%', overflow: 'auto', width:'400px' }}>
                  {dataAll.map((object, i) => {
                     return (
                        <React.Fragment key={object.key}>
                           {i > 0 ? (<Divider />) : (<></>)}
                           <Folder showAction={true} data={object} removeClick={handleRemove} onLoad={handleLoad} onError={handleError} onSuccess={handleSuccess} onShipment={handleShipment} onRoute={handleRoute} onCleanMap={cleanMap} />
                        </React.Fragment>
                     );
                  })}
               </Box>
            </Box>
            <Box className={classes.contmap}>
               <Map
                  showRoute={showRoute}
                  data={dataMap}
                  valueCenter={valueCenter}
                  googleMapURL={mapURL}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />} />
            </Box>
         </Hidden>
         <Hidden lgUp>
            <div className={classes.contmobile}>
               <AppBar position="static" color="default">
                  <Tabs
                     value={tabs}
                     onChange={handleChangeTab}
                     indicatorColor="primary"
                     textColor="primary"
                     variant="fullWidth">
                     <Tab label="Search" {...a11yProps(0)} />
                     <Tab label="Map" {...a11yProps(1)} />
                  </Tabs>
               </AppBar>
               <TabPanel value={tabs} index={0} >
                  <Box className={classes.contmobile} >
                     <Box style={{ maxHeight: '91%', overflow: 'auto' }}>
                        {dataAll.map((object, i) => {
                           return (
                              <React.Fragment key={object.key}>
                                 {i > 0 ? (<Divider />) : (<></>)}
                                 <Folder showAction={true} data={object} removeClick={handleRemove} onLoad={handleLoad} onError={handleError} onSuccess={handleSuccess} onShipment={handleShipment} onRoute={handleRoute} onCleanMap={cleanMap} />
                              </React.Fragment>
                           );
                        })}
                     </Box>
                  </Box>
               </TabPanel>
               <TabPanel value={tabs} index={1} >
                  <Map
                     showRoute={showRoute}
                     data={dataMap}
                     valueCenter={valueCenter}
                     googleMapURL={mapURL}
                     loadingElement={<div style={{ height: `100%` }} />}
                     containerElement={<div style={{ height: `100%` }} />}
                     mapElement={<div style={{ height: `100%` }} />} />

               </TabPanel>


            </div>
         </Hidden>
         <Backdrop className={classes.backdrop} open={isLoad}>
            <CircularProgress color="inherit" />
         </Backdrop>
      </Page>


   );
}

export default TrackingUserView;