import React from 'react';
import { useRoutes } from 'react-router-dom';
import routes from './constants/routes';
import theme from './theme';
import Cookies from 'universal-cookie';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import { APP_BASE } from './constants/apiConstants';


function App() {
   const cookies = new Cookies();

   const isLoggedIn = cookies.get(APP_BASE + '.objSession') ? true : false;

   let idprofile = 1;
   if (isLoggedIn) { 
      idprofile = cookies.get(APP_BASE + '.objSession').idprofile;
   }

   const routing = useRoutes(routes(isLoggedIn, idprofile));

   return (
      <ThemeProvider theme={theme}>
         <GlobalStyles />
         {routing}
      </ThemeProvider>
   );
};

export default App;