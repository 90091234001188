import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../pages/layouts/DashboardLayout';
import MainLayout from '../pages/layouts/MainLayout';
import LoginView from '../pages/auth/LoginView';
import RecoverView from '../pages/auth/RecoverView';
import ChangeView from '../pages/auth/ChangeView';
import NotFoundView from '../pages/error/NotFoundView';
import DashboardView from '../pages/default/DashboardView';
import TrackingAdminView from '../pages/default/TrackingAdminView';
import TrackingUserView from '../pages/default/TrackingUserView';
import UserView from '../pages/default/UserView';
import UserRegisterView from '../pages/default/UserRegisterView';
import UserResumenView from '../pages/default/UserResumenView';
import ShipView from '../pages/default/ShipView';
import AccountView from '../pages/default/AccountView';
import SettingView from '../pages/default/SettingView';
import ShipInfoView from '../pages/default/ShipInfoView';
import ShipUserView from '../pages/default/ShipUserView';
import NotificationView from '../pages/default/NotificationView';
import UserOperationsView from '../pages/default/UserOperationsView';
import { Profile } from './baseConstants';
import TrackingShipView from '../pages/default/TrackingShipView';



const routes = (isLoggedIn, idProfile = Profile.SuperAdmin) => {


   return [{
      path: 'app',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: idProfile == Profile.Client ? [
         { path: 'tracking', element: <TrackingUserView /> },
         { path: 'trackingShip', element: <TrackingShipView /> },
         { path: 'dashboard', element: <DashboardView /> },
         { path: 'ship', element: <ShipUserView /> },
         { path: 'account', element: <AccountView /> },
         { path: 'notification', element: <NotificationView /> },
         { path: '404', element: <NotFoundView /> },
         { path: '*', element: <Navigate to="/404" /> }
      ] : [
         { path: 'tracking', element: <TrackingAdminView /> },
         { path: 'trackingOpe', element: <TrackingUserView /> },
         { path: 'trackingShip', element: <TrackingShipView /> },
         { path: 'dashboard', element: <DashboardView /> },
         { path: 'user', element: <UserView /> },
         { path: 'user/register', element: <UserRegisterView /> },
         { path: 'user/resumen', element: <UserResumenView /> },
         { path: 'user/operations', element: <UserOperationsView /> },
         { path: 'ship', element: <ShipView /> },
         { path: 'ship/info', element: <ShipInfoView /> },
         { path: 'account', element: <AccountView /> },
         { path: 'setting', element: <SettingView /> },
         { path: 'notification', element: <NotificationView /> },
         { path: '404', element: <NotFoundView /> },
         { path: 'app', element: <Navigate to="/app/dashboard" /> },
         { path: '*', element: <Navigate to="/404" /> }
      ]
   },
   {
      path: '/',
      element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
      children: [
         { path: 'login', element: <LoginView /> },
         { path: 'login/user-register', element: <UserRegisterView /> },
         { path: 'recover', element: <RecoverView /> },
         { path: 'change', element: <ChangeView /> },
         { path: '404', element: <NotFoundView /> },
         { path: '/', element: <Navigate to="/login" /> },
         { path: '*', element: <Navigate to="/404" /> }
      ]
   }, 
   {
      path: '*', 
      element: <Navigate to="/404" />
   }]
};

export default routes;