import React from 'react';
import {
   Box,
   Container,
   Typography,
   makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';


const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
   },
   image: {
      marginTop: 50,
      display: 'inline-block',
      maxWidth: '100%',
      width: 560
   }
}));

const NotFoundView = () => {
   const classes = useStyles();

   return (
      <Page className={classes.root} title="404">
         <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
            <Container maxWidth="lg">
               <Typography align="center" color="textPrimary" variant="h3">
                  404: La página que estás buscando no está aquí.
               </Typography>
               <Typography align="center" color="textPrimary" variant="subtitle2">
                  O intentaste alguna ruta equivocada o viniste aquí por error.
                  Sea lo que sea, intente usar la navegación.
               </Typography>
               <Box textAlign="center">
                  <img alt="404" className={classes.image} src="/images/404.jpg"/>
               </Box>
            </Container>
         </Box>
      </Page>
   );
};

export default NotFoundView;