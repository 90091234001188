import React, { useState, useEffect } from 'react';
import {
   Box,
   Container,
   Card,
   CardContent,
   CardHeader,
   Divider,
   List,
   ListItem,
   ListItemAvatar,
   Avatar,
   ListItemText,
   Backdrop,
   CircularProgress,
   Slide,
   makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import Cookies from 'universal-cookie';
import httpClient from '../../services/axios-client';
import { useNavigate } from 'react-router-dom';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import { APP_BASE } from '../../constants/apiConstants';


const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
   },
   m_auto: {
      margin: 'auto'
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
   border: {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '5px',
      '& .MuiDivider-root': {
         background: theme.palette.primary.main,
      }
   },
   textCenter: {
      textAlign: 'center'
   }
}));

const cookies = new Cookies();

const NotificationView = () => {
   const classes = useStyles();

   const navigate = useNavigate();

   const objSession = cookies.get(APP_BASE + '.objSession');

   const [notification, setNotification] = useState([]);

   const [isLoad, setLoad] = useState(false);

   const getData = async () => {
      await httpClient.post('Notification/ListAll', { iduser: objSession.iduser }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
         .then(response => {
            return response.data;
         })
         .then(response => {
            if (response.length) {
               setNotification(response);
               cookies.set(APP_BASE + '.listNotification', '[]', { path: '/' });
            }
         })
         .catch(error => {

         });
   };

   useEffect(() => {
      getData();
   }, []);

   return (
      <Page className={classes.root} title="Notification" id="userregister-pnl">
         <Container maxWidth="sm">

            <Box mt={3}>

               <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Card className={classes.border}>
                     <CardHeader title="Notifications" subheader="Shipments" />
                     <Divider />
                     <CardContent>
                        <List  className={classes.rootN}>
                           {notification.map((not) => (
                              <ListItem key={not.idnu}>
                                 <ListItemAvatar>
                                    <Avatar>
                                       <DirectionsBoatIcon />
                                    </Avatar>
                                 </ListItemAvatar>
                                 <ListItemText primary={'Code: ' + not.booking + ' is ' + not.description + ' in ' + not.location} secondary={not.date + ', ' + not.origin + ' - ' + not.target} />
                              </ListItem>

                           ))}
                           {notification.length ? (<></>) : (
                              <ListItem className={classes.textCenter}>
                                 <ListItemText primary="You do not have any pending notification." />
                              </ListItem>
                           )}
                        </List>
                     </CardContent>
                  </Card>
               </Slide>

            </Box>
            <Backdrop className={classes.backdrop} open={isLoad}>
               <CircularProgress color="inherit" />
            </Backdrop>
         </Container>
      </Page>
   );
};

export default NotificationView;