import React, { useState, useEffect } from 'react';
import {
   Hidden, Snackbar, CircularProgress, Backdrop, Modal, Box, Container, Button, Card, makeStyles, Typography, Slide, FormControlLabel, Checkbox, Grid, FormControl, InputLabel, Select, MenuItem, OutlinedInput, ListItemText
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import httpClient from '../../services/axios-client';
import Cookies from 'universal-cookie';
import MUIDataTable from "mui-datatables";
import { APP_BASE } from '../../constants/apiConstants';
import { Profile } from '../../constants/baseConstants';

const cookies = new Cookies();

const Alert = (props) => {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const options = {
   filter: true,
   filterType: "dropdown",
   selectableRows: false,
   pagination: false
};

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
      '& .MuiTableCell-root':{
         textAlign:'left',
         minWidth: '150px'
      },
      '& .MuiTableCell-head span':{
         justifyContent: 'left'
      }
   },
   rows: {
      textAlign: 'center',
      '& .MuiButton-root': {
         margin: '10px 0px 0px 0px',
      },
      '& .MuiButtonBase-root': {
         width: '200px'
      }
   },
   marginBtn: {
      margintop: '5px'
   },
   container: {
      maxHeight: 440,
   },
   danger: {
      color: '#e91e63'
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
   },
   paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `10%`,
      left: `40%`,
   },
   papermobile: {
      position: 'absolute',
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: `10%`,
   },
   border:{
      border:'1px solid',
      borderColor: theme.palette.primary.main,
      borderRadius:'5px',
      '& .MuiToolbar-root[role=toolbar]':{
         borderBottom:'1px solid',
         borderColor: theme.palette.primary.main,
      },
      '& .MuiTableCell-body, .MuiTableCell-head':{
         borderColor: theme.palette.primary.main,
      }
   }
}));

const UserOperationsView = () => {
   const classes = useStyles();

   const objSession = cookies.get(APP_BASE + '.objSession');

   const [_operations, setOperations] = useState([]);
   const [_clients, setClients] = useState([]);

   const [rows, setRows] = useState([]);
   const [columns, setColumns] = useState([]);

   const [openModalSaveOperations, setOpenModalSaveOperations] = React.useState(false);

   const [isLoad, setLoad] = useState(false);

   const [isSuccess, setSuccess] = useState(false);
   const [lblSuccess, setTextSuccess] = useState('');

   const [isError, setError] = useState(false);
   const [lblError, setTextError] = useState('');

   const [state, setState] = useState({
      cboIdOperations: []
   });

   const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      setError(false);
      setSuccess(false);
   };

   const navRegisterOperations = () => {
      setOpenModalSaveOperations(true);
   };

   const handleSaveOperations = () => {
      setLoad(true);
      setOpenModalSaveOperations(false);
      let data = [];

      columns.forEach((e, i) => {
         if (i > 1) {
            let idclients = [];
            let idoperations = _operations[i-2].idclient;
            for (let r = 0; r < rows.length; r++) {
               let check = rows[r][i];
               if (check) {
                  idclients.push(rows[r][0]);
               }
            }
            data.push({ idclient: idoperations, user_view: idclients.toString() });
         }
      });
      
      let d = {
         iduser: objSession.iduser,
         data: data
      };

      httpClient.post('Client/saveOperations', d, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
      .then(response => {
         return response.data;
      })
      .then(response => {
         if (response.done == 1) {
            getData();
            setTextSuccess('Save Ok');
            setSuccess(true);
         } else {
            setTextError('Unexpected error, check with your system administrator.');
            setError(true);
         }
         setLoad(false);
      })
      .catch(error => {
         setTextError('Server error.');
         setError(true);
         setLoad(false);
      });
   }

   const getData = async () => {
      setLoad(true);
      await httpClient.post('Client/List', { idcompany: objSession.idcompany, order_by: 'user', asc_desc: 'asc' }, { Authorization: 'Bearer ' + cookies.get(APP_BASE + '.token') })
      .then(response => {
         return response.data.filter(x => x.state == 'Active'); 
      })
      .then(response => {
         let clients = response.filter(x => x.idprofile == Profile.Client);
         let operations = response.filter(x => x.idprofile == Profile.Operations);

         setClients(clients);
         setOperations(operations);
         setState({ ...state, ['cboIdOperations']: operations });

         if (clients.length) {
            let dataU = [];
            let dataC = []
            dataC.push({ name: 'Id', options: { display: false } });
            dataC.push({ name: 'User' });
            
            operations.forEach(e => {
               dataC.push({
                   idclient: e.idclient,
                   name: e.user, options: {
                      filter: false,
                      sort: false,
                      empty: true,
                      customBodyRender: (value, meta, update) => {
                       let checked = meta.tableData[meta.rowIndex][meta.columnIndex];
                       return (
                           <React.Fragment>
                               <FormControlLabel
                                   control={<Checkbox checked={checked} onChange={e => {
                                    meta.tableData[meta.rowIndex][meta.columnIndex] = !checked;
                                    let newData = [];
                                    meta.tableData.forEach(e => {
                                       newData.push(e);
                                    });
                                    setRows(newData);
                                   }} />} />
                           </React.Fragment>
                       );
                     }
                   }
                });
            });
            
            clients.forEach((e, index) => {
               let id = e.idclient;
               let columns = [];
               columns.push(e.idclient);
               columns.push(e.user);
               for (let i = 0; i < operations.length; i++) {
                  var options = operations[i].user_view.split(',');
                  let hasChecked = operations[i].alluser || options.filter(x => x == id)[0];
                  let checked = hasChecked != null;
                  columns.push(checked);
               }
               dataU.push(columns);
            });
            setColumns(dataC);
            setRows(dataU);
         }
         setLoad(false);
      })
      .catch(error => {
         setLoad(false);
      });
   };

   useEffect(() => {
      getData();
   }, []);

   const handleCloseModalSaveOperations = () => {
      setOpenModalSaveOperations(false);
   }
   
   const handleChange = (e) => {
      setState({ ...state, [e.target.name]: e.target.value });
      let ope = e.target.value;
      let newColumns = [];
      columns.forEach((e, i) => {
         if (i > 1) {
            let has = ope.filter(x => x.idclient == e.idclient)[0];
            e.options.display = has != null;
         }
         newColumns.push(e);
      });
      setColumns(newColumns);
    };

   const bodySaveOperations = (
      <React.Fragment>
         <Hidden mdDown>
            <Box className={classes.paper}>
               <Typography variant="h5">Are you sure?</Typography>
               <Box className={classes.rows} justifyContent="space-between" mt={2}>
                  <Button variant="contained" size="small" color="primary" onClick={handleSaveOperations}>Save</Button><br></br>
                  <Button variant="contained" size="small" color="secondary" onClick={handleCloseModalSaveOperations}>Cancel</Button>
               </Box>
            </Box>
         </Hidden>
         <Hidden lgUp>
         <Box className={classes.papermobile}>
               <Typography variant="h5">Are you sure?</Typography>
               <Box className={classes.rows} justifyContent="space-between" mt={2}>
                  <Button variant="contained" size="small" color="primary" onClick={handleSaveOperations}>Save</Button><br></br>
                  <Button variant="contained" size="small" color="secondary" onClick={handleCloseModalSaveOperations}>Cancel</Button>
               </Box>
            </Box>
         </Hidden>
      </React.Fragment>
   );

   return (
      <Page className={classes.root} title="Operations" id="user-pnl">
         <style>
            {`@media print{.MuiTableRow-root:before {content: 'User Details'; color: #263238; font-family: Helvetica, sans-serif; font-size: 18px;margin-top: 25px;margin-bottom: 10px;display: block;padding-left:10px;}}`}
         </style>
         <Modal
            open={openModalSaveOperations}
            onClose={handleCloseModalSaveOperations}>
            {bodySaveOperations}
         </Modal>

         <Container maxWidth={false}>
            <Snackbar open={isError} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="error">
                  {lblError}
               </Alert>
            </Snackbar>
            <Snackbar open={isSuccess} autoHideDuration={3000} onClose={handleClose}>
               <Alert onClose={handleClose} severity="success">
                  {lblSuccess}
               </Alert>
            </Snackbar>
            <Box display="flex" justifyContent="space-between"> 
               <Typography color="textPrimary" variant="h2">Staff</Typography>
               <Box>
                  <Button color="primary" variant="contained" onClick={navRegisterOperations}>Guardar</Button>
               </Box>
            </Box>
            <Box display="flex" justifyContent="space-between"> 
               <Grid container>
                  <Grid item lg={4} md={6} xs={12}>
                     <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel id="cboIdOperations">User Staff</InputLabel>
                        <Select
                           labelId="cboIdOperations"
                           name="cboIdOperations"
                           multiple
                           value={state.cboIdOperations}
                           onChange={handleChange}
                           input={<OutlinedInput label="User Operations" />}
                           renderValue={(selected) => {
                              let value = [];
                              selected.forEach(e => {
                                 value.push(e.user);
                              });
                              return value.toString();
                           }}
                        >
                           {_operations.map((obj) => (
                              <MenuItem key={obj.idclient} value={obj}>
                              <Checkbox checked={state.cboIdOperations.indexOf(obj) > -1} />
                              <ListItemText primary={obj.user} />
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </Grid>
               </Grid>
            </Box>
            <Box mt={5}>
               <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                  <Card className={classes.border}>
                     <MUIDataTable
                        title={"Configurations"}
                        data={rows}
                        columns={columns}
                        options={options}
                     />
                  </Card>
               </Slide>
            </Box>
            <Backdrop className={classes.backdrop} open={isLoad}>
               <CircularProgress color="inherit" />
            </Backdrop>
         </Container>
      </Page>
   );
};

export default UserOperationsView;